import {
  configs,
  globalLoading,
  globalRef,
  isSidebarOpen,
  isUnauthorizedModalOpen,
} from '@store/configs/reducers';
import { pagination } from '@store/pagination/reducers';
import {
  currentProject,
  projects,
  projectsLoading,
} from '@store/projects/reducers';
import { user, userToken } from '@store/user/reducers';
import { webPush } from '@store/webPush/reducers';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
  user,
  userToken,
  projects,
  projectsLoading,
  currentProject,
  isSidebarOpen,
  configs,
  isUnauthorizedModalOpen,
  globalLoading,
  pagination,
  webPush,
  globalRef,
});
export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
