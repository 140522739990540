import type { WebPushConfig } from '@dataTypes/channel';
import type { ActionType } from 'typesafe-actions';
import { createAction } from 'typesafe-actions';

export interface SetWebPushConfigs {
  data: WebPushConfig | null;
  isLoading: boolean;
  isActivate: boolean;
}

export const SET_WEB_PUSH_CONFIGS = 'SET_WEB_PUSH_CONFIGS';
export const setWebPushConfigs = createAction(SET_WEB_PUSH_CONFIGS)<
  SetWebPushConfigs | ((prev: SetWebPushConfigs) => SetWebPushConfigs)
>();

const webPushActions = { setWebPushConfigs };

export type WebPushActions = ActionType<typeof webPushActions>;
