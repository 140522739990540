import { localStorageKey } from '@configs/localStorageKey';
import type { Project } from '@dataTypes/project';
import type {
  CurrentProjectAction,
  ProjectsAction,
  ProjectsLoadingAction,
} from '@store/projects/actions';
import {
  SET_CURRENT_PROJECT,
  SET_PROJECTS,
  SET_PROJECTS_LOADING,
} from '@store/projects/actions';
import { createReducer } from 'typesafe-actions';

export type CurrentProjectState = Record<string, never> | Project;

export const projects = createReducer<Project[], ProjectsAction>([], {
  [SET_PROJECTS]: (state, action) => action.payload,
});

export const projectsLoading = createReducer<boolean, ProjectsLoadingAction>(
  false,
  {
    [SET_PROJECTS_LOADING]: (state, action) => action.payload,
  }
);

export const currentProject = createReducer<
  CurrentProjectState,
  CurrentProjectAction
>(
  {},
  {
    [SET_CURRENT_PROJECT]: (state, action) => {
      const nextState =
        typeof action.payload === 'function'
          ? action.payload(state)
          : action.payload;

      if (JSON.stringify(state) === JSON.stringify(nextState)) {
        return state;
      } else {
        localStorage.setItem(localStorageKey.projectId, nextState.id);

        if (state.id) {
          location.href = '/dashboard';
        }

        return nextState;
      }
    },
  }
);
