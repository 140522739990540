import Alert from '@components/atoms/Alert/Alert';
import GlobalLoaderModal from '@components/organisms/Modal/GlobalLoaderModal';
import UnauthorizedModal from '@components/organisms/Modal/UnauthorizedModal';
import { alertOptions } from '@configs/alertConfigs';
import { cookieConfigs } from '@configs/cookieConfigs';
import {
  demoConfigs,
  demoProject,
  demoProjects,
  demoUser,
} from '@configs/demoDatas';
import { Global, ThemeProvider } from '@emotion/react';
import { useDemoData } from '@hooks/useDemoData';
import { useGlobalRef } from '@hooks/useGlobalRef';
import { useWebPushConfig } from '@hooks/useWebPushConfig';
import { getCookie, setLangCookie } from '@libs/cookie';
import {
  removePageLoadingBar,
  setPageLoadingBar,
} from '@libs/setPageLoadingBar';
import { setConfigs, setIsGlobalLoading } from '@store/configs/actions';
import {
  setCurrentProject,
  setProjects,
  setProjectsLoading,
} from '@store/projects/actions';
import { wrapper } from '@store/rootStore';
import { setUser } from '@store/user/actions';
import globalStyle from '@styles/globalStyles';
import theme from '@styles/theme';
import { appWithTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect, useRef } from 'react';
import { Provider as AlertProvider } from 'react-alert';
import { useDispatch } from 'react-redux';

const RootApp = ({ Component, pageProps }: AppProps): JSX.Element => {
  const dispatch = useDispatch();
  const router = useRouter();
  const globalRef = useRef<any>();
  useWebPushConfig();
  const { reset: resetGlobalRef } = useGlobalRef();
  const demoUserData = useDemoData(demoUser);
  const demoProjectsData = useDemoData(demoProjects);
  const demoProjectData = useDemoData(demoProject);
  const demoConfigsData = useDemoData(demoConfigs);

  // DEMO
  useEffect(() => {
    // global변수 set
    resetGlobalRef(globalRef, {
      isLoadingWebPushConfig: false,
      cachedPlan: {},
    });

    setPageLoadingBar();

    const { pathname, asPath, query, locale, locales } = router;
    const lang = getCookie(cookieConfigs.key);

    if (lang && router.locale !== lang) {
      if (locales?.includes(lang)) {
        router.push({ pathname, query }, asPath, { locale: lang });
      } else {
        setLangCookie('en');
        location.reload();
      }

      return;
    }

    if (!lang) {
      setLangCookie(locale);
    }

    // DEMO
    dispatch(
      setIsGlobalLoading({
        isLoading: false,
      })
    );

    // DEMO USER
    dispatch(setUser(demoUserData));

    dispatch(setProjects(demoProjectsData));
    dispatch(setCurrentProject(demoProjectData));
    dispatch(setProjectsLoading(false));

    // DEMO CONFIGS
    dispatch(setConfigs(demoConfigsData));

    return () => removePageLoadingBar();
  }, []);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1, user-scalable=no"
        />
        <title>FlareLane Demo</title>
      </Head>
      <Script
        src="https://www.googletagmanager.com/gtag/js?id=AW-10786928509"
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag() { dataLayer.push(arguments); }
          gtag('js', new Date());
          gtag('config', 'AW-10786928509');
          `}
      </Script>
      <Script id="google-tag-manager" strategy="afterInteractive">
        {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-5STJMHC');
        `}
      </Script>
      <Global styles={globalStyle} />
      <ThemeProvider theme={theme}>
        <AlertProvider template={Alert as any} {...alertOptions}>
          <Component {...pageProps} />
          <UnauthorizedModal />
          <GlobalLoaderModal />
        </AlertProvider>
      </ThemeProvider>
    </>
  );
};

export default wrapper.withRedux(appWithTranslation(RootApp));
