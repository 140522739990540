import { Conifgs } from '@dataTypes/configs';
import type { GlobalLoading } from '@dataTypes/GlobalLoading';
import {
  ConfigStateAction,
  SetGlobalRefAction,
  SetIsGlobalLoading,
  SET_CONFIGS,
  SET_GLOBAL_REF,
  SET_IS_GLOBAL_LOADING,
  SET_SIDEBAR_CLOSE,
  SET_SIDEBAR_STATE,
  SET_UNAUTHORIZED_MODAL_STATE,
  SidebarStateAction,
  UnauthorizedModalStateAction,
} from '@store/configs/actions';
import { HYDRATE } from 'next-redux-wrapper';
import { createReducer } from 'typesafe-actions';

export const isSidebarOpen = createReducer<boolean, SidebarStateAction>(true, {
  [SET_SIDEBAR_STATE]: (state) => !state,
  [SET_SIDEBAR_CLOSE]: () => false,
});

export const isUnauthorizedModalOpen = createReducer<
  boolean,
  UnauthorizedModalStateAction
>(false, {
  [SET_UNAUTHORIZED_MODAL_STATE]: (_, action) => action.payload,
});

export const globalLoading = createReducer<GlobalLoading, SetIsGlobalLoading>(
  {
    isLoading: true,
  },
  {
    [HYDRATE]: (state, action) => {
      // 서버에서는 끌수만 있음
      const globalLoadingFromServer = action.payload.globalLoading;
      return !globalLoadingFromServer.isLoading
        ? globalLoadingFromServer
        : state;
    },
    [SET_IS_GLOBAL_LOADING]: (state, action) => {
      if (typeof action.payload === 'function') {
        return action.payload(state);
      }

      return action.payload;
    },
  }
);

export const configs = createReducer<Conifgs, ConfigStateAction>(
  {},
  {
    [SET_CONFIGS]: (_, action) => action.payload,
  }
);

export const globalRef = createReducer<any, SetGlobalRefAction>(null, {
  [SET_GLOBAL_REF]: (_, action) => action.payload,
});
