import { positions } from 'react-alert';

export const alertOptions = {
  position: positions.TOP_RIGHT,
  timeout: 20 * 1000, // 20초
  containerStyle: {
    paddingTop: 50,
    zIndex: 210000000,
  },
};
