import { AutomatedExample } from '@dataTypes/automatedMessages';
import { Member } from '@dataTypes/member';
import { eachDayOfInterval, format } from 'date-fns';

export const demoUser = {
  ko: {
    'https://flarelane.com/flarelane_id': 'choijunyeong',
    'family_name': '',
    'given_name': '',
    'locale': 'ko',
    'name': '플레어몰',
    'nickname': '',
    'picture': '',
    'sub': '',
    'updated_at': '',
    'email': 'help@flaremall.com',
    'email_verified': true,
  },
  en: {
    'https://flarelane.com/flarelane_id': 'choijunyeong',
    'family_name': '',
    'given_name': '',
    'locale': 'en',
    'name': 'FLAREMALL',
    'nickname': '',
    'picture': '',
    'sub': '',
    'updated_at': '',
    'email': 'help@flaremall.com',
    'email_verified': true,
  },
} as any;

export const demoProject = {
  ko: {
    id: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
    name: '플레어몰',
    createdAt: '',
    updatedAt: '',
    promotion: null,
  },
  en: {
    id: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
    name: 'FLAREMALL',
    createdAt: '',
    updatedAt: '',
    promotion: null,
  },
} as any;

export const demoProjects = { ko: [demoProject.ko], en: [demoProject.en] };

export const demoProjectDetail = {
  ko: {
    ...demoProject.ko,
    fcmPayload: {
      serverKey: 'demo::fcmserverkeyA2cVx2fj5Sj9i7gGS4d6cEEVFBsdnv4cbfmdf7',
      senderId: '73384232',
    },
    apnsPayload: {
      key:
        '-----BEGIN PRIVATE KEY-----\nDEMOAPNSMBMG564DFM42548DFGdqGSMQQWERHBHkwdwIBAQQgxGQDoa024AlqFLwU\nOhCDyljO8vFKXcNMASVEF23oagCgYIKoZIzj0QERE234CAASrd2ctr3dfkj1b\nSHep0JEbQYvOdvdZUDa69FE7567wvdp71XGmxJFI7kSz6BJaXCN0005sF3nYR\nqAge+Xww\n-----END PRIVATE KEY-----',
      keyId: 'AG24FSW5',
      teamId: 'GTEV5A57Q',
      topic: 'com.flarelane.demo',
    },
    accessToken: '1234567890abcdef',
  },
  en: {
    ...demoProject.en,
    fcmPayload: {
      serverKey: 'demo::fcmserverkeyA2cVx2fj5Sj9i7gGS4d6cEEVFBsdnv4cbfmdf7',
      senderId: '73384232',
    },
    apnsPayload: {
      key:
        '-----BEGIN PRIVATE KEY-----\nDEMOAPNSMBMG564DFM42548DFGdqGSMQQWERHBHkwdwIBAQQgxGQDoa024AlqFLwU\nOhCDyljO8vFKXcNMASVEF23oagCgYIKoZIzj0QERE234CAASrd2ctr3dfkj1b\nSHep0JEbQYvOdvdZUDa69FE7567wvdp71XGmxJFI7kSz6BJaXCN0005sF3nYR\nqAge+Xww\n-----END PRIVATE KEY-----',
      keyId: 'AG24FSW5',
      teamId: 'GTEV5A57Q',
      topic: 'com.flarelane.demo',
    },
    accessToken: '1234567890abcdef',
  },
};

export const demoAllDevicesOverview = {
  ko: {
    android: 127426,
    ios: 92887,
    webDesktop: 178521,
    webMobile: 301778,
  },
  en: {
    android: 127426,
    ios: 92887,
    webDesktop: 178521,
    webMobile: 301778,
  },
};

export const demoSubDevicesOverview = {
  ko: {
    android: 113495,
    ios: 90476,
    webDesktop: 142351,
    webMobile: 267850,
  },
  en: {
    android: 113495,
    ios: 90476,
    webDesktop: 142351,
    webMobile: 267850,
  },
};

export const demoBillInfo = {
  ko: {
    companyName: 'FLAREMALL',
    managerName: '최대리',
    managerEmail: 'help@flaremall.com',
    managerTel: '010-0000-0000',
    paymentMethod: 'IAMPORT_CARD',
  },
  en: {
    companyName: 'FLAREMALL',
    managerName: 'Steve',
    managerEmail: 'help@flaremall.com',
    managerTel: '010-0000-0000',
    paymentMethod: 'IAMPORT_CARD',
  },
} as any;

export const demoCardInfo = {
  ko: {
    cardNumber: '0000********0000',
  },
  en: {
    cardNumber: '0000********0000',
  },
};

const allSubDevices = {
  ko:
    demoSubDevicesOverview.ko.android +
    demoSubDevicesOverview.ko.ios +
    demoSubDevicesOverview.ko.webDesktop +
    demoSubDevicesOverview.ko.webMobile,
  en:
    demoSubDevicesOverview.en.android +
    demoSubDevicesOverview.en.ios +
    demoSubDevicesOverview.en.webDesktop +
    demoSubDevicesOverview.en.webMobile,
};

export const demoPlanData = {
  ko: {
    planName: 'BUSINESS',
    startedAt: '',
    expiredAt: '',
    scheduledPlanName: null,
    deviceCount: allSubDevices.ko,
    planPayAmount: Number((allSubDevices.ko * 0).toFixed()),
  },
  en: {
    planName: 'BUSINESS',
    startedAt: '',
    expiredAt: '',
    scheduledPlanName: null,
    deviceCount: allSubDevices.en,
    planPayAmount: Number((allSubDevices.en * 0).toFixed()),
  },
} as any;

export const demoPoint = { ko: 0, en: 0 };

const getAtoBDates = (a: Date, b: Date) => {
  try {
    return eachDayOfInterval({
      start: a,
      end: b,
    }).map((d) => format(new Date(d), 'yyyy-MM-dd'));
  } catch {
    return [];
  }
};

const randomIntWithWeight = (min: number, max: number, w: number) => {
  return Number(
    ((Math.floor(Math.random() * (max - min)) + min) * w).toFixed()
  );
};

export const demoDashboardNewDevices = (a: Date, b: Date) => {
  const range = getAtoBDates(a, b);
  return range.map((d, i) => ({
    date: d,
    new: randomIntWithWeight(500, 1000, i <= 4 ? 1 : i * 0.1),
    unsubscribed: randomIntWithWeight(10, 200, i <= 4 ? 1 : i * 0.1),
  }));
};

export const demoDashboardSentConverted = (a: Date, b: Date) => {
  const range = getAtoBDates(a, b);
  return range.map((d, i) => {
    const sent =
      randomIntWithWeight(300000, 600000, 1) +
      randomIntWithWeight(30000, 60000, i * 0.1);
    return {
      date: d,
      sent: sent,
      converted: Number(
        (sent * ((Math.floor(Math.random() * (6 - 1)) + 1) / 10)).toFixed()
      ),
    };
  });
};

export const demoDashboardConvertedRank = () => {
  const value = [
    0.289,
    0.341,
    0.175,
    0.131,
    0.108,
    0.041,
    0.05,
    0.027,
    0.023,
    0.089,
    0.062,
    0.121,
    0.068,
    0.011,
    0.059,
    0.115,
    0.169,
    0.141,
    0.228,
    0.292,
    0.271,
    0.354,
    0.317,
    0.419,
  ];

  return new Array(24).fill(0).map((_, i) => ({ hour: i, average: value[i] }));
};

export const demoWebPushConfigs = {
  ko: {
    vapidPayload: {
      publicKey:
        'BGzfKEn2GWB7gy7Y0Brx19SCvRcxye6H7wijWs0Ek2r0j1OFpcEGqut3Rww1Y8RVYbS-0LJpXkj3Jkau0efjRIk',
    },
    siteName: '플레어몰',
    siteIcon:
      'https://imagedelivery.net/lfkkXChLw6uG9RcA2ff5fg/38cceeff-d415-4adf-f33e-36fc222b5d00/flarelane',
    siteBadge:
      'https://imagedelivery.net/lfkkXChLw6uG9RcA2ff5fg/8918123d-98b6-4e68-bdb8-b5a66a5fae00/flarelane',
    originSiteUrl: 'https://flaremall.com',
    welcomeNotificationConfig: {
      active: true,
      body: '알림 구독 완료! 이제 최신 소식들을 받아볼 수 있습니다.',
    },
    widgetConfig: {
      active: true,
      color: '#1d4289',
      size: 1,
      position: 'left',
      horizontalMargin: 15,
      verticalMargin: 15,
      mobileSize: 1,
      mobilePosition: 'left',
      mobileHorizontalMargin: 15,
      mobileVerticalMargin: 15,
      hideIfMobile: false,
      allowedPaths: ['/*'],
    },
    popupConfig: {
      active: true,
      title: '푸시 알림 받기',
      body:
        '알림을 구독하여 이 웹사이트의 최신 소식들을 받아보세요. 이후 언제든 구독을 취소할 수 있습니다.',
      allowButtonText: '구독',
      closeButtonText: '다음에',
      color: '#1d4289',
      retryIntervalDay: 7,
    },
  },
  en: {
    vapidPayload: {
      publicKey:
        'BGzfKEn2GWB7gy7Y0Brx19SCvRcxye6H7wijWs0Ek2r0j1OFpcEGqut3Rww1Y8RVYbS-0LJpXkj3Jkau0efjRIk',
    },
    siteName: 'FLAREMALL',
    siteIcon:
      'https://imagedelivery.net/lfkkXChLw6uG9RcA2ff5fg/38cceeff-d415-4adf-f33e-36fc222b5d00/flarelane',
    siteBadge:
      'https://imagedelivery.net/lfkkXChLw6uG9RcA2ff5fg/8918123d-98b6-4e68-bdb8-b5a66a5fae00/flarelane',
    originSiteUrl: 'https://flaremall.com',
    welcomeNotificationConfig: {
      active: true,
      body: 'You have successfully subscribed to our notifications.',
    },
    widgetConfig: {
      active: true,
      color: '#1d4289',
      size: 1,
      position: 'left',
      horizontalMargin: 15,
      verticalMargin: 15,
      mobileSize: 1,
      mobilePosition: 'left',
      mobileHorizontalMargin: 15,
      mobileVerticalMargin: 15,
      hideIfMobile: false,
      allowedPaths: ['/*'],
    },
    popupConfig: {
      active: true,
      title: 'Subsribe for important notifications',
      body: 'Subscribe now and get updated with our latest news.',
      allowButtonText: 'Subscribe',
      closeButtonText: 'No Thanks',
      color: '#1d4289',
      retryIntervalDay: 7,
    },
  },
} as any;

export const demoScheduledMessage = {
  ko: [
    {
      id: '0134d6c8-a794-4e8e-bbd6-26fb4963f7d7',
      createdAt: '2022-06-20T05:46:39.384Z',
      sendAfter: new Date(Date.now() + 5 * 3600 * 1000),
      status: 'WAITING',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      notification: {
        id: null,
        projectId: null,
        createdAt: null,
        title: '🏖 여름휴가 체크리스트 ✅',
        body:
          '뜨거운 여름 회사, 학교를 벗어나 휴가를 떠날 당신에게 필요한 것은?',
        url: '',
        imageUrl: null,
        templateId: null,
        data: null,
        senderSource: 'console',
        group: null,
        targetType: 'segment',
        targetIds: ['6935c2f7-d5c0-4392-b90c-755f52b4b9d0'],
        targetSnapshots: [
          {
            id: '1935c2f7-d5c0-4392-b90c-755f52b4b9d0',
            name: '알림 수신 가능 기기',
            filters: [],
            type: 'DEFAULT',
          },
        ],
        selectedAndroid: 0,
        selectedIos: 0,
        selectedWebDesktop: 0,
        selectedWebMobile: 0,
        sentAndroid: 0,
        sentIos: 0,
        sentWebDesktop: 0,
        sentWebMobile: 0,
        convertedAndroid: 0,
        convertedIos: 0,
        convertedWebDesktop: 0,
        convertedWebMobile: 0,
        failedAndroid: 0,
        failedIos: 0,
        failedWebDesktop: 0,
        failedWebMobile: 0,
        selectedTotal: 0,
        sentTotal: 0,
        convertedTotal: 0,
        failedTotal: 0,
        remainingTotal: 0,
        remainingAndroid: 0,
        remainingIos: 0,
        remainingWebDesktop: 0,
        remainingWebMobile: 0,
      },
    },
    {
      id: '18099acf-b38c-4dc2-88eb-5e080acbc3d8',
      createdAt: '2022-06-20T05:46:39.384Z',
      sendAfter: '2022-06-22T05:46:00.000Z',
      status: 'STARTED',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      notification: {
        id: '18099acf-b38c-4dc2-88eb-5e080acbc3d8',
        projectId: null,
        createdAt: null,
        title: '💓 가장 특별한 사람을 위한 선물',
        body: '고생한 나에게 어떤 발렌타인 선물을 줘볼까!',
        url: '',
        imageUrl: null,
        templateId: null,
        data: null,
        senderSource: 'console',
        group: null,
        targetType: 'segment',
        targetIds: ['6935c2f7-d5c0-4392-b90c-755f52b4b9d0'],
        targetSnapshots: [
          {
            id: '1935c2f7-d5c0-4392-b90c-755f52b4b9d0',
            name: '알림 수신 가능 기기',
            filters: [],
            type: 'DEFAULT',
          },
        ],
        selectedAndroid: 127426,
        selectedIos: 92887,
        selectedWebDesktop: 178521,
        selectedWebMobile: 301778,
        selectedTotal: 700612,
        sentAndroid: 107426,
        sentIos: 72887,
        sentWebDesktop: 158521,
        sentWebMobile: 281778,
        sentTotal: 700612,
        convertedAndroid: 39423,
        convertedIos: 25667,
        convertedWebDesktop: 49171,
        convertedWebMobile: 85612,
        convertedTotal: 199873,
        failedAndroid: 0,
        failedIos: 0,
        failedWebDesktop: 0,
        failedWebMobile: 0,
        failedTotal: 0,
        remainingAndroid: 20000,
        remainingIos: 20000,
        remainingWebDesktop: 20000,
        remainingWebMobile: 20000,
        remainingTotal: 80000,
      },
    },
    {
      id: 'e9421a85-dee4-4c3f-a004-2c6f07498029',
      createdAt: '2022-05-24T11:59:28.370Z',
      sendAfter: '2022-05-25T11:59:00.000Z',
      status: 'REQUESTED',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      notification: {
        id: '2224dcc1-8c81-4f75-a4fe-0d0570ed5e2b',
        projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
        createdAt: '2022-05-25T11:59:02.903Z',
        title: '👫 개성 다양한 MBTI별 추천 코디',
        body:
          '다양한 MBTI, 개성 넘치는 개별 추천코디! 내 MBTI에 어울리는 코디는?ㅇ',
        url: null,
        imageUrl: null,
        templateId: null,
        data: null,
        senderSource: 'console',
        group: null,
        targetType: 'segment',
        targetIds: ['6935c2f7-d5c0-4392-b90c-755f52b4b9d0'],
        targetSnapshots: [
          {
            id: '1935c2f7-d5c0-4392-b90c-755f52b4b9d0',
            name: '알림 수신 가능 기기',
            filters: [],
            type: 'DEFAULT',
          },
        ],
        selectedAndroid: 127426,
        selectedIos: 92887,
        selectedWebDesktop: 178521,
        selectedWebMobile: 301778,
        selectedTotal: 700612,
        sentAndroid: 127426,
        sentIos: 92887,
        sentWebDesktop: 178521,
        sentWebMobile: 301778,
        sentTotal: 700612,
        convertedAndroid: 39423,
        convertedIos: 25667,
        convertedWebDesktop: 49171,
        convertedWebMobile: 85612,
        convertedTotal: 199873,
        failedAndroid: 0,
        failedIos: 0,
        failedWebDesktop: 0,
        failedWebMobile: 0,
        failedTotal: 0,
        remainingAndroid: 0,
        remainingIos: 0,
        remainingWebDesktop: 0,
        remainingWebMobile: 0,
        remainingTotal: 0,
      },
    },
  ],
  en: [
    {
      id: '0134d6c8-a794-4e8e-bbd6-26fb4963f7d7',
      createdAt: '2022-06-20T05:46:39.384Z',
      sendAfter: new Date(Date.now() + 5 * 3600 * 1000),
      status: 'WAITING',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      notification: {
        id: null,
        projectId: null,
        createdAt: null,
        title: '🏖 Summer Vacation Checklist ✅',
        body: '7 Summer Vacation Must-Haves to Pack on Your Next Getaway',
        url: '',
        imageUrl: null,
        templateId: null,
        data: null,
        senderSource: 'console',
        group: null,
        targetType: 'segment',
        targetIds: ['6935c2f7-d5c0-4392-b90c-755f52b4b9d0'],
        targetSnapshots: [
          {
            id: '1935c2f7-d5c0-4392-b90c-755f52b4b9d0',
            name: 'Subscribed Devices',
            filters: [],
            type: 'DEFAULT',
          },
        ],
        selectedAndroid: 0,
        selectedIos: 0,
        selectedWebDesktop: 0,
        selectedWebMobile: 0,
        sentAndroid: 0,
        sentIos: 0,
        sentWebDesktop: 0,
        sentWebMobile: 0,
        convertedAndroid: 0,
        convertedIos: 0,
        convertedWebDesktop: 0,
        convertedWebMobile: 0,
        failedAndroid: 0,
        failedIos: 0,
        failedWebDesktop: 0,
        failedWebMobile: 0,
        selectedTotal: 0,
        sentTotal: 0,
        convertedTotal: 0,
        failedTotal: 0,
        remainingTotal: 0,
        remainingAndroid: 0,
        remainingIos: 0,
        remainingWebDesktop: 0,
        remainingWebMobile: 0,
      },
    },
    {
      id: '18099acf-b38c-4dc2-88eb-5e080acbc3d8',
      createdAt: '2022-06-20T05:46:39.384Z',
      sendAfter: '2022-06-22T05:46:00.000Z',
      status: 'STARTED',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      notification: {
        id: '18099acf-b38c-4dc2-88eb-5e080acbc3d8',
        projectId: null,
        createdAt: null,
        title: '💓 A special gift for myself',
        body: "35 Valentine's day gifts to buy yourself",
        url: '',
        imageUrl: null,
        templateId: null,
        data: null,
        senderSource: 'console',
        group: null,
        targetType: 'segment',
        targetIds: ['6935c2f7-d5c0-4392-b90c-755f52b4b9d0'],
        targetSnapshots: [
          {
            id: '1935c2f7-d5c0-4392-b90c-755f52b4b9d0',
            name: 'Subscribed Devices',
            filters: [],
            type: 'DEFAULT',
          },
        ],
        selectedAndroid: 127426,
        selectedIos: 92887,
        selectedWebDesktop: 178521,
        selectedWebMobile: 301778,
        selectedTotal: 700612,
        sentAndroid: 107426,
        sentIos: 72887,
        sentWebDesktop: 158521,
        sentWebMobile: 281778,
        sentTotal: 700612,
        convertedAndroid: 39423,
        convertedIos: 25667,
        convertedWebDesktop: 49171,
        convertedWebMobile: 85612,
        convertedTotal: 199873,
        failedAndroid: 0,
        failedIos: 0,
        failedWebDesktop: 0,
        failedWebMobile: 0,
        failedTotal: 0,
        remainingAndroid: 20000,
        remainingIos: 20000,
        remainingWebDesktop: 20000,
        remainingWebMobile: 20000,
        remainingTotal: 80000,
      },
    },
    {
      id: 'e9421a85-dee4-4c3f-a004-2c6f07498029',
      createdAt: '2022-05-24T11:59:28.370Z',
      sendAfter: '2022-05-25T11:59:00.000Z',
      status: 'REQUESTED',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      notification: {
        id: '2224dcc1-8c81-4f75-a4fe-0d0570ed5e2b',
        projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
        createdAt: '2022-05-25T11:59:02.903Z',
        title: '👫  Discover your personal style',
        body:
          'Here are some of the best styling recommendations and unique tips!',
        url: null,
        imageUrl: null,
        templateId: null,
        data: null,
        senderSource: 'console',
        group: null,
        targetType: 'segment',
        targetIds: ['6935c2f7-d5c0-4392-b90c-755f52b4b9d0'],
        targetSnapshots: [
          {
            id: '1935c2f7-d5c0-4392-b90c-755f52b4b9d0',
            name: 'Subscribed Devices',
            filters: [],
            type: 'DEFAULT',
          },
        ],
        selectedAndroid: 127426,
        selectedIos: 92887,
        selectedWebDesktop: 178521,
        selectedWebMobile: 301778,
        selectedTotal: 700612,
        sentAndroid: 127426,
        sentIos: 92887,
        sentWebDesktop: 178521,
        sentWebMobile: 301778,
        sentTotal: 700612,
        convertedAndroid: 39423,
        convertedIos: 25667,
        convertedWebDesktop: 49171,
        convertedWebMobile: 85612,
        convertedTotal: 199873,
        failedAndroid: 0,
        failedIos: 0,
        failedWebDesktop: 0,
        failedWebMobile: 0,
        failedTotal: 0,
        remainingAndroid: 0,
        remainingIos: 0,
        remainingWebDesktop: 0,
        remainingWebMobile: 0,
        remainingTotal: 0,
      },
    },
  ],
} as any;

export const demoSegments = {
  ko: [
    {
      id: 'd19ffb27-e6a3-48ad-ac20-b8baf3871671',
      createdAt: '2022-01-18T03:34:52.571Z',
      updatedAt: '2022-01-18T03:34:52.571Z',
      type: 'DEFAULT',
      name: '알림 수신 가능 기기',
      filters: [],
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      c: allSubDevices.ko,
    },
    {
      id: '716a7a25-54c7-446f-b2b3-aa9e54be658b',
      createdAt: '2022-06-24T01:18:05.673Z',
      updatedAt: '2022-06-24T01:18:05.674Z',
      type: null,
      name: '신규 유저',
      filters: [
        [
          {
            type: 'CREATED_AT_WITHIN_HOURS',
            values: ['EQ', '72'],
          },
        ],
      ],
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      c: 2812,
    },
    {
      id: 'a7b4df76-7d04-11ed-a1eb-0242ac120002',
      createdAt: '2022-06-24T01:18:35.673Z',
      updatedAt: '2022-06-24T01:18:35.674Z',
      type: null,
      name: '3일 이상 비회원',
      filters: [
        [
          {
            type: 'CREATED_AT_WITHIN_HOURS',
            values: ['NOT_EQ', '72'],
          },
          {
            type: 'USER_ID',
            values: ['NOT_EXIST', ''],
          },
        ],
      ],
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      c: 17235,
    },
    {
      id: 'e13daf5e-cd36-440f-8f6e-1410666ed3b0',
      createdAt: '2022-06-24T01:18:48.820Z',
      updatedAt: '2022-06-24T01:18:48.821Z',
      type: null,
      name: '장기 미접속 유저',
      filters: [
        [
          {
            type: 'LAST_ACTIVE_AT_WITHIN_HOURS',
            values: ['NOT_EQ', '2160'],
          },
        ],
      ],
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      c: 52048,
    },
    {
      id: '115f9f7a-ce34-4e52-a3da-0a8eada63eb5',
      createdAt: '2022-06-25T14:13:11.748Z',
      updatedAt: '2022-06-25T14:13:11.748Z',
      type: null,
      name: '장바구니 담은 유저',
      filters: [
        [
          {
            type: 'TAG',
            values: ['cartCount', 'GTE', '1'],
          },
        ],
      ],
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      c: 173005,
    },
    {
      id: 'e1a8eb5c-e8d4-49db-9e86-6999635ef153',
      createdAt: '2022-06-24T01:19:22.748Z',
      updatedAt: '2022-06-24T01:19:22.748Z',
      type: null,
      name: '미구매 유저',
      filters: [
        [
          {
            type: 'TAG',
            values: ['orderCount', 'EQ', '0'],
          },
        ],
      ],
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      c: 74671,
    },
    {
      id: '7397bc7c-7d04-11ed-a1eb-0242ac120002',
      createdAt: '2022-06-24T01:21:22.748Z',
      updatedAt: '2022-06-24T01:21:22.748Z',
      type: null,
      name: '생성 3시간 경과',
      filters: [
        [
          {
            type: 'CREATED_AT_WITHIN_HOURS',
            values: ['NOT_EQ', '3'],
          },
        ],
      ],
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      c: 142,
    },
    {
      id: '6cbd7414-7d04-11ed-a1eb-0242ac120002',
      createdAt: '2022-06-24T01:21:32.748Z',
      updatedAt: '2022-06-24T01:21:32.748Z',
      type: null,
      name: '생성 1일 경과',
      filters: [
        [
          {
            type: 'CREATED_AT_WITHIN_HOURS',
            values: ['NOT_EQ', '24'],
          },
        ],
      ],
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      c: 1212,
    },
    {
      id: '661edcd8-7d04-11ed-a1eb-0242ac120002',
      createdAt: '2022-06-24T01:21:42.748Z',
      updatedAt: '2022-06-24T01:21:42.748Z',
      type: null,
      name: '생성 2일 경과',
      filters: [
        [
          {
            type: 'CREATED_AT_WITHIN_HOURS',
            values: ['NOT_EQ', '48'],
          },
        ],
      ],
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      c: 2188,
    },
    {
      id: '5d1cc2c6-7d04-11ed-a1eb-0242ac120002',
      createdAt: '2022-06-25T13:45:11.748Z',
      updatedAt: '2022-06-25T13:45:11.748Z',
      type: null,
      name: '5일 미접속',
      filters: [
        [
          {
            type: 'LAST_ACTIVE_AT_WITHIN_HOURS',
            values: ['NOT_EQ', '120'],
          },
        ],
      ],
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      c: 25503,
    },
    {
      id: 'aba82b5c-e3d4-494b-9e86-6955635ef153',
      createdAt: '2022-06-25T13:55:11.748Z',
      updatedAt: '2022-06-25T13:55:11.748Z',
      type: null,
      name: '10일 미접속',
      filters: [
        [
          {
            type: 'LAST_ACTIVE_AT_WITHIN_HOURS',
            values: ['NOT_EQ', '240'],
          },
        ],
      ],
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      c: 15937,
    },
  ],
  en: [
    {
      id: 'd19ffb27-e6a3-48ad-ac20-b8baf3871671',
      createdAt: '2022-01-18T03:34:52.571Z',
      updatedAt: '2022-01-18T03:34:52.571Z',
      type: 'DEFAULT',
      name: 'Subscribed Devices',
      filters: [],
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      c: allSubDevices.en,
    },
    {
      id: '716a7a25-54c7-446f-b2b3-aa9e54be658b',
      createdAt: '2022-06-24T01:18:05.673Z',
      updatedAt: '2022-06-24T01:18:05.674Z',
      type: null,
      name: 'New Subscribers',
      filters: [
        [
          {
            type: 'CREATED_AT_WITHIN_HOURS',
            values: ['EQ', '72'],
          },
        ],
      ],
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      c: 2812,
    },
    {
      id: 'a7b4df76-7d04-11ed-a1eb-0242ac120002',
      createdAt: '2022-06-24T01:18:35.673Z',
      updatedAt: '2022-06-24T01:18:35.674Z',
      type: null,
      name: 'Not signed-up for 3 days',
      filters: [
        [
          {
            type: 'CREATED_AT_WITHIN_HOURS',
            values: ['NOT_EQ', '72'],
          },
          {
            type: 'USER_ID',
            values: ['NOT_EXIST', ''],
          },
        ],
      ],
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      c: 17235,
    },
    {
      id: 'e13daf5e-cd36-440f-8f6e-1410666ed3b0',
      createdAt: '2022-06-24T01:18:48.820Z',
      updatedAt: '2022-06-24T01:18:48.821Z',
      type: null,
      name: 'Dormant for 3 months',
      filters: [
        [
          {
            type: 'LAST_ACTIVE_AT_WITHIN_HOURS',
            values: ['NOT_EQ', '2160'],
          },
        ],
      ],
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      c: 52048,
    },
    {
      id: '115f9f7a-ce34-4e52-a3da-0a8eada63eb5',
      createdAt: '2022-06-25T14:13:11.748Z',
      updatedAt: '2022-06-25T14:13:11.748Z',
      type: null,
      name: 'Product added to cart',
      filters: [
        [
          {
            type: 'TAG',
            values: ['cartCount', 'GTE', '1'],
          },
        ],
      ],
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      c: 173005,
    },
    {
      id: 'e1a8eb5c-e8d4-49db-9e86-6999635ef153',
      createdAt: '2022-06-24T01:19:22.748Z',
      updatedAt: '2022-06-24T01:19:22.748Z',
      type: null,
      name: 'Non-buyers',
      filters: [
        [
          {
            type: 'TAG',
            values: ['orderCount', 'EQ', '0'],
          },
        ],
      ],
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      c: 74671,
    },
    {
      id: '7397bc7c-7d04-11ed-a1eb-0242ac120002',
      createdAt: '2022-06-24T01:21:22.748Z',
      updatedAt: '2022-06-24T01:21:22.748Z',
      type: null,
      name: '3 hours after opt-in',
      filters: [
        [
          {
            type: 'CREATED_AT_WITHIN_HOURS',
            values: ['NOT_EQ', '3'],
          },
        ],
      ],
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      c: 142,
    },
    {
      id: '6cbd7414-7d04-11ed-a1eb-0242ac120002',
      createdAt: '2022-06-24T01:21:32.748Z',
      updatedAt: '2022-06-24T01:21:32.748Z',
      type: null,
      name: '1 day after opt-in',
      filters: [
        [
          {
            type: 'CREATED_AT_WITHIN_HOURS',
            values: ['NOT_EQ', '24'],
          },
        ],
      ],
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      c: 1212,
    },
    {
      id: '661edcd8-7d04-11ed-a1eb-0242ac120002',
      createdAt: '2022-06-24T01:21:42.748Z',
      updatedAt: '2022-06-24T01:21:42.748Z',
      type: null,
      name: '2 days after opt-in',
      filters: [
        [
          {
            type: 'CREATED_AT_WITHIN_HOURS',
            values: ['NOT_EQ', '48'],
          },
        ],
      ],
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      c: 2188,
    },
    {
      id: '5d1cc2c6-7d04-11ed-a1eb-0242ac120002',
      createdAt: '2022-06-25T13:45:11.748Z',
      updatedAt: '2022-06-25T13:45:11.748Z',
      type: null,
      name: 'Last accessed 5 days ago',
      filters: [
        [
          {
            type: 'LAST_ACTIVE_AT_WITHIN_HOURS',
            values: ['NOT_EQ', '120'],
          },
        ],
      ],
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      c: 25503,
    },
    {
      id: 'aba82b5c-e3d4-494b-9e86-6955635ef153',
      createdAt: '2022-06-25T13:55:11.748Z',
      updatedAt: '2022-06-25T13:55:11.748Z',
      type: null,
      name: 'Last accessed 10 days ago',
      filters: [
        [
          {
            type: 'LAST_ACTIVE_AT_WITHIN_HOURS',
            values: ['NOT_EQ', '240'],
          },
        ],
      ],
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      c: 15937,
    },
  ],
} as any;

export const demoNotifications = {
  ko: [
    {
      id: '18099acf-b38c-4dc2-88eb-5e080acbc3d8',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      createdAt: new Date(Date.now() - 5 * 1000),
      title: '💓 가장 특별한 사람을 위한 선물',
      body: '고생한 나에게 어떤 발렌타인 선물을 줘볼까!',
      url: 'app://event/fE2Bz15FGG',
      imageUrl: null,
      templateId: null,
      data: null,
      senderSource: 'console',
      group: null,
      targetType: 'segment',
      targetIds: ['6935c2f7-d5c0-4392-b90c-755f52b4b9d0'],
      targetSnapshots: [
        {
          id: '1935c2f7-d5c0-4392-b90c-755f52b4b9d0',
          name: '알림 수신 가능 기기',
          filters: [],
          type: 'DEFAULT',
        },
      ],
      selectedAndroid: 127426,
      selectedIos: 92887,
      selectedWebDesktop: 178521,
      selectedWebMobile: 301778,
      selectedTotal: 700612,
      sentAndroid: 127426,
      sentIos: 92887,
      sentWebDesktop: 178521,
      sentWebMobile: 301778,
      sentTotal: 700612,
      convertedAndroid: 39423,
      convertedIos: 25667,
      convertedWebDesktop: 49171,
      convertedWebMobile: 85612,
      convertedTotal: 199873,
      failedAndroid: 0,
      failedIos: 0,
      failedWebDesktop: 0,
      failedWebMobile: 0,
      failedTotal: 0,
      remainingAndroid: 0,
      remainingIos: 0,
      remainingWebDesktop: 0,
      remainingWebMobile: 0,
      remainingTotal: 0,
      uninstalledAndroid: 0,
      uninstalledIos: 0,
      uninstalledWebDesktop: 0,
      uninstalledWebMobile: 0,
      uninstalledTotal: 0,
    },
    {
      id: '1a5dde33-378d-400b-89d5-96614b8c8c06',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      createdAt: new Date(Date.now() - 6 * 1000),
      title: '👥  또 다른 구매자 등장',
      body:
        '내가 관심있는 세미와이드 슬랙스에 새로운 구매 후기가 달렸어요. 한 번 확인해볼까요?',
      url: 'app://store/product/a5gGd2JB/comment',
      imageUrl: null,
      templateId: null,
      data: null,
      senderSource: 'api',
      group: null,
      targetType: 'userId',
      targetIds: [
        'myuser119987@flaremall.com',
        'myuser59258@flaremall.com',
        'myuser4787@flaremall.com',
        'myuser51533@flaremall.com',
      ],
      targetSnapshots: [
        {
          id: [
            'myuser119987@flaremall.com',
            'myuser59258@flaremall.com',
            'myuser4787@flaremall.com',
            'myuser51533@flaremall.com',
          ],
        },
      ],
      selectedAndroid: 2,
      selectedIos: 1,
      selectedWebDesktop: 1,
      selectedWebMobile: 0,
      selectedTotal: 4,
      sentAndroid: 2,
      sentIos: 1,
      sentWebDesktop: 1,
      sentWebMobile: 0,
      sentTotal: 4,
      convertedAndroid: 1,
      convertedIos: 0,
      convertedWebDesktop: 1,
      convertedWebMobile: 0,
      convertedTotal: 2,
      failedAndroid: 0,
      failedIos: 0,
      failedWebDesktop: 0,
      failedWebMobile: 0,
      failedTotal: 0,
      remainingAndroid: 0,
      remainingIos: 0,
      remainingWebDesktop: 0,
      remainingWebMobile: 0,
      remainingTotal: 0,
      uninstalledAndroid: 0,
      uninstalledIos: 0,
      uninstalledWebDesktop: 0,
      uninstalledWebMobile: 0,
      uninstalledTotal: 0,
    },
    {
      id: '98dab3f8-4a36-492c-897d-4c7f9e41b0bb',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      createdAt: new Date(Date.now() - 10 * 1000),
      title: '💌 할인 쿠폰 도착',
      body: '지금 쿠폰코드 "23FWSALE" 입력 시 20% 할인쿠폰 증정!',
      url: 'app://event/sH23VZPN3',
      imageUrl: null,
      templateId: null,
      data: null,
      senderSource: 'api',
      group: null,
      targetType: 'userId',
      targetIds: ['myuser8362@flaremall.com', 'myuser82543@flaremall.com'],
      targetSnapshots: [
        {
          id: ['myuser8362@flaremall.com', 'myuser82543@flaremall.com'],
        },
      ],
      selectedAndroid: 0,
      selectedIos: 1,
      selectedWebDesktop: 0,
      selectedWebMobile: 1,
      selectedTotal: 2,
      sentAndroid: 0,
      sentIos: 1,
      sentWebDesktop: 0,
      sentWebMobile: 1,
      sentTotal: 2,
      convertedAndroid: 0,
      convertedIos: 1,
      convertedWebDesktop: 0,
      convertedWebMobile: 1,
      convertedTotal: 2,
      failedAndroid: 0,
      failedIos: 0,
      failedWebDesktop: 0,
      failedWebMobile: 0,
      failedTotal: 0,
      remainingAndroid: 0,
      remainingIos: 0,
      remainingWebDesktop: 0,
      remainingWebMobile: 0,
      remainingTotal: 0,
      uninstalledAndroid: 0,
      uninstalledIos: 0,
      uninstalledWebDesktop: 0,
      uninstalledWebMobile: 0,
      uninstalledTotal: 0,
    },
    {
      id: '3f6e21c9-7821-4bc8-a459-9a0456f07c00',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      createdAt: new Date(Date.now() - 12 * 1000),
      title: '📱 띵동~ 김박사 님을 위한 꿀 TIP 🍯',
      body:
        '모바일 앱으로 더욱 쾌적한 사용이 가능해요. 앱 전용 할인쿠폰까지 증정! 💸',
      url: 'https://itunes.apple.com/kr/app/podcast/ZkmdT1',
      imageUrl: null,
      templateId: null,
      data: null,
      senderSource: 'api',
      group: null,
      targetType: 'userId',
      targetIds: ['myuser186644@flaremall.com'],
      targetSnapshots: [
        {
          id: 'myuser186644@flaremall.com',
        },
      ],
      selectedAndroid: 0,
      selectedIos: 0,
      selectedWebDesktop: 0,
      selectedWebMobile: 1,
      selectedTotal: 1,
      sentAndroid: 0,
      sentIos: 0,
      sentWebDesktop: 0,
      sentWebMobile: 1,
      sentTotal: 1,
      convertedAndroid: 0,
      convertedIos: 0,
      convertedWebDesktop: 0,
      convertedWebMobile: 1,
      convertedTotal: 1,
      failedAndroid: 0,
      failedIos: 0,
      failedWebDesktop: 0,
      failedWebMobile: 0,
      failedTotal: 0,
      remainingAndroid: 0,
      remainingIos: 0,
      remainingWebDesktop: 0,
      remainingWebMobile: 0,
      remainingTotal: 0,
      uninstalledAndroid: 0,
      uninstalledIos: 0,
      uninstalledWebDesktop: 0,
      uninstalledWebMobile: 0,
      uninstalledTotal: 0,
    },
    {
      id: 'e3592dc7-59c1-44b4-a960-d96e9d389058',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      createdAt: new Date(Date.now() - 14 * 1000),
      title: '🛒 가득 찬 장바구니',
      body:
        '장바구니에 담은 3개 상품들이 구매를 기다리고 있어요. 설마 잊으신 건 아니죠?',
      url: 'https://flaremall.com/store/cart',
      imageUrl: null,
      templateId: null,
      data: null,
      senderSource: 'api',
      group: null,
      targetType: 'userId',
      targetIds: ['myuser9342@flaremall.com'],
      targetSnapshots: [
        {
          id: 'myuser9342@flaremall.com',
        },
      ],
      selectedAndroid: 1,
      selectedIos: 0,
      selectedWebDesktop: 0,
      selectedWebMobile: 0,
      selectedTotal: 1,
      sentAndroid: 1,
      sentIos: 0,
      sentWebDesktop: 0,
      sentWebMobile: 0,
      sentTotal: 1,
      convertedAndroid: 1,
      convertedIos: 0,
      convertedWebDesktop: 0,
      convertedWebMobile: 0,
      convertedTotal: 1,
      failedAndroid: 0,
      failedIos: 0,
      failedWebDesktop: 0,
      failedWebMobile: 0,
      failedTotal: 0,
      remainingAndroid: 0,
      remainingIos: 0,
      remainingWebDesktop: 0,
      remainingWebMobile: 0,
      remainingTotal: 0,
      uninstalledAndroid: 0,
      uninstalledIos: 0,
      uninstalledWebDesktop: 0,
      uninstalledWebMobile: 0,
      uninstalledTotal: 0,
    },
    {
      id: '14ac6a63-b2a2-4335-9102-43092f3ac920',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      createdAt: new Date(Date.now() - 18 * 1000),
      title: '🏖 여름휴가 체크리스트 ✅',
      body: '뜨거운 여름 회사, 학교를 벗어나 휴가를 떠날 당신에게 필요한 것은?',
      url: 'https://flarelane.com/event',
      imageUrl: null,
      templateId: null,
      data: null,
      senderSource: 'console',
      group: null,
      targetType: 'segment',
      targetIds: ['6935c2f7-d5c0-4392-b90c-755f52b4b9d0'],
      targetSnapshots: [
        {
          id: '1935c2f7-d5c0-4392-b90c-755f52b4b9d0',
          name: '알림 수신 가능 기기',
          filters: [],
          type: 'DEFAULT',
        },
      ],
      selectedAndroid: 115726,
      selectedIos: 90257,
      selectedWebDesktop: 158721,
      selectedWebMobile: 298878,
      selectedTotal: 663582,
      sentAndroid: 115726,
      sentIos: 90257,
      sentWebDesktop: 158721,
      sentWebMobile: 298878,
      sentTotal: 663582,
      convertedAndroid: 38123,
      convertedIos: 26667,
      convertedWebDesktop: 51171,
      convertedWebMobile: 99612,
      convertedTotal: 215573,
      failedAndroid: 0,
      failedIos: 0,
      failedWebDesktop: 0,
      failedWebMobile: 0,
      failedTotal: 0,
      remainingAndroid: 0,
      remainingIos: 0,
      remainingWebDesktop: 0,
      remainingWebMobile: 0,
      remainingTotal: 0,
      uninstalledAndroid: 0,
      uninstalledIos: 0,
      uninstalledWebDesktop: 0,
      uninstalledWebMobile: 0,
      uninstalledTotal: 0,
    },
    {
      id: '0fd72cee-04f8-4697-a3b9-3677e2632d53',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      createdAt: new Date(Date.now() - 19 * 1000),
      title: '🚚 배송 시작',
      body: '주문하신 쓰리포켓 박스티(3 COL..이 이제 막 출발했어요!',
      url: 'app://mypage/sgGkLc3/state',
      imageUrl: null,
      templateId: null,
      data: null,
      senderSource: 'api',
      group: null,
      targetType: 'userId',
      targetIds: ['myuser239987@flaremall.com'],
      targetSnapshots: [
        {
          id: 'myuser239987@flaremall.com',
        },
      ],
      selectedAndroid: 0,
      selectedIos: 1,
      selectedWebDesktop: 0,
      selectedWebMobile: 0,
      selectedTotal: 1,
      sentAndroid: 0,
      sentIos: 1,
      sentWebDesktop: 0,
      sentWebMobile: 0,
      sentTotal: 1,
      convertedAndroid: 0,
      convertedIos: 1,
      convertedWebDesktop: 0,
      convertedWebMobile: 0,
      convertedTotal: 1,
      failedAndroid: 0,
      failedIos: 0,
      failedWebDesktop: 0,
      failedWebMobile: 0,
      failedTotal: 0,
      remainingAndroid: 0,
      remainingIos: 0,
      remainingWebDesktop: 0,
      remainingWebMobile: 0,
      remainingTotal: 0,
      uninstalledAndroid: 0,
      uninstalledIos: 0,
      uninstalledWebDesktop: 0,
      uninstalledWebMobile: 0,
      uninstalledTotal: 0,
    },
    {
      id: '2224dcc1-8c81-4f75-a4fe-0d0570ed5e2b',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      createdAt: new Date(Date.now() - 32 * 1000),
      title: '👫 개성 다양한 MBTI별 추천 코디',
      body:
        '다양한 MBTI, 개성 넘치는 개별 추천코디! 내 MBTI에 어울리는 코디는?',
      url: 'https://flaremall.com/event/4gYk7cGh',
      imageUrl: null,
      templateId: null,
      data: null,
      senderSource: 'console',
      group: null,
      targetType: 'segment',
      targetIds: ['6935c2f7-d5c0-4392-b90c-755f52b4b9d0'],
      targetSnapshots: [
        {
          id: '1935c2f7-d5c0-4392-b90c-755f52b4b9d0',
          name: '알림 수신 가능 기기',
          filters: [],
          type: 'DEFAULT',
        },
      ],
      selectedAndroid: 115726,
      selectedIos: 90257,
      selectedWebDesktop: 158721,
      selectedWebMobile: 298878,
      selectedTotal: 663582,
      sentAndroid: 115726,
      sentIos: 90257,
      sentWebDesktop: 158721,
      sentWebMobile: 298878,
      sentTotal: 663582,
      convertedAndroid: 37823,
      convertedIos: 25611,
      convertedWebDesktop: 55171,
      convertedWebMobile: 89612,
      convertedTotal: 208217,
      failedAndroid: 0,
      failedIos: 0,
      failedWebDesktop: 0,
      failedWebMobile: 0,
      failedTotal: 0,
      remainingAndroid: 0,
      remainingIos: 0,
      remainingWebDesktop: 0,
      remainingWebMobile: 0,
      remainingTotal: 0,
      uninstalledAndroid: 0,
      uninstalledIos: 0,
      uninstalledWebDesktop: 0,
      uninstalledWebMobile: 0,
      uninstalledTotal: 0,
    },
    {
      id: '98a01328-e9db-46fb-9281-3f3365679ddb',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      createdAt: new Date(Date.now() - 41 * 1000),
      title: '🚚 배송 시작',
      body: '주문하신 스트링와이드밴딩팬..이 이제 막 출발했어요!',
      url: 'app://mypage/f8ToVdy/state',
      imageUrl: null,
      templateId: null,
      data: null,
      senderSource: 'api',
      group: null,
      targetType: 'userId',
      targetIds: ['myuser1753@flaremall.com'],
      targetSnapshots: [
        {
          id: 'myuser451162@flaremall.com',
        },
      ],
      selectedAndroid: 0,
      selectedIos: 1,
      selectedWebDesktop: 0,
      selectedWebMobile: 0,
      selectedTotal: 1,
      sentAndroid: 0,
      sentIos: 1,
      sentWebDesktop: 0,
      sentWebMobile: 0,
      sentTotal: 1,
      convertedAndroid: 0,
      convertedIos: 1,
      convertedWebDesktop: 0,
      convertedWebMobile: 0,
      convertedTotal: 1,
      failedAndroid: 0,
      failedIos: 0,
      failedWebDesktop: 0,
      failedWebMobile: 0,
      failedTotal: 0,
      remainingAndroid: 0,
      remainingIos: 0,
      remainingWebDesktop: 0,
      remainingWebMobile: 0,
      remainingTotal: 0,
      uninstalledAndroid: 0,
      uninstalledIos: 0,
      uninstalledWebDesktop: 0,
      uninstalledWebMobile: 0,
      uninstalledTotal: 0,
    },
    {
      id: '994677a9-4271-4d7b-a890-ca7bac276e21',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      createdAt: new Date(Date.now() - 44 * 1000),
      title: '🚚 배송 시작',
      body: '주문하신 퍼프 크롭 반팔..이 이제 막 출발했어요!',
      url: 'app://mypage/Pv74Jso7/state',
      imageUrl: null,
      templateId: null,
      data: {},
      senderSource: 'api',
      group: '1',
      targetType: 'userId',
      targetIds: ['myuser1753@flaremall.com'],
      targetSnapshots: [
        {
          id: 'myuser1753@flaremall.com',
        },
      ],
      selectedAndroid: 0,
      selectedIos: 1,
      selectedWebDesktop: 0,
      selectedWebMobile: 0,
      selectedTotal: 1,
      sentAndroid: 0,
      sentIos: 1,
      sentWebDesktop: 0,
      sentWebMobile: 0,
      sentTotal: 1,
      convertedAndroid: 0,
      convertedIos: 1,
      convertedWebDesktop: 0,
      convertedWebMobile: 0,
      convertedTotal: 1,
      failedAndroid: 0,
      failedIos: 0,
      failedWebDesktop: 0,
      failedWebMobile: 0,
      failedTotal: 0,
      remainingAndroid: 0,
      remainingIos: 0,
      remainingWebDesktop: 0,
      remainingWebMobile: 0,
      remainingTotal: 0,
      uninstalledAndroid: 0,
      uninstalledIos: 0,
      uninstalledWebDesktop: 0,
      uninstalledWebMobile: 0,
      uninstalledTotal: 0,
    },
  ],
  en: [
    {
      id: '18099acf-b38c-4dc2-88eb-5e080acbc3d8',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      createdAt: new Date(Date.now() - 5 * 1000),
      title: '💓 A special gift for myself',
      body: "35 Valentine's day gifts to buy yourself",
      url: 'app://event/fE2Bz15FGG',
      imageUrl: null,
      templateId: null,
      data: null,
      senderSource: 'console',
      group: null,
      targetType: 'segment',
      targetIds: ['6935c2f7-d5c0-4392-b90c-755f52b4b9d0'],
      targetSnapshots: [
        {
          id: '1935c2f7-d5c0-4392-b90c-755f52b4b9d0',
          name: 'Subscribed Devices',
          filters: [],
          type: 'DEFAULT',
        },
      ],
      selectedAndroid: 127426,
      selectedIos: 92887,
      selectedWebDesktop: 178521,
      selectedWebMobile: 301778,
      selectedTotal: 700612,
      sentAndroid: 127426,
      sentIos: 92887,
      sentWebDesktop: 178521,
      sentWebMobile: 301778,
      sentTotal: 700612,
      convertedAndroid: 39423,
      convertedIos: 25667,
      convertedWebDesktop: 49171,
      convertedWebMobile: 85612,
      convertedTotal: 199873,
      failedAndroid: 0,
      failedIos: 0,
      failedWebDesktop: 0,
      failedWebMobile: 0,
      failedTotal: 0,
      remainingAndroid: 0,
      remainingIos: 0,
      remainingWebDesktop: 0,
      remainingWebMobile: 0,
      remainingTotal: 0,
      uninstalledAndroid: 0,
      uninstalledIos: 0,
      uninstalledWebDesktop: 0,
      uninstalledWebMobile: 0,
      uninstalledTotal: 0,
    },
    {
      id: '1a5dde33-378d-400b-89d5-96614b8c8c06',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      createdAt: new Date(Date.now() - 6 * 1000),
      title: '👥  Check out reviews',
      body:
        "There's a review for the Semi-wide Slacks that you might be interested in. You should check it out!",
      url: 'app://store/product/a5gGd2JB/comment',
      imageUrl: null,
      templateId: null,
      data: null,
      senderSource: 'api',
      group: null,
      targetType: 'userId',
      targetIds: [
        'myuser119987@flaremall.com',
        'myuser59258@flaremall.com',
        'myuser4787@flaremall.com',
        'myuser51533@flaremall.com',
      ],
      targetSnapshots: [
        {
          id: [
            'myuser119987@flaremall.com',
            'myuser59258@flaremall.com',
            'myuser4787@flaremall.com',
            'myuser51533@flaremall.com',
          ],
        },
      ],
      selectedAndroid: 2,
      selectedIos: 1,
      selectedWebDesktop: 1,
      selectedWebMobile: 0,
      selectedTotal: 4,
      sentAndroid: 2,
      sentIos: 1,
      sentWebDesktop: 1,
      sentWebMobile: 0,
      sentTotal: 4,
      convertedAndroid: 1,
      convertedIos: 0,
      convertedWebDesktop: 1,
      convertedWebMobile: 0,
      convertedTotal: 2,
      failedAndroid: 0,
      failedIos: 0,
      failedWebDesktop: 0,
      failedWebMobile: 0,
      failedTotal: 0,
      remainingAndroid: 0,
      remainingIos: 0,
      remainingWebDesktop: 0,
      remainingWebMobile: 0,
      remainingTotal: 0,
      uninstalledAndroid: 0,
      uninstalledIos: 0,
      uninstalledWebDesktop: 0,
      uninstalledWebMobile: 0,
      uninstalledTotal: 0,
    },
    {
      id: '98dab3f8-4a36-492c-897d-4c7f9e41b0bb',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      createdAt: new Date(Date.now() - 10 * 1000),
      title: '💌 Promo code just for you!',
      body: 'Use coupon code ‘23FWSALE’ and get 20% off!',
      url: 'app://event/sH23VZPN3',
      imageUrl: null,
      templateId: null,
      data: null,
      senderSource: 'api',
      group: null,
      targetType: 'userId',
      targetIds: ['myuser8362@flaremall.com', 'myuser82543@flaremall.com'],
      targetSnapshots: [
        {
          id: ['myuser8362@flaremall.com', 'myuser82543@flaremall.com'],
        },
      ],
      selectedAndroid: 0,
      selectedIos: 1,
      selectedWebDesktop: 0,
      selectedWebMobile: 1,
      selectedTotal: 2,
      sentAndroid: 0,
      sentIos: 1,
      sentWebDesktop: 0,
      sentWebMobile: 1,
      sentTotal: 2,
      convertedAndroid: 0,
      convertedIos: 1,
      convertedWebDesktop: 0,
      convertedWebMobile: 1,
      convertedTotal: 2,
      failedAndroid: 0,
      failedIos: 0,
      failedWebDesktop: 0,
      failedWebMobile: 0,
      failedTotal: 0,
      remainingAndroid: 0,
      remainingIos: 0,
      remainingWebDesktop: 0,
      remainingWebMobile: 0,
      remainingTotal: 0,
      uninstalledAndroid: 0,
      uninstalledIos: 0,
      uninstalledWebDesktop: 0,
      uninstalledWebMobile: 0,
      uninstalledTotal: 0,
    },
    {
      id: '3f6e21c9-7821-4bc8-a459-9a0456f07c00',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      createdAt: new Date(Date.now() - 12 * 1000),
      title: '📱 Knock Knock 🍯',
      body: 'Download mobile app now and get mobile only coupons! 💸',
      url: 'https://itunes.apple.com/kr/app/podcast/ZkmdT1',
      imageUrl: null,
      templateId: null,
      data: null,
      senderSource: 'api',
      group: null,
      targetType: 'userId',
      targetIds: ['myuser186644@flaremall.com'],
      targetSnapshots: [
        {
          id: 'myuser186644@flaremall.com',
        },
      ],
      selectedAndroid: 0,
      selectedIos: 0,
      selectedWebDesktop: 0,
      selectedWebMobile: 1,
      selectedTotal: 1,
      sentAndroid: 0,
      sentIos: 0,
      sentWebDesktop: 0,
      sentWebMobile: 1,
      sentTotal: 1,
      convertedAndroid: 0,
      convertedIos: 0,
      convertedWebDesktop: 0,
      convertedWebMobile: 1,
      convertedTotal: 1,
      failedAndroid: 0,
      failedIos: 0,
      failedWebDesktop: 0,
      failedWebMobile: 0,
      failedTotal: 0,
      remainingAndroid: 0,
      remainingIos: 0,
      remainingWebDesktop: 0,
      remainingWebMobile: 0,
      remainingTotal: 0,
      uninstalledAndroid: 0,
      uninstalledIos: 0,
      uninstalledWebDesktop: 0,
      uninstalledWebMobile: 0,
      uninstalledTotal: 0,
    },
    {
      id: 'e3592dc7-59c1-44b4-a960-d96e9d389058',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      createdAt: new Date(Date.now() - 14 * 1000),
      title: '🛒 Forget Something?',
      body: 'It seems like you left 3 products in the shopping cart',
      url: 'https://flaremall.com/store/cart',
      imageUrl: null,
      templateId: null,
      data: null,
      senderSource: 'api',
      group: null,
      targetType: 'userId',
      targetIds: ['myuser9342@flaremall.com'],
      targetSnapshots: [
        {
          id: 'myuser9342@flaremall.com',
        },
      ],
      selectedAndroid: 1,
      selectedIos: 0,
      selectedWebDesktop: 0,
      selectedWebMobile: 0,
      selectedTotal: 1,
      sentAndroid: 1,
      sentIos: 0,
      sentWebDesktop: 0,
      sentWebMobile: 0,
      sentTotal: 1,
      convertedAndroid: 1,
      convertedIos: 0,
      convertedWebDesktop: 0,
      convertedWebMobile: 0,
      convertedTotal: 1,
      failedAndroid: 0,
      failedIos: 0,
      failedWebDesktop: 0,
      failedWebMobile: 0,
      failedTotal: 0,
      remainingAndroid: 0,
      remainingIos: 0,
      remainingWebDesktop: 0,
      remainingWebMobile: 0,
      remainingTotal: 0,
      uninstalledAndroid: 0,
      uninstalledIos: 0,
      uninstalledWebDesktop: 0,
      uninstalledWebMobile: 0,
      uninstalledTotal: 0,
    },
    {
      id: '14ac6a63-b2a2-4335-9102-43092f3ac920',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      createdAt: new Date(Date.now() - 18 * 1000),
      title: '🏖 Summer Vacation Checklist ✅',
      body: '7 Summer Vacation Must-Haves to Pack on Your Next Getaway',
      url: 'https://flarelane.com/event',
      imageUrl: null,
      templateId: null,
      data: null,
      senderSource: 'console',
      group: null,
      targetType: 'segment',
      targetIds: ['6935c2f7-d5c0-4392-b90c-755f52b4b9d0'],
      targetSnapshots: [
        {
          id: '1935c2f7-d5c0-4392-b90c-755f52b4b9d0',
          name: 'Subscribed Devices',
          filters: [],
          type: 'DEFAULT',
        },
      ],
      selectedAndroid: 115726,
      selectedIos: 90257,
      selectedWebDesktop: 158721,
      selectedWebMobile: 298878,
      selectedTotal: 663582,
      sentAndroid: 115726,
      sentIos: 90257,
      sentWebDesktop: 158721,
      sentWebMobile: 298878,
      sentTotal: 663582,
      convertedAndroid: 38123,
      convertedIos: 26667,
      convertedWebDesktop: 51171,
      convertedWebMobile: 99612,
      convertedTotal: 215573,
      failedAndroid: 0,
      failedIos: 0,
      failedWebDesktop: 0,
      failedWebMobile: 0,
      failedTotal: 0,
      remainingAndroid: 0,
      remainingIos: 0,
      remainingWebDesktop: 0,
      remainingWebMobile: 0,
      remainingTotal: 0,
      uninstalledAndroid: 0,
      uninstalledIos: 0,
      uninstalledWebDesktop: 0,
      uninstalledWebMobile: 0,
      uninstalledTotal: 0,
    },
    {
      id: '0fd72cee-04f8-4697-a3b9-3677e2632d53',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      createdAt: new Date(Date.now() - 19 * 1000),
      title: '🚚 Shipped',
      body: 'The 3 Pocket Boxy T-Shirt you ordered has been shipped',
      url: 'app://mypage/sgGkLc3/state',
      imageUrl: null,
      templateId: null,
      data: null,
      senderSource: 'api',
      group: null,
      targetType: 'userId',
      targetIds: ['myuser239987@flaremall.com'],
      targetSnapshots: [
        {
          id: 'myuser239987@flaremall.com',
        },
      ],
      selectedAndroid: 0,
      selectedIos: 1,
      selectedWebDesktop: 0,
      selectedWebMobile: 0,
      selectedTotal: 1,
      sentAndroid: 0,
      sentIos: 1,
      sentWebDesktop: 0,
      sentWebMobile: 0,
      sentTotal: 1,
      convertedAndroid: 0,
      convertedIos: 1,
      convertedWebDesktop: 0,
      convertedWebMobile: 0,
      convertedTotal: 1,
      failedAndroid: 0,
      failedIos: 0,
      failedWebDesktop: 0,
      failedWebMobile: 0,
      failedTotal: 0,
      remainingAndroid: 0,
      remainingIos: 0,
      remainingWebDesktop: 0,
      remainingWebMobile: 0,
      remainingTotal: 0,
      uninstalledAndroid: 0,
      uninstalledIos: 0,
      uninstalledWebDesktop: 0,
      uninstalledWebMobile: 0,
      uninstalledTotal: 0,
    },
    {
      id: '2224dcc1-8c81-4f75-a4fe-0d0570ed5e2b',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      createdAt: new Date(Date.now() - 32 * 1000),
      title: '👫 Discover your personal style',
      body:
        'Here are some of the best styling recommendations and unique tips!',
      url: 'https://flaremall.com/event/4gYk7cGh',
      imageUrl: null,
      templateId: null,
      data: null,
      senderSource: 'console',
      group: null,
      targetType: 'segment',
      targetIds: ['6935c2f7-d5c0-4392-b90c-755f52b4b9d0'],
      targetSnapshots: [
        {
          id: '1935c2f7-d5c0-4392-b90c-755f52b4b9d0',
          name: 'Subscribed Devices',
          filters: [],
          type: 'DEFAULT',
        },
      ],
      selectedAndroid: 115726,
      selectedIos: 90257,
      selectedWebDesktop: 158721,
      selectedWebMobile: 298878,
      selectedTotal: 663582,
      sentAndroid: 115726,
      sentIos: 90257,
      sentWebDesktop: 158721,
      sentWebMobile: 298878,
      sentTotal: 663582,
      convertedAndroid: 37823,
      convertedIos: 25611,
      convertedWebDesktop: 55171,
      convertedWebMobile: 89612,
      convertedTotal: 208217,
      failedAndroid: 0,
      failedIos: 0,
      failedWebDesktop: 0,
      failedWebMobile: 0,
      failedTotal: 0,
      remainingAndroid: 0,
      remainingIos: 0,
      remainingWebDesktop: 0,
      remainingWebMobile: 0,
      remainingTotal: 0,
      uninstalledAndroid: 0,
      uninstalledIos: 0,
      uninstalledWebDesktop: 0,
      uninstalledWebMobile: 0,
      uninstalledTotal: 0,
    },
    {
      id: '98a01328-e9db-46fb-9281-3f3365679ddb',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      createdAt: new Date(Date.now() - 41 * 1000),
      title: '🚚 Shipped',
      body: 'The String Wide Bandi... you ordered has been shipped',
      url: 'app://mypage/f8ToVdy/state',
      imageUrl: null,
      templateId: null,
      data: null,
      senderSource: 'api',
      group: null,
      targetType: 'userId',
      targetIds: ['myuser1753@flaremall.com'],
      targetSnapshots: [
        {
          id: 'myuser451162@flaremall.com',
        },
      ],
      selectedAndroid: 0,
      selectedIos: 1,
      selectedWebDesktop: 0,
      selectedWebMobile: 0,
      selectedTotal: 1,
      sentAndroid: 0,
      sentIos: 1,
      sentWebDesktop: 0,
      sentWebMobile: 0,
      sentTotal: 1,
      convertedAndroid: 0,
      convertedIos: 1,
      convertedWebDesktop: 0,
      convertedWebMobile: 0,
      convertedTotal: 1,
      failedAndroid: 0,
      failedIos: 0,
      failedWebDesktop: 0,
      failedWebMobile: 0,
      failedTotal: 0,
      remainingAndroid: 0,
      remainingIos: 0,
      remainingWebDesktop: 0,
      remainingWebMobile: 0,
      remainingTotal: 0,
      uninstalledAndroid: 0,
      uninstalledIos: 0,
      uninstalledWebDesktop: 0,
      uninstalledWebMobile: 0,
      uninstalledTotal: 0,
    },
    {
      id: '994677a9-4271-4d7b-a890-ca7bac276e21',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      createdAt: new Date(Date.now() - 44 * 1000),
      title: '🚚 Shipped',
      body: 'The Puffer Crop Jacket you ordered has been shipped',
      url: 'app://mypage/Pv74Jso7/state',
      imageUrl: null,
      templateId: null,
      data: {},
      senderSource: 'api',
      group: '1',
      targetType: 'userId',
      targetIds: ['myuser1753@flaremall.com'],
      targetSnapshots: [
        {
          id: 'myuser1753@flaremall.com',
        },
      ],
      selectedAndroid: 0,
      selectedIos: 1,
      selectedWebDesktop: 0,
      selectedWebMobile: 0,
      selectedTotal: 1,
      sentAndroid: 0,
      sentIos: 1,
      sentWebDesktop: 0,
      sentWebMobile: 0,
      sentTotal: 1,
      convertedAndroid: 0,
      convertedIos: 1,
      convertedWebDesktop: 0,
      convertedWebMobile: 0,
      convertedTotal: 1,
      failedAndroid: 0,
      failedIos: 0,
      failedWebDesktop: 0,
      failedWebMobile: 0,
      failedTotal: 0,
      remainingAndroid: 0,
      remainingIos: 0,
      remainingWebDesktop: 0,
      remainingWebMobile: 0,
      remainingTotal: 0,
      uninstalledAndroid: 0,
      uninstalledIos: 0,
      uninstalledWebDesktop: 0,
      uninstalledWebMobile: 0,
      uninstalledTotal: 0,
    },
  ],
} as any;

export const demoTemplates = {
  ko: [
    {
      id: '5cfb7da1-feb2-4f4f-a1f2-c0cbc24c55f6',
      createdAt: '2021-12-04T05:03:24.727Z',
      updatedAt: '2021-12-13T04:46:41.379Z',
      name: '결제 성공',
      category: {
        id: '965446bf-baf9-4552-8b55-f83b078c5571',
        createdAt: null,
        updatedAt: null,
        name: '결제',
        color: '#67995e',
      },
      notification: {
        url: 'app://store/buy/{{ productId }}',
        title: '🎉 결제 완료',
        body: '"{{ productName }}" 상품을 집 앞으로 빠르게 배송해드릴게요!',
      },
    },
    {
      id: '5cfb7da1-feb2-4f4f-a1f2-c0cbc24c55f1',
      createdAt: '2021-11-21T15:24:01.727Z',
      updatedAt: '2021-12-21T16:17:34.379Z',
      name: '쿠폰 프로모션',
      category: {
        id: '965446bf-baf9-4552-8b55-f83b078c5571',
        createdAt: null,
        updatedAt: null,
        name: '결제',
        color: '#67995e',
      },
      notification: {
        url: 'app://store/coupon/{{couponCode}}',
        title: '💌 할인 쿠폰 도착',
        body:
          '지금 쿠폰코드 {{ couponCode }} 입력 시 {{ couponRate }} 할인 쿠폰 증정!',
      },
    },
    {
      id: '5cfb7da1-feb2-4f4f-a1f2-c0cbc24c55f2',
      createdAt: '2021-12-04T11:23:56.727Z',
      updatedAt: '2021-12-30T16:11:34.379Z',
      name: '장바구니 비우기',
      category: {
        id: '965446bf-baf9-4552-8b55-f83b078c5571',
        createdAt: null,
        updatedAt: null,
        name: '결제',
        color: '#67995e',
      },
      notification: {
        url: 'app://store/cart',
        title: '🛒 가득 찬 장바구니',
        body:
          '장바구니에 담은 {{ cartCount }}개 상품들이 구매를 기다리고 있어요. 설마 잊으신 건 아니죠?',
      },
    },
    {
      id: '5cfb7da1-feb2-4f4f-a1f2-c0cbc24c55f3',
      createdAt: '2021-12-30T15:37:23.727Z',
      updatedAt: '2022-01-23T11:51:44.379Z',
      name: '구매 유도',
      category: {
        id: '965446bf-baf9-4552-8b55-f83b078c5571',
        createdAt: null,
        updatedAt: null,
        name: '결제',
        color: '#67995e',
      },
      notification: {
        url: 'app://store/category/{{ categoryId }}',
        title: '💁‍♀️ "{{ category }}" 에 관심이 있으신가요?',
        body:
          '{{userName}} 님의 취향저격 아이템들만 모아봤습니다. 지금 확인하세요!',
      },
    },
    {
      id: '5cfb7da1-feb2-4f4f-a1f2-c0cbc24c55f4',
      createdAt: '2022-01-22T15:24:01.727Z',
      updatedAt: '2022-01-23T16:17:34.379Z',
      name: '구매 후기 알림',
      category: {
        id: '965446bf-baf9-4552-8b55-f83b078c5571',
        createdAt: null,
        updatedAt: null,
        name: '즐겨찾기',
        color: '#dac825',
      },
      notification: {
        url: 'app://store/product/{{ productId }}/comment',
        title: '👥  또 다른 구매자 등장',
        body:
          '내가 관심있는 {{ productName || 상품 }}에 새로운 구매 후기가 달렸어요. 한 번 확인해볼까요?',
      },
    },
    {
      id: '5cfb7da1-feb2-4f4f-a1f2-c0cbc24c55f5',
      createdAt: '2022-01-22T17:34:51.727Z',
      updatedAt: '2022-01-22T18:11:24.379Z',
      name: '플래시 세일',
      category: {
        id: '965446bf-baf9-4552-8b55-f83b078c5571',
        createdAt: null,
        updatedAt: null,
        name: '즐겨찾기',
        color: '#dac825',
      },
      notification: {
        url: 'app://store/product/{{ productId }}',
        title: '💸 깜짝 할인 안내',
        body:
          '평소에 관심있던 {{ productName || 상품 }}이 오늘 하루 동안만 할인해요!',
      },
    },
    {
      id: '5cfb7da1-feb2-4f4f-a1f2-c0cbc24c55f6',
      createdAt: '2022-01-23T15:24:11.727Z',
      updatedAt: '2022-01-23T16:11:14.379Z',
      name: '배송 시작',
      category: {
        id: '965446bf-baf9-4552-8b55-f83b078c5571',
        createdAt: null,
        updatedAt: null,
        name: '배송',
        color: '#8c6948',
      },
      notification: {
        url: 'app://store/product/{{ productId }}',
        title: '🚚 배송 시작',
        body: '주문하신 {{ productName || 상품}}이 이제 막 출발했어요!',
      },
    },
    {
      id: '5cfb7da1-feb2-4f4f-a1f2-c0cbc24c55f7',
      createdAt: '2022-01-23T15:24:11.727Z',
      updatedAt: '2022-01-23T16:11:14.379Z',
      name: '구매 후기 작성 유도',
      category: {
        id: '965446bf-baf9-4552-8b55-f83b078c5571',
        createdAt: null,
        updatedAt: null,
        name: '배송',
        color: '#8c6948',
      },
      notification: {
        url: 'app://store/product/{{ productId }}/comment',
        title: '🎁 후기 작성하고 포인트 받기',
        body:
          '구매한 {{ productName || 상품}} 후기를 작성하고 최대 {{ point }}원까지 get!! get!!💰',
      },
    },
    {
      id: '5cfb7da1-feb2-4f4f-a1f2-c0cbc24c55f8',
      createdAt: '2022-01-23T15:24:11.727Z',
      updatedAt: '2022-01-23T16:11:14.379Z',
      name: '앱설치 유도',
      category: {
        id: '965446bf-baf9-4552-8b55-f83b078c5571',
        createdAt: null,
        updatedAt: null,
        name: '유입',
        color: '#826aaa',
      },
      notification: {
        url: 'https://itunes.apple.com/kr/app/podcast/{{ appId }}',
        title: '📱 띵동~ {{ userName }} 님을 위한 꿀 TIP 🍯',
        body:
          '모바일 앱으로 더욱 쾌적한 사용이 가능해요. 앱 전용 할인쿠폰까지 증정! 💸',
      },
    },
    {
      id: '6a3e8e56-7d06-11ed-a1eb-0242ac120002',
      createdAt: '2022-02-02T13:15:11.727Z',
      updatedAt: '2022-02-02T13:15:11.727Z',
      name: '회원가입 유도',
      category: {
        id: '965446bf-baf9-4552-8b55-f83b078c5571',
        createdAt: null,
        updatedAt: null,
        name: '유입',
        color: '#826aaa',
      },
      notification: {
        url: 'app://store/coupon/{{couponCode}}',
        title: '🛍️ 바로 지금만! 가입 시 할인쿠폰 지급',
        body: '최소 금액 없이 즉시 사용할 수 있어요. (안 쓰면 손해 😎)',
      },
    },
    {
      id: 'be60a172-7d06-11ed-a1eb-0242ac120002',
      createdAt: '2022-02-02T14:20:11.727Z',
      updatedAt: '2022-02-02T14:20:11.727Z',
      name: '신규 유저 온보딩 - Step 1',
      category: {
        id: 'ad5336bf-baf9-b5d4-8225-f8b98d8c5571',
        createdAt: null,
        updatedAt: null,
        name: '온보딩',
        color: '#d67631',
      },
      notification: {
        url: 'app://store/events',
        title: '반가워요. 플레어몰입니다. 🥳',
        body: '지금 진행 중인 이벤트🎁가 무엇이 있는지 궁금하지 않으세요?',
      },
    },
    {
      id: '5cacd0a8-7d07-11ed-a1eb-0242ac120002',
      createdAt: '2022-02-02T14:25:11.727Z',
      updatedAt: '2022-02-02T14:25:11.727Z',
      name: '신규 유저 온보딩 - Step 2',
      category: {
        id: 'ad5336bf-baf9-b5d4-8225-f8b98d8c5571',
        createdAt: null,
        updatedAt: null,
        name: '온보딩',
        color: '#d67631',
      },
      notification: {
        url: 'app://store/products/sale',
        title: '바쁜 고객님을 위한 맞춤 알림! 📣',
        body: '🧾 이번 주 할인하는 인기 상품들을 확인해보세요.',
      },
    },
    {
      id: 'bdcce69d-5bdb-471a-b6ca-477b06bc4ff9',
      createdAt: '2022-02-02T14:32:11.727Z',
      updatedAt: '2022-02-02T14:32:11.727Z',
      name: '신규 유저 온보딩 - Step 3',
      category: {
        id: 'ad5336bf-baf9-b5d4-8225-f8b98d8c5571',
        createdAt: null,
        updatedAt: null,
        name: '온보딩',
        color: '#d67631',
      },
      notification: {
        url: 'app://store/products/recommand/ai-now',
        title: '저희 이런 것도 있어요! 📣',
        body: '플레어 AI🤖가 제공하는 초개인화 추천 상품입니다.',
      },
    },
    {
      id: '6ecf701c-e165-4a6e-85a6-abe46e9d316c',
      createdAt: '2022-02-02T14:38:21.727Z',
      updatedAt: '2022-02-02T14:38:21.727Z',
      name: '재방문 유도 자동화 - Step 1',
      category: {
        id: '965446bf-baf9-4552-8b55-f83b078c5571',
        createdAt: null,
        updatedAt: null,
        name: '유입',
        color: '#826aaa',
      },
      notification: {
        url: 'app://store/products/new',
        title: '😭 안 들어오신지 꽤 되셨어요.',
        body: '신상품이 정말 많이 추가되었는데 보러오지 않으실래요? 🙏',
      },
    },
    {
      id: '1afabe89-3887-4ef7-b213-95e219cdaf38',
      createdAt: '2022-02-02T14:42:21.727Z',
      updatedAt: '2022-02-02T14:42:21.727Z',
      name: '재방문 유도 자동화 - Step 2',
      category: {
        id: '965446bf-baf9-4552-8b55-f83b078c5571',
        createdAt: null,
        updatedAt: null,
        name: '유입',
        color: '#826aaa',
      },
      notification: {
        url: 'app://store/coupon/{{couponCode}}',
        title: '🥺 저희를 잊으신 건 아니죠..?',
        body: '돌아오실 날 기다리며 할인쿠폰 하나 발급해드렸답니다. 🎁',
      },
    },
  ],
  en: [
    {
      id: '5cfb7da1-feb2-4f4f-a1f2-c0cbc24c55f6',
      createdAt: '2021-12-04T05:03:24.727Z',
      updatedAt: '2021-12-13T04:46:41.379Z',
      name: 'Payment Successful',
      category: {
        id: '965446bf-baf9-4552-8b55-f83b078c5571',
        createdAt: null,
        updatedAt: null,
        name: 'Payment',
        color: '#67995e',
      },
      notification: {
        url: 'app://store/buy/{{ productId }}',
        title: '🎉 Payment Successful',
        body: '"{{ productName }} will be delivered to your door soon!',
      },
    },
    {
      id: '5cfb7da1-feb2-4f4f-a1f2-c0cbc24c55f1',
      createdAt: '2021-11-21T15:24:01.727Z',
      updatedAt: '2021-12-21T16:17:34.379Z',
      name: 'Promotion Code',
      category: {
        id: '965446bf-baf9-4552-8b55-f83b078c5571',
        createdAt: null,
        updatedAt: null,
        name: 'Payment',
        color: '#67995e',
      },
      notification: {
        url: 'app://store/coupon/{{couponCode}}',
        title: '💌 Get your free coupon',
        body: 'Use coupon code {{ couponCode }} and get {{ couponRate }} off!',
      },
    },
    {
      id: '5cfb7da1-feb2-4f4f-a1f2-c0cbc24c55f2',
      createdAt: '2021-12-04T11:23:56.727Z',
      updatedAt: '2021-12-30T16:11:34.379Z',
      name: 'Cart Recovery',
      category: {
        id: '965446bf-baf9-4552-8b55-f83b078c5571',
        createdAt: null,
        updatedAt: null,
        name: 'Payment',
        color: '#67995e',
      },
      notification: {
        url: 'app://store/cart',
        title: '🛒 Forget Something?',
        body:
          'It seems like you left {{ cartCount }} products in the shopping cart',
      },
    },
    {
      id: '5cfb7da1-feb2-4f4f-a1f2-c0cbc24c55f3',
      createdAt: '2021-12-30T15:37:23.727Z',
      updatedAt: '2022-01-23T11:51:44.379Z',
      name: 'Encourage Purchase',
      category: {
        id: '965446bf-baf9-4552-8b55-f83b078c5571',
        createdAt: null,
        updatedAt: null,
        name: 'Payment',
        color: '#67995e',
      },
      notification: {
        url: 'app://store/category/{{ categoryId }}',
        title: '💁‍♀️ Interested in {{ category }}?',
        body:
          "We've selected {{ userName }}'s favorite items. Check it out now!",
      },
    },
    {
      id: '5cfb7da1-feb2-4f4f-a1f2-c0cbc24c55f4',
      createdAt: '2022-01-22T15:24:01.727Z',
      updatedAt: '2022-01-23T16:17:34.379Z',
      name: 'Review Notifications',
      category: {
        id: '965446bf-baf9-4552-8b55-f83b078c5571',
        createdAt: null,
        updatedAt: null,
        name: 'Bookmark',
        color: '#dac825',
      },
      notification: {
        url: 'app://store/product/{{ productId }}/comment',
        title: '👥 Check out reviews',
        body:
          "There's a review for the {{ productName || product }} that you might be interested in. You should check it out!",
      },
    },
    {
      id: '5cfb7da1-feb2-4f4f-a1f2-c0cbc24c55f5',
      createdAt: '2022-01-22T17:34:51.727Z',
      updatedAt: '2022-01-22T18:11:24.379Z',
      name: 'Flash Sale',
      category: {
        id: '965446bf-baf9-4552-8b55-f83b078c5571',
        createdAt: null,
        updatedAt: null,
        name: 'Bookmark',
        color: '#dac825',
      },
      notification: {
        url: 'app://store/product/{{ productId }}',
        title: '💸 Flash Sale',
        body:
          'The {{ productName || product }} you are interested in is on sale ONLY TODAY!',
      },
    },
    {
      id: '5cfb7da1-feb2-4f4f-a1f2-c0cbc24c55f6',
      createdAt: '2022-01-23T15:24:11.727Z',
      updatedAt: '2022-01-23T16:11:14.379Z',
      name: 'Shipped',
      category: {
        id: '965446bf-baf9-4552-8b55-f83b078c5571',
        createdAt: null,
        updatedAt: null,
        name: 'Shipment',
        color: '#8c6948',
      },
      notification: {
        url: 'app://store/product/{{ productId }}',
        title: '🚚 Shipped',
        body: 'The {{ productName || product}} you ordered has been shipped',
      },
    },
    {
      id: '5cfb7da1-feb2-4f4f-a1f2-c0cbc24c55f7',
      createdAt: '2022-01-23T15:24:11.727Z',
      updatedAt: '2022-01-23T16:11:14.379Z',
      name: 'Encourge to Write Reviews',
      category: {
        id: '965446bf-baf9-4552-8b55-f83b078c5571',
        createdAt: null,
        updatedAt: null,
        name: 'Shipment',
        color: '#8c6948',
      },
      notification: {
        url: 'app://store/product/{{ productId }}/comment',
        title: '🎁 Write a review and get rewards',
        body:
          'Write a review on the {{ productName || product}} and get {{ point }} points💰',
      },
    },
    {
      id: '5cfb7da1-feb2-4f4f-a1f2-c0cbc24c55f8',
      createdAt: '2022-01-23T15:24:11.727Z',
      updatedAt: '2022-01-23T16:11:14.379Z',
      name: 'Encourage App Download',
      category: {
        id: '965446bf-baf9-4552-8b55-f83b078c5571',
        createdAt: null,
        updatedAt: null,
        name: 'Acquisition',
        color: '#826aaa',
      },
      notification: {
        url: 'https://itunes.apple.com/kr/app/podcast/{{ appId }}',
        title: '📱 Knock Knock 🍯',
        body: 'Download mobile app now and get mobile only coupons! 💸',
      },
    },
    {
      id: '6a3e8e56-7d06-11ed-a1eb-0242ac120002',
      createdAt: '2022-02-02T13:15:11.727Z',
      updatedAt: '2022-02-02T13:15:11.727Z',
      name: 'Encourage Sign-ups',
      category: {
        id: '965446bf-baf9-4552-8b55-f83b078c5571',
        createdAt: null,
        updatedAt: null,
        name: 'Acquisition',
        color: '#826aaa',
      },
      notification: {
        url: 'app://store/coupon/{{couponCode}}',
        title: '🛍️ Ready to elevate your experience with our product?',
        body:
          'Sign up now and receive a special welcome offer to get started 😎',
      },
    },
    {
      id: 'be60a172-7d06-11ed-a1eb-0242ac120002',
      createdAt: '2022-02-02T14:20:11.727Z',
      updatedAt: '2022-02-02T14:20:11.727Z',
      name: 'Onboard New Customers_1',
      category: {
        id: 'ad5336bf-baf9-b5d4-8225-f8b98d8c5571',
        createdAt: null,
        updatedAt: null,
        name: 'Onboarding',
        color: '#d67631',
      },
      notification: {
        url: 'app://store/events',
        title: 'Welcome to Flaremall. 🥳',
        body:
          "Check out our beginner's guide to get started with our product today🎁",
      },
    },
    {
      id: '5cacd0a8-7d07-11ed-a1eb-0242ac120002',
      createdAt: '2022-02-02T14:25:11.727Z',
      updatedAt: '2022-02-02T14:25:11.727Z',
      name: 'Onboard New Customers_2',
      category: {
        id: 'ad5336bf-baf9-b5d4-8225-f8b98d8c5571',
        createdAt: null,
        updatedAt: null,
        name: 'Onboarding',
        color: '#d67631',
      },
      notification: {
        url: 'app://store/products/sale',
        title: 'Special news! 📣',
        body: "🧾 Take a look at this week's discounted item",
      },
    },
    {
      id: 'bdcce69d-5bdb-471a-b6ca-477b06bc4ff9',
      createdAt: '2022-02-02T14:32:11.727Z',
      updatedAt: '2022-02-02T14:32:11.727Z',
      name: 'Onboard New Customers_3',
      category: {
        id: 'ad5336bf-baf9-b5d4-8225-f8b98d8c5571',
        createdAt: null,
        updatedAt: null,
        name: 'Onboarding',
        color: '#d67631',
      },
      notification: {
        url: 'app://store/products/recommand/ai-now',
        title: 'Check this out! 📣',
        body: 'FlareMall AI recommended products just for you 🤖',
      },
    },
    {
      id: '6ecf701c-e165-4a6e-85a6-abe46e9d316c',
      createdAt: '2022-02-02T14:38:21.727Z',
      updatedAt: '2022-02-02T14:38:21.727Z',
      name: 'Encourage Revisits_1',
      category: {
        id: '965446bf-baf9-4552-8b55-f83b078c5571',
        createdAt: null,
        updatedAt: null,
        name: 'Retention',
        color: '#826aaa',
      },
      notification: {
        url: 'app://store/products/new',
        title: "😭 We've missed you!",
        body:
          "New products available now. Don't forget to use your exclusive discount code! 💪🏻",
      },
    },
    {
      id: '1afabe89-3887-4ef7-b213-95e219cdaf38',
      createdAt: '2022-02-02T14:42:21.727Z',
      updatedAt: '2022-02-02T14:42:21.727Z',
      name: 'Encourage Revisits_2',
      category: {
        id: '965446bf-baf9-4552-8b55-f83b078c5571',
        createdAt: null,
        updatedAt: null,
        name: 'Retention',
        color: '#826aaa',
      },
      notification: {
        url: 'app://store/coupon/{{couponCode}}',
        title: '🥺 We miss you',
        body: 'A free coupon has been added to your cart 🎁',
      },
    },
  ],
} as any;

export const demoAllDevices = {
  ko: [
    {
      id: 'd411f98d-b22b-49ea-9545-dbc5611f4c7d',
      platform: 'webDesktop',
      isSubscribed: true,
      isTest: false,
      testName: null,
      createdAt: '2022-06-08T10:47:36.189Z',
      userId: null,
      tags: {},
      languageCode: 'ko-KR',
      countryCode: null,
      deviceModel: 'macOS',
      browser: 'Chrome',
      browserVersion: '101.0.4951.64',
      osVersion: '10.15.7',
      sdkVersion: '1.0.0',
      timeZone: 'Asia/Seoul',
      lastActiveAt: '2022-06-08T10:50:16.481Z',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
    },
    {
      id: 'b4188604-ad2b-4431-994a-2d6361a7899f',
      platform: 'webMobile',
      isSubscribed: true,
      isTest: false,
      testName: null,
      createdAt: '2022-06-08T01:02:16.153Z',
      userId: 'A3gdEz6D',
      tags: {},
      languageCode: 'ko-KR',
      countryCode: null,
      deviceModel: 'Android',
      browser: 'Chrome',
      browserVersion: '102.0.0.0',
      osVersion: '12',
      sdkVersion: '1.0.0',
      timeZone: 'Asia/Seoul',
      lastActiveAt: '2022-06-08T09:13:17.209Z',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
    },
    {
      id: 'f3158da3-382a-4a03-9647-bf5866d90ed4',
      platform: 'webDesktop',
      isSubscribed: true,
      isTest: false,
      testName: null,
      createdAt: '2022-06-08T00:36:58.178Z',
      userId: 'E0Bz2g6X',
      tags: {},
      languageCode: 'ko-KR',
      countryCode: null,
      deviceModel: 'Windows',
      browser: 'Chrome',
      browserVersion: '102.0.5005.63',
      osVersion: 'NT 10.0',
      sdkVersion: '1.0.0',
      timeZone: 'Asia/Seoul',
      lastActiveAt: '2022-06-08T05:08:32.814Z',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
    },
    {
      id: 'b416449c-c024-4343-b141-a71d661adcdc',
      platform: 'ios',
      isSubscribed: false,
      isTest: true,
      testName: '김팀장 휴대폰',
      createdAt: '2022-01-13T07:47:22.518Z',
      userId: 'Pz02Mdm0',
      tags: {},
      languageCode: 'ko',
      countryCode: 'KR',
      deviceModel: 'iPhone13,3',
      browser: null,
      browserVersion: null,
      osVersion: '15.1.1',
      sdkVersion: '1.0.0',
      timeZone: 'Asia/Seoul',
      lastActiveAt: '2022-06-08T04:47:22.518Z',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      apsEnvironment: 'development',
    },
    {
      id: '7012e520-e32c-4d2c-bf4f-06fa6cda5921',
      platform: 'webMobile',
      isSubscribed: true,
      isTest: true,
      testName: '박주임 휴대폰',
      createdAt: '2022-06-07T08:29:19.736Z',
      userId: null,
      tags: {},
      languageCode: 'en-US',
      countryCode: null,
      deviceModel: 'Android',
      browser: 'Chrome',
      browserVersion: '102.0.5005.78',
      osVersion: '12',
      sdkVersion: '1.0.0',
      timeZone: 'Asia/Seoul',
      lastActiveAt: '2022-06-09T02:23:23.771Z',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
    },
    {
      id: 'ce12b495-6523-4680-9241-59286624362e',
      platform: 'webDesktop',
      isSubscribed: false,
      isTest: true,
      testName: '이대리 데스크탑',
      createdAt: '2022-06-07T08:16:31.525Z',
      userId: null,
      tags: {},
      languageCode: 'ko-KR',
      countryCode: null,
      deviceModel: 'Windows',
      browser: 'Chrome',
      browserVersion: '102.0.5005.63',
      osVersion: 'NT 10.0',
      sdkVersion: '1.0.0',
      timeZone: 'Asia/Seoul',
      lastActiveAt: '2022-06-08T00:28:06.034Z',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
    },
    {
      id: 'bf12ec37-9224-400f-a54d-34396b181f60',
      platform: 'webDesktop',
      isSubscribed: true,
      isTest: false,
      testName: null,
      createdAt: '2022-06-07T08:07:54.382Z',
      userId: null,
      tags: {},
      languageCode: 'ko-KR',
      countryCode: null,
      deviceModel: 'Windows',
      browser: 'Chrome',
      browserVersion: '102.0.5005.63',
      osVersion: 'NT 10.0',
      sdkVersion: '1.0.0',
      timeZone: 'Asia/Seoul',
      lastActiveAt: '2022-06-07T08:13:41.354Z',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
    },
    {
      id: 'a51d5396-3024-4388-9e42-51b06b462370',
      platform: 'webDesktop',
      isSubscribed: true,
      isTest: true,
      testName: '최사원 맥북',
      createdAt: '2022-06-07T07:52:13.231Z',
      userId: '8B3ouD71',
      tags: {},
      languageCode: 'ko',
      countryCode: null,
      deviceModel: 'macOS',
      browser: 'Chrome',
      browserVersion: '102.0.5005.61',
      osVersion: '10.15.7',
      sdkVersion: '1.0.0',
      timeZone: 'Asia/Seoul',
      lastActiveAt: '2022-06-09T02:22:34.323Z',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
    },
    {
      id: 'f91e2ca7-fb27-4be3-8344-da6d60a7613d',
      platform: 'webDesktop',
      isSubscribed: true,
      isTest: false,
      testName: null,
      createdAt: '2022-06-07T07:21:51.454Z',
      userId: null,
      tags: {},
      languageCode: 'ko-KR',
      countryCode: null,
      deviceModel: 'Windows',
      browser: 'Chrome',
      browserVersion: '102.0.5005.63',
      osVersion: 'NT 10.0',
      sdkVersion: '1.0.0',
      timeZone: 'Asia/Seoul',
      lastActiveAt: '2022-06-07T08:06:40.612Z',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
    },
    {
      id: '501768a7-042d-4ebc-9d4d-51e261c441aa',
      platform: 'webDesktop',
      isSubscribed: true,
      isTest: false,
      testName: null,
      createdAt: '2022-06-07T07:19:24.459Z',
      userId: 'z56fUIz4',
      tags: {},
      languageCode: 'ko-KR',
      countryCode: null,
      deviceModel: 'Windows',
      browser: 'Chrome',
      browserVersion: '102.0.5005.63',
      osVersion: 'NT 10.0',
      sdkVersion: '1.0.0',
      timeZone: 'Asia/Seoul',
      lastActiveAt: '2022-06-07T07:19:24.459Z',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
    },
    {
      id: 'a513b002-752e-44b5-bf46-6d3e68d798ea',
      platform: 'webDesktop',
      isSubscribed: true,
      isTest: false,
      testName: null,
      createdAt: '2022-06-07T07:12:43.837Z',
      userId: null,
      tags: {},
      languageCode: 'ko-KR',
      countryCode: null,
      deviceModel: 'Windows',
      browser: 'Chrome',
      browserVersion: '102.0.5005.63',
      osVersion: 'NT 10.0',
      sdkVersion: '1.0.0',
      timeZone: 'Asia/Seoul',
      lastActiveAt: '2022-06-07T07:12:43.837Z',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
    },
    {
      id: '54163243-7822-4c14-8c4a-6bab6df6d929',
      platform: 'ios',
      isSubscribed: false,
      isTest: false,
      testName: null,
      createdAt: '2021-11-15T08:17:55.722Z',
      userId: null,
      tags: {
        gender: 'men',
      },
      languageCode: 'en',
      countryCode: 'ko',
      deviceModel: 'iPhoneX',
      browser: null,
      browserVersion: null,
      osVersion: '14.0.1',
      sdkVersion: '1.0.0',
      timeZone: 'Asia/Seoul',
      lastActiveAt: '2021-06-07T06:55:55.722Z',
      projectId: '0d359653-f2f0-42c1-8ba6-41d1ede1294f',
    },
    {
      id: 'f81a34fd-fe26-4f87-bb4d-53746ae1b9dc',
      platform: 'webDesktop',
      isSubscribed: true,
      isTest: false,
      testName: null,
      createdAt: '2022-06-07T06:48:39.250Z',
      userId: null,
      tags: {},
      languageCode: 'ko-KR',
      countryCode: null,
      deviceModel: 'Windows',
      browser: 'Chrome',
      browserVersion: '102.0.5005.63',
      osVersion: 'NT 10.0',
      sdkVersion: '1.0.0',
      timeZone: 'Asia/Seoul',
      lastActiveAt: '2022-06-07T06:49:33.746Z',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
    },
    {
      id: 'df1906fd-b928-4b7d-ae4b-97bd62ba248d',
      platform: 'android',
      isSubscribed: false,
      isTest: true,
      testName: '넥서스5 기기',
      createdAt: '2021-10-28T06:53:35.894Z',
      userId: 'UB1cs0cg',
      tags: {},
      languageCode: 'ko',
      countryCode: 'KR',
      deviceModel: 'Nexus 5',
      browser: null,
      browserVersion: null,
      osVersion: '6.0.1',
      sdkVersion: '1.0.0',
      timeZone: 'Asia/Seoul',
      lastActiveAt: '2022-06-07T06:48:46.763Z',
      projectId: '0d359653-f2f0-42c1-8ba6-41d1ede1294f',
    },
  ],
  en: [
    {
      id: 'd411f98d-b22b-49ea-9545-dbc5611f4c7d',
      platform: 'webDesktop',
      isSubscribed: true,
      isTest: false,
      testName: null,
      createdAt: '2022-06-08T10:47:36.189Z',
      userId: null,
      tags: {},
      languageCode: 'ko-KR',
      countryCode: null,
      deviceModel: 'macOS',
      browser: 'Chrome',
      browserVersion: '101.0.4951.64',
      osVersion: '10.15.7',
      sdkVersion: '1.0.0',
      timeZone: 'Asia/Seoul',
      lastActiveAt: '2022-06-08T10:50:16.481Z',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
    },
    {
      id: 'b4188604-ad2b-4431-994a-2d6361a7899f',
      platform: 'webMobile',
      isSubscribed: true,
      isTest: false,
      testName: null,
      createdAt: '2022-06-08T01:02:16.153Z',
      userId: 'A3gdEz6D',
      tags: {},
      languageCode: 'ko-KR',
      countryCode: null,
      deviceModel: 'Android',
      browser: 'Chrome',
      browserVersion: '102.0.0.0',
      osVersion: '12',
      sdkVersion: '1.0.0',
      timeZone: 'Asia/Seoul',
      lastActiveAt: '2022-06-08T09:13:17.209Z',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
    },
    {
      id: 'f3158da3-382a-4a03-9647-bf5866d90ed4',
      platform: 'webDesktop',
      isSubscribed: true,
      isTest: false,
      testName: null,
      createdAt: '2022-06-08T00:36:58.178Z',
      userId: 'E0Bz2g6X',
      tags: {},
      languageCode: 'ko-KR',
      countryCode: null,
      deviceModel: 'Windows',
      browser: 'Chrome',
      browserVersion: '102.0.5005.63',
      osVersion: 'NT 10.0',
      sdkVersion: '1.0.0',
      timeZone: 'Asia/Seoul',
      lastActiveAt: '2022-06-08T05:08:32.814Z',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
    },
    {
      id: 'b416449c-c024-4343-b141-a71d661adcdc',
      platform: 'ios',
      isSubscribed: false,
      isTest: true,
      testName: "Michael's cellphone",
      createdAt: '2022-01-13T07:47:22.518Z',
      userId: 'Pz02Mdm0',
      tags: {},
      languageCode: 'ko',
      countryCode: 'KR',
      deviceModel: 'iPhone13,3',
      browser: null,
      browserVersion: null,
      osVersion: '15.1.1',
      sdkVersion: '1.0.0',
      timeZone: 'Asia/Seoul',
      lastActiveAt: '2022-06-08T04:47:22.518Z',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      apsEnvironment: 'development',
    },
    {
      id: '7012e520-e32c-4d2c-bf4f-06fa6cda5921',
      platform: 'webMobile',
      isSubscribed: true,
      isTest: true,
      testName: "Oliver's cellphone",
      createdAt: '2022-06-07T08:29:19.736Z',
      userId: null,
      tags: {},
      languageCode: 'en-US',
      countryCode: null,
      deviceModel: 'Android',
      browser: 'Chrome',
      browserVersion: '102.0.5005.78',
      osVersion: '12',
      sdkVersion: '1.0.0',
      timeZone: 'Asia/Seoul',
      lastActiveAt: '2022-06-09T02:23:23.771Z',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
    },
    {
      id: 'ce12b495-6523-4680-9241-59286624362e',
      platform: 'webDesktop',
      isSubscribed: false,
      isTest: true,
      testName: "Monica's desktop",
      createdAt: '2022-06-07T08:16:31.525Z',
      userId: null,
      tags: {},
      languageCode: 'ko-KR',
      countryCode: null,
      deviceModel: 'Windows',
      browser: 'Chrome',
      browserVersion: '102.0.5005.63',
      osVersion: 'NT 10.0',
      sdkVersion: '1.0.0',
      timeZone: 'Asia/Seoul',
      lastActiveAt: '2022-06-08T00:28:06.034Z',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
    },
    {
      id: 'bf12ec37-9224-400f-a54d-34396b181f60',
      platform: 'webDesktop',
      isSubscribed: true,
      isTest: false,
      testName: null,
      createdAt: '2022-06-07T08:07:54.382Z',
      userId: null,
      tags: {},
      languageCode: 'ko-KR',
      countryCode: null,
      deviceModel: 'Windows',
      browser: 'Chrome',
      browserVersion: '102.0.5005.63',
      osVersion: 'NT 10.0',
      sdkVersion: '1.0.0',
      timeZone: 'Asia/Seoul',
      lastActiveAt: '2022-06-07T08:13:41.354Z',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
    },
    {
      id: 'a51d5396-3024-4388-9e42-51b06b462370',
      platform: 'webDesktop',
      isSubscribed: true,
      isTest: true,
      testName: "John's MacBook",
      createdAt: '2022-06-07T07:52:13.231Z',
      userId: '8B3ouD71',
      tags: {},
      languageCode: 'ko',
      countryCode: null,
      deviceModel: 'macOS',
      browser: 'Chrome',
      browserVersion: '102.0.5005.61',
      osVersion: '10.15.7',
      sdkVersion: '1.0.0',
      timeZone: 'Asia/Seoul',
      lastActiveAt: '2022-06-09T02:22:34.323Z',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
    },
    {
      id: 'f91e2ca7-fb27-4be3-8344-da6d60a7613d',
      platform: 'webDesktop',
      isSubscribed: true,
      isTest: false,
      testName: null,
      createdAt: '2022-06-07T07:21:51.454Z',
      userId: null,
      tags: {},
      languageCode: 'ko-KR',
      countryCode: null,
      deviceModel: 'Windows',
      browser: 'Chrome',
      browserVersion: '102.0.5005.63',
      osVersion: 'NT 10.0',
      sdkVersion: '1.0.0',
      timeZone: 'Asia/Seoul',
      lastActiveAt: '2022-06-07T08:06:40.612Z',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
    },
    {
      id: '501768a7-042d-4ebc-9d4d-51e261c441aa',
      platform: 'webDesktop',
      isSubscribed: true,
      isTest: false,
      testName: null,
      createdAt: '2022-06-07T07:19:24.459Z',
      userId: 'z56fUIz4',
      tags: {},
      languageCode: 'ko-KR',
      countryCode: null,
      deviceModel: 'Windows',
      browser: 'Chrome',
      browserVersion: '102.0.5005.63',
      osVersion: 'NT 10.0',
      sdkVersion: '1.0.0',
      timeZone: 'Asia/Seoul',
      lastActiveAt: '2022-06-07T07:19:24.459Z',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
    },
    {
      id: 'a513b002-752e-44b5-bf46-6d3e68d798ea',
      platform: 'webDesktop',
      isSubscribed: true,
      isTest: false,
      testName: null,
      createdAt: '2022-06-07T07:12:43.837Z',
      userId: null,
      tags: {},
      languageCode: 'ko-KR',
      countryCode: null,
      deviceModel: 'Windows',
      browser: 'Chrome',
      browserVersion: '102.0.5005.63',
      osVersion: 'NT 10.0',
      sdkVersion: '1.0.0',
      timeZone: 'Asia/Seoul',
      lastActiveAt: '2022-06-07T07:12:43.837Z',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
    },
    {
      id: '54163243-7822-4c14-8c4a-6bab6df6d929',
      platform: 'ios',
      isSubscribed: false,
      isTest: false,
      testName: null,
      createdAt: '2021-11-15T08:17:55.722Z',
      userId: null,
      tags: {
        gender: 'men',
      },
      languageCode: 'en',
      countryCode: 'ko',
      deviceModel: 'iPhoneX',
      browser: null,
      browserVersion: null,
      osVersion: '14.0.1',
      sdkVersion: '1.0.0',
      timeZone: 'Asia/Seoul',
      lastActiveAt: '2021-06-07T06:55:55.722Z',
      projectId: '0d359653-f2f0-42c1-8ba6-41d1ede1294f',
    },
    {
      id: 'f81a34fd-fe26-4f87-bb4d-53746ae1b9dc',
      platform: 'webDesktop',
      isSubscribed: true,
      isTest: false,
      testName: null,
      createdAt: '2022-06-07T06:48:39.250Z',
      userId: null,
      tags: {},
      languageCode: 'ko-KR',
      countryCode: null,
      deviceModel: 'Windows',
      browser: 'Chrome',
      browserVersion: '102.0.5005.63',
      osVersion: 'NT 10.0',
      sdkVersion: '1.0.0',
      timeZone: 'Asia/Seoul',
      lastActiveAt: '2022-06-07T06:49:33.746Z',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
    },
    {
      id: 'df1906fd-b928-4b7d-ae4b-97bd62ba248d',
      platform: 'android',
      isSubscribed: false,
      isTest: true,
      testName: 'Nexus5',
      createdAt: '2021-10-28T06:53:35.894Z',
      userId: 'UB1cs0cg',
      tags: {},
      languageCode: 'ko',
      countryCode: 'KR',
      deviceModel: 'Nexus 5',
      browser: null,
      browserVersion: null,
      osVersion: '6.0.1',
      sdkVersion: '1.0.0',
      timeZone: 'Asia/Seoul',
      lastActiveAt: '2022-06-07T06:48:46.763Z',
      projectId: '0d359653-f2f0-42c1-8ba6-41d1ede1294f',
    },
  ],
} as any;

export const demoTestDevices = {
  ko: demoAllDevices.ko.filter((d) => d.isTest),
  en: demoAllDevices.en.filter((d) => d.isTest),
} as any;

export const demoConfigs = {
  ko: {
    categoryColors: [
      {
        name: 'Default',
        color: '#777777',
      },
      {
        name: 'Gray',
        color: '#b0b0b0',
      },
      {
        name: 'Brown',
        color: '#8c6948',
      },
      {
        name: 'Orange',
        color: '#d67631',
      },
      {
        name: 'Yellow',
        color: '#dac825',
      },
      {
        name: 'Green',
        color: '#67995e',
      },
      {
        name: 'Blue',
        color: '#5784ba',
      },
      {
        name: 'Purple',
        color: '#826aaa',
      },
      {
        name: 'Pink',
        color: '#c5639e',
      },
      {
        name: 'Red',
        color: '#bd4848',
      },
    ],
    segmentFilters: [
      {
        type: 'PLATFORM',
        validValues: [['webDesktop', 'webMobile', 'android', 'ios']],
      },
      {
        type: 'USER_ID',
        validValues: [['EQ', 'EXIST', 'NOT_EXIST'], 'STRING'],
      },
      {
        type: 'IS_TEST',
        validValues: [['true', 'false']],
      },
      {
        type: 'TAG',
        validValues: [
          'STRING',
          [
            'EQ',
            'NOT_EQ',
            'GTE',
            'LTE',
            'GT',
            'LT',
            'EXIST',
            'NOT_EXIST',
            'AFTER_HOURS',
            'REMAIN_HOURS',
          ],
          'STRING',
        ],
      },
      {
        type: 'CREATED_AT_WITHIN_HOURS',
        validValues: [['NOT_EQ', 'EQ'], 'STRING'],
      },
      {
        type: 'LAST_ACTIVE_AT_WITHIN_HOURS',
        validValues: [['NOT_EQ', 'EQ'], 'STRING'],
      },
      {
        type: 'OS_VERSION',
        validValues: [['EQ', 'NOT_EQ', 'GTE', 'LTE', 'GT', 'LT'], 'STRING'],
      },
      {
        type: 'APP_VERSION',
        validValues: [['EQ', 'NOT_EQ', 'GTE', 'LTE', 'GT', 'LT'], 'STRING'],
      },
      {
        type: 'SDK_VERSION',
        validValues: [['EQ', 'NOT_EQ', 'GTE', 'LTE', 'GT', 'LT'], 'STRING'],
      },
      {
        type: 'COUNTRY_CODE',
        validValues: [['EQ', 'NOT_EQ'], 'STRING'],
      },
    ],
    countryCodes: [
      {
        code: 'US',
        en_name: 'United States',
        kr_name: '미국',
      },
      {
        code: 'CN',
        en_name: 'China',
        kr_name: '중국',
      },
      {
        code: 'JP',
        en_name: 'Japan',
        kr_name: '일본',
      },
      {
        code: 'DE',
        en_name: 'Germany',
        kr_name: '독일',
      },
      {
        code: 'BR',
        en_name: 'Brazil',
        kr_name: '브라질',
      },
      {
        code: 'FR',
        en_name: 'France',
        kr_name: '프랑스',
      },
      {
        code: 'IT',
        en_name: 'Italy',
        kr_name: '이탈리아',
      },
      {
        code: 'RU',
        en_name: 'Russia',
        kr_name: '러시아',
      },
      {
        code: 'GB',
        en_name: 'United Kingdom',
        kr_name: '영국',
      },
      {
        code: 'AU',
        en_name: 'Australia',
        kr_name: '오스트레일리아',
      },
      {
        code: 'CA',
        en_name: 'Canada',
        kr_name: '캐나다',
      },
      {
        code: 'IN',
        en_name: 'India',
        kr_name: '인도',
      },
      {
        code: 'MX',
        en_name: 'Mexico',
        kr_name: '멕시코',
      },
      {
        code: 'KR',
        en_name: 'South Korea',
        kr_name: '대한민국',
      },
      {
        code: 'ES',
        en_name: 'Spain',
        kr_name: '스페인',
      },
      {
        code: 'ID',
        en_name: 'Indonesia',
        kr_name: '인도네시아',
      },
      {
        code: 'TR',
        en_name: 'Turkey',
        kr_name: '터키',
      },
      {
        code: 'NL',
        en_name: 'Netherlands',
        kr_name: '네덜란드',
      },
      {
        code: 'SA',
        en_name: 'Saudi Arabia',
        kr_name: '사우디아라비아',
      },
      {
        code: 'CH',
        en_name: 'Switzerland',
        kr_name: '스위스',
      },
      {
        code: 'SE',
        en_name: 'Sweden',
        kr_name: '스웨덴',
      },
      {
        code: 'NO',
        en_name: 'Norway',
        kr_name: '노르웨이',
      },
      {
        code: 'PL',
        en_name: 'Poland',
        kr_name: '폴란드',
      },
      {
        code: 'BE',
        en_name: 'Belgium',
        kr_name: '벨기에',
      },
      {
        code: 'NG',
        en_name: 'Nigeria',
        kr_name: '나이지리아',
      },
      {
        code: 'AR',
        en_name: 'Argentina',
        kr_name: '아르헨티나',
      },
      {
        code: 'TW',
        en_name: 'Taiwan',
        kr_name: '대만',
      },
      {
        code: 'AT',
        en_name: 'Austria',
        kr_name: '오스트리아',
      },
      {
        code: 'IR',
        en_name: 'Iran',
        kr_name: '이란',
      },
      {
        code: 'TH',
        en_name: 'Thailand',
        kr_name: '태국',
      },
      {
        code: 'AE',
        en_name: 'United Arab Emirates',
        kr_name: '아랍에미리트',
      },
      {
        code: 'CO',
        en_name: 'Colombia',
        kr_name: '콜롬비아',
      },
      {
        code: 'VE',
        en_name: 'Venezuela',
        kr_name: '베네수엘라',
      },
      {
        code: 'ZA',
        en_name: 'South Africa',
        kr_name: '남아프리카',
      },
      {
        code: 'DK',
        en_name: 'Denmark',
        kr_name: '덴마크',
      },
      {
        code: 'MY',
        en_name: 'Malaysia',
        kr_name: '말레이시아',
      },
      {
        code: 'SG',
        en_name: 'Singapore',
        kr_name: '싱가포르',
      },
      {
        code: 'CL',
        en_name: 'Chile',
        kr_name: '칠레',
      },
      {
        code: 'HK',
        en_name: 'Hong Kong',
        kr_name: '홍콩',
      },
      {
        code: 'IL',
        en_name: 'Israel',
        kr_name: '이스라엘',
      },
      {
        code: 'PH',
        en_name: 'Philippines',
        kr_name: '필리핀',
      },
      {
        code: 'EG',
        en_name: 'Egypt',
        kr_name: '이집트',
      },
      {
        code: 'FI',
        en_name: 'Finland',
        kr_name: '핀란드',
      },
      {
        code: 'GR',
        en_name: 'Greece',
        kr_name: '그리스',
      },
      {
        code: 'PK',
        en_name: 'Pakistan',
        kr_name: '파키스탄',
      },
      {
        code: 'KZ',
        en_name: 'Kazakhstan',
        kr_name: '카자흐스탄',
      },
      {
        code: 'IQ',
        en_name: 'Iraq',
        kr_name: '이라크',
      },
      {
        code: 'IE',
        en_name: 'Ireland',
        kr_name: '아일랜드',
      },
      {
        code: 'PT',
        en_name: 'Portugal',
        kr_name: '포르투갈',
      },
      {
        code: 'DZ',
        en_name: 'Algeria',
        kr_name: '알제리',
      },
    ],
  },
  en: {
    categoryColors: [
      {
        name: 'Default',
        color: '#777777',
      },
      {
        name: 'Gray',
        color: '#b0b0b0',
      },
      {
        name: 'Brown',
        color: '#8c6948',
      },
      {
        name: 'Orange',
        color: '#d67631',
      },
      {
        name: 'Yellow',
        color: '#dac825',
      },
      {
        name: 'Green',
        color: '#67995e',
      },
      {
        name: 'Blue',
        color: '#5784ba',
      },
      {
        name: 'Purple',
        color: '#826aaa',
      },
      {
        name: 'Pink',
        color: '#c5639e',
      },
      {
        name: 'Red',
        color: '#bd4848',
      },
    ],
    segmentFilters: [
      {
        type: 'PLATFORM',
        validValues: [['webDesktop', 'webMobile', 'android', 'ios']],
      },
      {
        type: 'USER_ID',
        validValues: [['EQ', 'EXIST', 'NOT_EXIST'], 'STRING'],
      },
      {
        type: 'IS_TEST',
        validValues: [['true', 'false']],
      },
      {
        type: 'TAG',
        validValues: [
          'STRING',
          [
            'EQ',
            'NOT_EQ',
            'GTE',
            'LTE',
            'GT',
            'LT',
            'EXIST',
            'NOT_EXIST',
            'AFTER_HOURS',
            'REMAIN_HOURS',
          ],
          'STRING',
        ],
      },
      {
        type: 'CREATED_AT_WITHIN_HOURS',
        validValues: [['NOT_EQ', 'EQ'], 'STRING'],
      },
      {
        type: 'LAST_ACTIVE_AT_WITHIN_HOURS',
        validValues: [['NOT_EQ', 'EQ'], 'STRING'],
      },
      {
        type: 'OS_VERSION',
        validValues: [['EQ', 'NOT_EQ', 'GTE', 'LTE', 'GT', 'LT'], 'STRING'],
      },
      {
        type: 'APP_VERSION',
        validValues: [['EQ', 'NOT_EQ', 'GTE', 'LTE', 'GT', 'LT'], 'STRING'],
      },
      {
        type: 'SDK_VERSION',
        validValues: [['EQ', 'NOT_EQ', 'GTE', 'LTE', 'GT', 'LT'], 'STRING'],
      },
      {
        type: 'COUNTRY_CODE',
        validValues: [['EQ', 'NOT_EQ'], 'STRING'],
      },
    ],
    countryCodes: [
      {
        code: 'US',
        en_name: 'United States',
        kr_name: '미국',
      },
      {
        code: 'CN',
        en_name: 'China',
        kr_name: '중국',
      },
      {
        code: 'JP',
        en_name: 'Japan',
        kr_name: '일본',
      },
      {
        code: 'DE',
        en_name: 'Germany',
        kr_name: '독일',
      },
      {
        code: 'BR',
        en_name: 'Brazil',
        kr_name: '브라질',
      },
      {
        code: 'FR',
        en_name: 'France',
        kr_name: '프랑스',
      },
      {
        code: 'IT',
        en_name: 'Italy',
        kr_name: '이탈리아',
      },
      {
        code: 'RU',
        en_name: 'Russia',
        kr_name: '러시아',
      },
      {
        code: 'GB',
        en_name: 'United Kingdom',
        kr_name: '영국',
      },
      {
        code: 'AU',
        en_name: 'Australia',
        kr_name: '오스트레일리아',
      },
      {
        code: 'CA',
        en_name: 'Canada',
        kr_name: '캐나다',
      },
      {
        code: 'IN',
        en_name: 'India',
        kr_name: '인도',
      },
      {
        code: 'MX',
        en_name: 'Mexico',
        kr_name: '멕시코',
      },
      {
        code: 'KR',
        en_name: 'South Korea',
        kr_name: '대한민국',
      },
      {
        code: 'ES',
        en_name: 'Spain',
        kr_name: '스페인',
      },
      {
        code: 'ID',
        en_name: 'Indonesia',
        kr_name: '인도네시아',
      },
      {
        code: 'TR',
        en_name: 'Turkey',
        kr_name: '터키',
      },
      {
        code: 'NL',
        en_name: 'Netherlands',
        kr_name: '네덜란드',
      },
      {
        code: 'SA',
        en_name: 'Saudi Arabia',
        kr_name: '사우디아라비아',
      },
      {
        code: 'CH',
        en_name: 'Switzerland',
        kr_name: '스위스',
      },
      {
        code: 'SE',
        en_name: 'Sweden',
        kr_name: '스웨덴',
      },
      {
        code: 'NO',
        en_name: 'Norway',
        kr_name: '노르웨이',
      },
      {
        code: 'PL',
        en_name: 'Poland',
        kr_name: '폴란드',
      },
      {
        code: 'BE',
        en_name: 'Belgium',
        kr_name: '벨기에',
      },
      {
        code: 'NG',
        en_name: 'Nigeria',
        kr_name: '나이지리아',
      },
      {
        code: 'AR',
        en_name: 'Argentina',
        kr_name: '아르헨티나',
      },
      {
        code: 'TW',
        en_name: 'Taiwan',
        kr_name: '대만',
      },
      {
        code: 'AT',
        en_name: 'Austria',
        kr_name: '오스트리아',
      },
      {
        code: 'IR',
        en_name: 'Iran',
        kr_name: '이란',
      },
      {
        code: 'TH',
        en_name: 'Thailand',
        kr_name: '태국',
      },
      {
        code: 'AE',
        en_name: 'United Arab Emirates',
        kr_name: '아랍에미리트',
      },
      {
        code: 'CO',
        en_name: 'Colombia',
        kr_name: '콜롬비아',
      },
      {
        code: 'VE',
        en_name: 'Venezuela',
        kr_name: '베네수엘라',
      },
      {
        code: 'ZA',
        en_name: 'South Africa',
        kr_name: '남아프리카',
      },
      {
        code: 'DK',
        en_name: 'Denmark',
        kr_name: '덴마크',
      },
      {
        code: 'MY',
        en_name: 'Malaysia',
        kr_name: '말레이시아',
      },
      {
        code: 'SG',
        en_name: 'Singapore',
        kr_name: '싱가포르',
      },
      {
        code: 'CL',
        en_name: 'Chile',
        kr_name: '칠레',
      },
      {
        code: 'HK',
        en_name: 'Hong Kong',
        kr_name: '홍콩',
      },
      {
        code: 'IL',
        en_name: 'Israel',
        kr_name: '이스라엘',
      },
      {
        code: 'PH',
        en_name: 'Philippines',
        kr_name: '필리핀',
      },
      {
        code: 'EG',
        en_name: 'Egypt',
        kr_name: '이집트',
      },
      {
        code: 'FI',
        en_name: 'Finland',
        kr_name: '핀란드',
      },
      {
        code: 'GR',
        en_name: 'Greece',
        kr_name: '그리스',
      },
      {
        code: 'PK',
        en_name: 'Pakistan',
        kr_name: '파키스탄',
      },
      {
        code: 'KZ',
        en_name: 'Kazakhstan',
        kr_name: '카자흐스탄',
      },
      {
        code: 'IQ',
        en_name: 'Iraq',
        kr_name: '이라크',
      },
      {
        code: 'IE',
        en_name: 'Ireland',
        kr_name: '아일랜드',
      },
      {
        code: 'PT',
        en_name: 'Portugal',
        kr_name: '포르투갈',
      },
      {
        code: 'DZ',
        en_name: 'Algeria',
        kr_name: '알제리',
      },
    ],
  },
};

export const demoConsoleMessageRank = {
  ko: [
    {
      title: '🏖 여름휴가 체크리스트 ✅',
      body: '뜨거운 여름 회사, 학교를 벗어나 휴가를 떠날 당신에게 필요한 것은?',
      average: 0.381,
      total: 678221,
    },
    {
      title: '👫 개성 다양한 MBTI별 추천 코디',
      body:
        '다양한 MBTI, 개성 넘치는 개별 추천코디! 내 MBTI에 어울리는 코디는?',
      average: 0.309,
      total: 851211,
    },
    {
      title: '💓 가장 특별한 사람을 위한 선물',
      body: '고생한 나에게 어떤 발렌타인 선물을 줘볼까!',
      average: 0.285,
      total: 199873,
    },
  ],
  en: [
    {
      title: '🏖 Summer Vacation Checklist ✅',
      body: '7 Summer Vacation Must-Haves to Pack on Your Next Getaway',
      average: 0.381,
      total: 678221,
    },
    {
      title: '👫 Discover your personal style',
      body:
        'Here are some of the best styling recommendations and unique tips!',
      average: 0.309,
      total: 851211,
    },
    {
      title: '💓 A special gift for myself',
      body: "35 Valentine's day gifts to buy yourself",
      average: 0.285,
      total: 199873,
    },
  ],
} as any;

export const demoAPiMessageRank = {
  ko: [
    {
      title: '👥  또 다른 구매자 등장',
      body:
        '내가 관심있는 {{ productName || 상품 }} 에 새로운 구매 후기가 달렸어요. 한 번 확인해볼까요?',
      average: 0.482,
      total: 701032,
    },
    {
      title: '🚚 배송 시작',
      body: '주문하신 {{ productName || 상품}}이 이제 막 출발했어요!',
      average: 0.429,
      total: 551009,
    },
    {
      title: '💌 할인 쿠폰 도착',
      body:
        '지금 쿠폰코드 {{ couponCode }} 입력 시 {{ couponRate }} 할인 쿠폰 증정!',
      average: 0.401,
      total: 109516,
    },
    {
      title: '💸 깜짝 할인 안내',
      body:
        '평소에 관심있던 {{ productName || 상품 }}이 오늘 하루 동안만 할인해요!',
      average: 0.375,
      total: 218152,
    },
    {
      title: '📱 띵동~ {{ userName }} 님을 위한 꿀 TIP 🍯',
      body:
        '모바일 앱으로 더욱 쾌적한 사용이 가능해요. 앱 전용 할인쿠폰까지 증정! 💸',
      average: 0.318,
      total: 1855421,
    },
  ],
  en: [
    {
      title: '👥 Check out reviews',
      body:
        "There's a review for the {{ productName || product }} that you might be interested in. You should check it out!",
      average: 0.482,
      total: 701032,
    },
    {
      title: '🚚 Shipped',
      body: 'The {{ productName || product}} you ordered has been shipped',
      average: 0.429,
      total: 551009,
    },
    {
      title: '💌 Promo code just for you!',
      body: 'Use coupon code {{ couponCode }} and get {{ couponRate }} off!',
      average: 0.401,
      total: 109516,
    },
    {
      title: '💸 Flash Sale',
      body:
        'The roductName || product }} you are interested in is on sale ONLY TODAY!',
      average: 0.375,
      total: 218152,
    },
    {
      title: '📱 Knock Knock 🍯',
      body: 'Download mobile app now and get mobile only coupons! 💸',
      average: 0.318,
      total: 1855421,
    },
  ],
} as any;

export const demoMember: { ko: Member[]; en: Member[] } = {
  ko: [
    {
      id: '36949f2a-985e-4bc6-8236-0e6d4062cf8f',
      email: 'mk.kim@flaremall.com',
      createdAt: '2021-11-14T01:21:09.829Z',
      admin: true,
    },
    {
      id: '867b75b5-1046-4ce3-8744-23965a935e2e',
      email: 'jy.choi@flaremall.com',
      createdAt: '2021-11-14T04:21:09.829Z',
      admin: true,
    },
    {
      id: '70a075bd-e643-4e34-9cc5-8c54ededbea9',
      email: 'di.lee@flaremall.com',
      createdAt: '2021-11-22T01:12:45.829Z',
      admin: false,
    },
    {
      id: 'ee578836-14b9-48f8-bf9a-576b87f91850',
      email: 'jw.jung@flarelane.com',
      createdAt: '2021-11-22T01:16:04.829Z',
      admin: false,
    },
  ],
  en: [
    {
      id: '36949f2a-985e-4bc6-8236-0e6d4062cf8f',
      email: 'mk.kim@flaremall.com',
      createdAt: '2021-11-14T01:21:09.829Z',
      admin: true,
    },
    {
      id: '867b75b5-1046-4ce3-8744-23965a935e2e',
      email: 'jy.choi@flaremall.com',
      createdAt: '2021-11-14T04:21:09.829Z',
      admin: true,
    },
    {
      id: '70a075bd-e643-4e34-9cc5-8c54ededbea9',
      email: 'di.lee@flaremall.com',
      createdAt: '2021-11-22T01:12:45.829Z',
      admin: false,
    },
    {
      id: 'ee578836-14b9-48f8-bf9a-576b87f91850',
      email: 'jw.jung@flarelane.com',
      createdAt: '2021-11-22T01:16:04.829Z',
      admin: false,
    },
  ],
};

export const demoAutomatedMessages = {
  ko: [
    {
      id: '0c52b41d-4643-403f-bab9-c2abb223a326',
      active: true,
      name: '신규 유저 온보딩 - Step 1',
      dndStartHour: 21,
      dndEndHour: 8,
      resendIntervalMinutes: null,
      segment: {
        id: '7397bc7c-7d04-11ed-a1eb-0242ac120002',
        createdAt: '2022-06-24T01:21:22.748Z',
        updatedAt: '2022-06-24T01:21:22.748Z',
        type: null,
        name: '생성 3시간 경과',
        filters: [
          [
            {
              type: 'CREATED_AT_WITHIN_HOURS',
              values: ['NOT_EQ', '3'],
            },
          ],
        ],
        projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
        c: 142,
      },
      template: {
        id: 'be60a172-7d06-11ed-a1eb-0242ac120002',
        createdAt: '2022-02-02T14:20:11.727Z',
        updatedAt: '2022-02-02T14:20:11.727Z',
        name: '신규 유저 온보딩 - Step 1',
        category: {
          id: 'ad5336bf-baf9-b5d4-8225-f8b98d8c5571',
          createdAt: null,
          updatedAt: null,
          name: '온보딩',
          color: '#d67631',
        },
        notification: {
          url: 'app://store/events',
          title: '반가워요. 플레어몰입니다. 🥳',
          body: '지금 진행 중인 이벤트🎁가 무엇이 있는지 궁금하지 않으세요?',
        },
      },
      sentDevices: 36211,
      convertedRate: 0.324,
    },
    {
      id: '1796b1b6-4547-49b3-9111-b97c0453f9bf',
      active: true,
      name: '신규 유저 온보딩 - Step 2',
      dndStartHour: 21,
      dndEndHour: 8,
      resendIntervalMinutes: null,
      segment: {
        id: '6cbd7414-7d04-11ed-a1eb-0242ac120002',
        createdAt: '2022-06-24T01:21:32.748Z',
        updatedAt: '2022-06-24T01:21:32.748Z',
        type: null,
        name: '생성 1일 경과',
        filters: [
          [
            {
              type: 'CREATED_AT_WITHIN_HOURS',
              values: ['NOT_EQ', '24'],
            },
          ],
        ],
        projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
        c: 1212,
      },
      template: {
        id: '5cacd0a8-7d07-11ed-a1eb-0242ac120002',
        createdAt: '2022-02-02T14:25:11.727Z',
        updatedAt: '2022-02-02T14:25:11.727Z',
        name: '신규 유저 온보딩 - Step 2',
        category: {
          id: 'ad5336bf-baf9-b5d4-8225-f8b98d8c5571',
          createdAt: null,
          updatedAt: null,
          name: '온보딩',
          color: '#d67631',
        },
        notification: {
          url: 'app://store/products/sale',
          title: '바쁜 고객님을 위한 맞춤 알림! 📣',
          body: '🧾 이번 주 할인하는 인기 상품들을 확인해보세요.',
        },
      },
      sentDevices: 27298,
      convertedRate: 0.411,
    },
    {
      id: '037871d2-bcd2-464e-9041-8752d3aaf367',
      active: true,
      name: '신규 유저 온보딩 - Step 3',
      dndStartHour: 21,
      dndEndHour: 8,
      resendIntervalMinutes: null,
      segment: {
        id: '661edcd8-7d04-11ed-a1eb-0242ac120002',
        createdAt: '2022-06-24T01:21:42.748Z',
        updatedAt: '2022-06-24T01:21:42.748Z',
        type: null,
        name: '생성 2일 경과',
        filters: [
          [
            {
              type: 'CREATED_AT_WITHIN_HOURS',
              values: ['NOT_EQ', '48'],
            },
          ],
        ],
        projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
        c: 2188,
      },
      template: {
        id: 'bdcce69d-5bdb-471a-b6ca-477b06bc4ff9',
        createdAt: '2022-02-02T14:32:11.727Z',
        updatedAt: '2022-02-02T14:32:11.727Z',
        name: '신규 유저 온보딩 - Step 3',
        category: {
          id: 'ad5336bf-baf9-b5d4-8225-f8b98d8c5571',
          createdAt: null,
          updatedAt: null,
          name: '온보딩',
          color: '#d67631',
        },
        notification: {
          url: 'app://store/products/recommand/ai-now',
          title: '저희 이런 것도 있어요! 📣',
          body: '플레어 AI🤖가 제공하는 초개인화 추천 상품입니다.',
        },
      },
      sentDevices: 41052,
      convertedRate: 0.398,
    },
    {
      id: '0b1192e9-feab-4437-8335-87ad64c4904c',
      active: true,
      name: '재방문 유도 자동화 - Step 1',
      dndStartHour: 21,
      dndEndHour: 8,
      resendIntervalMinutes: null,
      segment: {
        id: '5d1cc2c6-7d04-11ed-a1eb-0242ac120002',
        createdAt: '2022-06-25T13:45:11.748Z',
        updatedAt: '2022-06-25T13:45:11.748Z',
        type: null,
        name: '5일 미접속',
        filters: [
          [
            {
              type: 'LAST_ACTIVE_AT_WITHIN_HOURS',
              values: ['NOT_EQ', '120'],
            },
          ],
        ],
        projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
        c: 25503,
      },
      template: {
        id: '6ecf701c-e165-4a6e-85a6-abe46e9d316c',
        createdAt: '2022-02-02T14:38:21.727Z',
        updatedAt: '2022-02-02T14:38:21.727Z',
        name: '재방문 유도 자동화 - Step 1',
        category: {
          id: '965446bf-baf9-4552-8b55-f83b078c5571',
          createdAt: null,
          updatedAt: null,
          name: '유입',
          color: '#826aaa',
        },
        notification: {
          url: 'app://store/products/new',
          title: '😭 안 들어오신지 꽤 되셨어요.',
          body: '신상품이 정말 많이 추가되었는데 보러오지 않으실래요? 🙏',
        },
      },
      sentDevices: 57962,
      convertedRate: 0.512,
    },
    {
      id: '2e27a49e-f3b2-498d-875e-eb593010da6c',
      active: true,
      name: '재방문 유도 자동화 - Step 2',
      dndStartHour: 21,
      dndEndHour: 8,
      resendIntervalMinutes: null,
      segment: {
        id: 'aba82b5c-e3d4-494b-9e86-6955635ef153',
        createdAt: '2022-06-25T13:55:11.748Z',
        updatedAt: '2022-06-25T13:55:11.748Z',
        type: null,
        name: '10일 미접속',
        filters: [
          [
            {
              type: 'LAST_ACTIVE_AT_WITHIN_HOURS',
              values: ['NOT_EQ', '240'],
            },
          ],
        ],
        projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
        c: 15937,
      },
      template: {
        id: '1afabe89-3887-4ef7-b213-95e219cdaf38',
        createdAt: '2022-02-02T14:42:21.727Z',
        updatedAt: '2022-02-02T14:42:21.727Z',
        name: '재방문 유도 자동화 - Step 2',
        category: {
          id: '965446bf-baf9-4552-8b55-f83b078c5571',
          createdAt: null,
          updatedAt: null,
          name: '유입',
          color: '#826aaa',
        },
        notification: {
          url: 'app://store/coupon/{{couponCode}}',
          title: '🥺 저희를 잊으신 건 아니죠..?',
          body: '돌아오실 날 기다리며 할인쿠폰 하나 발급해드렸답니다. 🎁',
        },
      },
      sentDevices: 57115,
      convertedRate: 0.488,
    },
    {
      id: '3e39795f-7601-4db7-bd5d-8e6d6c2c4228',
      active: true,
      name: '회원가입 유도 자동화',
      dndStartHour: 21,
      dndEndHour: 8,
      resendIntervalMinutes: null,
      segment: {
        id: 'a7b4df76-7d04-11ed-a1eb-0242ac120002',
        createdAt: '2022-06-24T01:18:35.673Z',
        updatedAt: '2022-06-24T01:18:35.674Z',
        type: null,
        name: '3일 이상 비회원',
        filters: [
          [
            {
              type: 'CREATED_AT_WITHIN_HOURS',
              values: ['NOT_EQ', '72'],
            },
            {
              type: 'USER_ID',
              values: ['NOT_EXIST', ''],
            },
          ],
        ],
        projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
        c: 17235,
      },
      template: {
        id: '6a3e8e56-7d06-11ed-a1eb-0242ac120002',
        createdAt: '2022-02-02T13:15:11.727Z',
        updatedAt: '2022-02-02T13:15:11.727Z',
        name: '회원가입 유도',
        category: {
          id: '965446bf-baf9-4552-8b55-f83b078c5571',
          createdAt: null,
          updatedAt: null,
          name: '유입',
          color: '#826aaa',
        },
        notification: {
          url: 'app://store/coupon/{{couponCode}}',
          title: '🛍️ 바로 지금만! 가입 시 할인쿠폰 지급',
          body: '최소 금액 없이 즉시 사용할 수 있어요. (안 쓰면 손해 😎)',
        },
      },
      sentDevices: 81092,
      convertedRate: 0.613,
    },
    {
      id: 'b580c9d7-2adf-4020-8a1c-c520de1b70d4',
      active: true,
      name: '장바구니 비우기 자동화',
      dndStartHour: 21,
      dndEndHour: 8,
      resendIntervalMinutes: null,
      segment: {
        id: '115f9f7a-ce34-4e52-a3da-0a8eada63eb5',
        createdAt: '2022-06-25T14:13:11.748Z',
        updatedAt: '2022-06-25T14:13:11.748Z',
        type: null,
        name: '장바구니 담은 유저',
        filters: [
          [
            {
              type: 'TAG',
              values: ['cartCount', 'GTE', '1'],
            },
          ],
        ],
        projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
        c: 173005,
      },
      template: {
        id: '5cfb7da1-feb2-4f4f-a1f2-c0cbc24c55f2',
        createdAt: '2021-12-04T11:23:56.727Z',
        updatedAt: '2021-12-30T16:11:34.379Z',
        name: '장바구니 비우기',
        category: {
          id: '965446bf-baf9-4552-8b55-f83b078c5571',
          createdAt: null,
          updatedAt: null,
          name: '결제',
          color: '#67995e',
        },
        notification: {
          url: 'app://store/cart',
          title: '🛒 가득 찬 장바구니',
          body:
            '장바구니에 담은 {{ cartCount }}개 상품들이 구매를 기다리고 있어요. 설마 잊으신 건 아니죠?',
        },
      },
      sentDevices: 151021,
      convertedRate: 0.591,
    },
  ],
  en: [
    {
      id: '0c52b41d-4643-403f-bab9-c2abb223a326',
      active: true,
      name: 'Onboard New Customers_1',
      dndStartHour: 21,
      dndEndHour: 8,
      resendIntervalMinutes: null,
      segment: {
        id: '7397bc7c-7d04-11ed-a1eb-0242ac120002',
        createdAt: '2022-06-24T01:21:22.748Z',
        updatedAt: '2022-06-24T01:21:22.748Z',
        type: null,
        name: '3 hours after opt-in',
        filters: [
          [
            {
              type: 'CREATED_AT_WITHIN_HOURS',
              values: ['NOT_EQ', '3'],
            },
          ],
        ],
        projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
        c: 142,
      },
      template: {
        id: 'be60a172-7d06-11ed-a1eb-0242ac120002',
        createdAt: '2022-02-02T14:20:11.727Z',
        updatedAt: '2022-02-02T14:20:11.727Z',
        name: 'Onboard New Customers_1',
        category: {
          id: 'ad5336bf-baf9-b5d4-8225-f8b98d8c5571',
          createdAt: null,
          updatedAt: null,
          name: 'Onboarding',
          color: '#d67631',
        },
        notification: {
          url: 'app://store/events',
          title: 'Welcome to Flaremall 🥳',
          body:
            "Check out our beginner's guide to get started with our product today🎁",
        },
      },
      sentDevices: 36211,
      convertedRate: 0.324,
    },
    {
      id: '1796b1b6-4547-49b3-9111-b97c0453f9bf',
      active: true,
      name: 'Onboard New Customers_2',
      dndStartHour: 21,
      dndEndHour: 8,
      resendIntervalMinutes: null,
      segment: {
        id: '6cbd7414-7d04-11ed-a1eb-0242ac120002',
        createdAt: '2022-06-24T01:21:32.748Z',
        updatedAt: '2022-06-24T01:21:32.748Z',
        type: null,
        name: '1 day after opt-in',
        filters: [
          [
            {
              type: 'CREATED_AT_WITHIN_HOURS',
              values: ['NOT_EQ', '24'],
            },
          ],
        ],
        projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
        c: 1212,
      },
      template: {
        id: '5cacd0a8-7d07-11ed-a1eb-0242ac120002',
        createdAt: '2022-02-02T14:25:11.727Z',
        updatedAt: '2022-02-02T14:25:11.727Z',
        name: 'Onboard New Customers_2',
        category: {
          id: 'ad5336bf-baf9-b5d4-8225-f8b98d8c5571',
          createdAt: null,
          updatedAt: null,
          name: 'Onboarding',
          color: '#d67631',
        },
        notification: {
          url: 'app://store/products/sale',
          title: 'Special news! 📣',
          body: "🧾 Take a look at this week's discounted item",
        },
      },
      sentDevices: 27298,
      convertedRate: 0.411,
    },
    {
      id: '037871d2-bcd2-464e-9041-8752d3aaf367',
      active: true,
      name: 'Onboard New Customers_3',
      dndStartHour: 21,
      dndEndHour: 8,
      resendIntervalMinutes: null,
      segment: {
        id: '661edcd8-7d04-11ed-a1eb-0242ac120002',
        createdAt: '2022-06-24T01:21:42.748Z',
        updatedAt: '2022-06-24T01:21:42.748Z',
        type: null,
        name: '2 days after opt-in',
        filters: [
          [
            {
              type: 'CREATED_AT_WITHIN_HOURS',
              values: ['NOT_EQ', '48'],
            },
          ],
        ],
        projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
        c: 2188,
      },
      template: {
        id: 'bdcce69d-5bdb-471a-b6ca-477b06bc4ff9',
        createdAt: '2022-02-02T14:32:11.727Z',
        updatedAt: '2022-02-02T14:32:11.727Z',
        name: 'Onboard New Customers_3',
        category: {
          id: 'ad5336bf-baf9-b5d4-8225-f8b98d8c5571',
          createdAt: null,
          updatedAt: null,
          name: 'Onboarding',
          color: '#d67631',
        },
        notification: {
          url: 'app://store/products/recommand/ai-now',
          title: 'Check this out! 📣',
          body: 'FlareMall AI recommended products just for you 🤖',
        },
      },
      sentDevices: 41052,
      convertedRate: 0.398,
    },
    {
      id: '0b1192e9-feab-4437-8335-87ad64c4904c',
      active: true,
      name: 'Encourage Revisits_1',
      dndStartHour: 21,
      dndEndHour: 8,
      resendIntervalMinutes: null,
      segment: {
        id: '5d1cc2c6-7d04-11ed-a1eb-0242ac120002',
        createdAt: '2022-06-25T13:45:11.748Z',
        updatedAt: '2022-06-25T13:45:11.748Z',
        type: null,
        name: 'Last accessed 5 days ago',
        filters: [
          [
            {
              type: 'LAST_ACTIVE_AT_WITHIN_HOURS',
              values: ['NOT_EQ', '120'],
            },
          ],
        ],
        projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
        c: 25503,
      },
      template: {
        id: '6ecf701c-e165-4a6e-85a6-abe46e9d316c',
        createdAt: '2022-02-02T14:38:21.727Z',
        updatedAt: '2022-02-02T14:38:21.727Z',
        name: 'Encourage Revisits_1',
        category: {
          id: '965446bf-baf9-4552-8b55-f83b078c5571',
          createdAt: null,
          updatedAt: null,
          name: 'Retention',
          color: '#826aaa',
        },
        notification: {
          url: 'app://store/products/new',
          title: "😭 We've missed you!",
          body:
            "New products available now. Don't forget to use your exclusive discount code! 💪🏻",
        },
      },
      sentDevices: 57962,
      convertedRate: 0.512,
    },
    {
      id: '2e27a49e-f3b2-498d-875e-eb593010da6c',
      active: true,
      name: 'Encourage Revisits_2',
      dndStartHour: 21,
      dndEndHour: 8,
      resendIntervalMinutes: null,
      segment: {
        id: 'aba82b5c-e3d4-494b-9e86-6955635ef153',
        createdAt: '2022-06-25T13:55:11.748Z',
        updatedAt: '2022-06-25T13:55:11.748Z',
        type: null,
        name: 'Last accessed 10 days ago',
        filters: [
          [
            {
              type: 'LAST_ACTIVE_AT_WITHIN_HOURS',
              values: ['NOT_EQ', '240'],
            },
          ],
        ],
        projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
        c: 15937,
      },
      template: {
        id: '1afabe89-3887-4ef7-b213-95e219cdaf38',
        createdAt: '2022-02-02T14:42:21.727Z',
        updatedAt: '2022-02-02T14:42:21.727Z',
        name: 'Encourage Revisits_2',
        category: {
          id: '965446bf-baf9-4552-8b55-f83b078c5571',
          createdAt: null,
          updatedAt: null,
          name: 'Retention',
          color: '#826aaa',
        },
        notification: {
          url: 'app://store/coupon/{{couponCode}}',
          title: '🥺 We miss you!',
          body: 'A free coupon has been added to your cart 🎁',
        },
      },
      sentDevices: 57115,
      convertedRate: 0.488,
    },
    {
      id: '3e39795f-7601-4db7-bd5d-8e6d6c2c4228',
      active: true,
      name: 'Encourage Sign-ups',
      dndStartHour: 21,
      dndEndHour: 8,
      resendIntervalMinutes: null,
      segment: {
        id: 'a7b4df76-7d04-11ed-a1eb-0242ac120002',
        createdAt: '2022-06-24T01:18:35.673Z',
        updatedAt: '2022-06-24T01:18:35.674Z',
        type: null,
        name: 'Not signed-up for 3 days',
        filters: [
          [
            {
              type: 'CREATED_AT_WITHIN_HOURS',
              values: ['NOT_EQ', '72'],
            },
            {
              type: 'USER_ID',
              values: ['NOT_EXIST', ''],
            },
          ],
        ],
        projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
        c: 17235,
      },
      template: {
        id: '6a3e8e56-7d06-11ed-a1eb-0242ac120002',
        createdAt: '2022-02-02T13:15:11.727Z',
        updatedAt: '2022-02-02T13:15:11.727Z',
        name: 'Encourage Sign-ups',
        category: {
          id: '965446bf-baf9-4552-8b55-f83b078c5571',
          createdAt: null,
          updatedAt: null,
          name: 'Customer Acquisition',
          color: '#826aaa',
        },
        notification: {
          url: 'app://store/coupon/{{couponCode}}',
          title: '🛍️ Ready to elevate your experience with our product?',
          body:
            'Sign up now and receive a special welcome offer to get started 😎',
        },
      },
      sentDevices: 81092,
      convertedRate: 0.613,
    },
    {
      id: 'b580c9d7-2adf-4020-8a1c-c520de1b70d4',
      active: true,
      name: 'Cart Recovery',
      dndStartHour: 21,
      dndEndHour: 8,
      resendIntervalMinutes: null,
      segment: {
        id: '115f9f7a-ce34-4e52-a3da-0a8eada63eb5',
        createdAt: '2022-06-25T14:13:11.748Z',
        updatedAt: '2022-06-25T14:13:11.748Z',
        type: null,
        name: 'Product added to cart',
        filters: [
          [
            {
              type: 'TAG',
              values: ['cartCount', 'GTE', '1'],
            },
          ],
        ],
        projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
        c: 173005,
      },
      template: {
        id: '5cfb7da1-feb2-4f4f-a1f2-c0cbc24c55f2',
        createdAt: '2021-12-04T11:23:56.727Z',
        updatedAt: '2021-12-30T16:11:34.379Z',
        name: 'Cart Recovery',
        category: {
          id: '965446bf-baf9-4552-8b55-f83b078c5571',
          createdAt: null,
          updatedAt: null,
          name: 'Payment',
          color: '#67995e',
        },
        notification: {
          url: 'app://store/cart',
          title: '🛒 Forget Something?',
          body:
            'It seems like you left {{ cartCount }} products in the shopping cart',
        },
      },
      sentDevices: 151021,
      convertedRate: 0.591,
    },
  ],
};

export const demoPrebuiltAutomationsData = {
  kr: [
    {
      id: 'NEW_USER_ONBOARDING',
      section: 'base',
      title: '신규 유저 대상 우리 서비스 소개하기',
      description:
        '신규 유저에게 구석구석 우리 서비스를 소개하거나 지금 진행 중인 프로모션을 알려주세요.',
      imageUrl:
        'https://imagedelivery.net/lfkkXChLw6uG9RcA2ff5fg/9d8772d5-9b36-4ac1-11c7-99c558b64b00/flarelane',
      created: false,
      automation: {
        entryType: 'SEGMENT',
        entryConditions: {
          event: null,
          segment: [
            [
              {
                type: 'CREATED_AT_WITHIN_HOURS',
                values: ['NOT_EQ', '1'],
              },
            ],
          ],
        },
        startActions: [
          {
            type: 'SEND_NOTIFICATION',
            waitMinutes: 0,
            splitWeightPercent: null,
            nextActions: [
              {
                type: 'SEND_NOTIFICATION',
                waitMinutes: 360,
                splitWeightPercent: null,
                nextActions: [
                  {
                    type: 'SEND_NOTIFICATION',
                    waitMinutes: 1440,
                    splitWeightPercent: null,
                    nextActions: [],
                    payload: {
                      title: '저희 이런 것도 있어요! 📣',
                      body: '플레어 AI🤖가 제공하는 초개인화 추천 상품입니다.',
                      imageUrl:
                        'https://imagedelivery.net/lfkkXChLw6uG9RcA2ff5fg/5fe138d5-4307-4334-36b3-4361ade42e00/flarelane',
                    },
                  },
                ],
                payload: {
                  title: '바쁜 고객님을 위한 맞춤 알림! 📣',
                  body: '🧾 이번 주 할인하는 인기 상품들을 확인해보세요.',
                  imageUrl:
                    'https://imagedelivery.net/lfkkXChLw6uG9RcA2ff5fg/df11c268-776e-4578-7052-bf2413710800/flarelane',
                },
              },
            ],
            payload: {
              title: '🥳 반가워요. 플레어몰입니다.',
              body:
                '마침 여름 맞이 할인 이벤트🎁를 진행하고 있었는데 한번 확인해보실래요?',
              imageUrl:
                'https://imagedelivery.net/lfkkXChLw6uG9RcA2ff5fg/a149eb99-0cf7-42da-e1d9-4e95931af800/flarelane',
            },
          },
        ],
      },
    },
    {
      id: 'INACTIVE_USER_RETENTION',
      section: 'base',
      title: '미접속 유저 대상 재방문율 높이기',
      description:
        '일정 기간 접속하지 않은 유저의 재방문을 유도하여 이탈률을 개선합니다.',
      imageUrl:
        'https://imagedelivery.net/lfkkXChLw6uG9RcA2ff5fg/a1ed6bc0-a2dc-4d3e-fd1a-b38dc11d2700/flarelane',
      created: false,
      automation: {
        entryType: 'SEGMENT',
        entryConditions: {
          event: null,
          segment: [
            [
              {
                type: 'LAST_ACTIVE_AT_WITHIN_HOURS',
                values: ['NOT_EQ', '168'],
              },
            ],
          ],
        },
        startActions: [
          {
            type: 'SEND_NOTIFICATION',
            waitMinutes: 0,
            splitWeightPercent: null,
            nextActions: [],
            payload: {
              title: '😭 안 들어오신지 꽤 되셨어요.',
              body: '신상품이 정말 많이 추가되었는데 보러오지 않으실래요? 🙏',
            },
          },
        ],
      },
    },
    {
      id: 'NEW_USER_SIGNUP',
      section: 'base',
      title: '회원가입율 높이기',
      description:
        '회원에게만 제공되는 쿠폰 혹은 전용 혜택을 통해 충성 고객으로 전환시키세요.',
      imageUrl:
        'https://imagedelivery.net/lfkkXChLw6uG9RcA2ff5fg/2e4c3720-b078-4d61-3898-75594b249200/flarelane',
      created: false,
      automation: {
        entryType: 'SEGMENT',
        entryConditions: {
          event: null,
          segment: [
            [
              {
                type: 'USER_ID',
                values: ['NOT_EXIST', ''],
              },
              {
                type: 'CREATED_AT_WITHIN_HOURS',
                values: ['NOT_EQ', '24'],
              },
            ],
          ],
        },
        startActions: [
          {
            type: 'SEND_NOTIFICATION',
            waitMinutes: 0,
            splitWeightPercent: null,
            nextActions: [],
            payload: {
              title: '🛍️ 바로 지금만! 가입 시 할인쿠폰 지급',
              body: '최소 금액 없이 즉시 사용할 수 있어요. (안 쓰면 손해 😎)',
            },
          },
        ],
      },
    },
    {
      id: 'FIRST_PURCHASE',
      section: 'commerce',
      title: '첫 구매 유도하기',
      description:
        '구매율 높이기의 시작! 첫 구매 전용 혜택으로 신규 유저의 첫 구매를 적극 유도하세요.',
      imageUrl:
        'https://imagedelivery.net/lfkkXChLw6uG9RcA2ff5fg/88ae7898-3529-41b1-cc01-dd5c7ae52900/flarelane',
      created: false,
      automation: {
        entryType: 'EVENT',
        entryConditions: {
          event: [
            [
              {
                eventType: '@first_session',
              },
            ],
          ],
          segment: null,
        },
        startActions: [
          {
            type: 'SEND_NOTIFICATION',
            waitMinutes: 180,
            splitWeightPercent: null,
            nextActions: [],
            payload: {
              title: '🎁 첫 구매 전용 쿠폰팩 도착',
              body:
                '오직 지금만 사용할 수 있는 할인 쿠폰이 있어요! 해당 쿠폰은 7일 이내 사용 가능하니 서두르세요.⏰',
            },
          },
        ],
        exitConditions: {
          event: [
            [
              {
                eventType: 'purchase',
              },
            ],
          ],
          segment: null,
        },
      },
    },
    {
      id: 'ABANDONED_CART',
      section: 'commerce',
      title: '장바구니 비우기',
      description:
        '장바구니에 담겨진 상품은 구매확률이 높습니다. 구매로 이어지도록 리마인드 알림을 보내세요.',
      imageUrl:
        'https://imagedelivery.net/lfkkXChLw6uG9RcA2ff5fg/81d2305f-a1d8-4415-d5fc-fcf49911de00/flarelane',
      created: false,
      automation: {
        entryType: 'EVENT',
        entryConditions: {
          event: [
            [
              {
                eventType: 'add_to_cart',
              },
            ],
          ],
          segment: null,
        },
        startActions: [
          {
            type: 'SEND_NOTIFICATION',
            waitMinutes: 180,
            splitWeightPercent: null,
            nextActions: [],
            payload: {
              title: '🛒 고객님! 뭔가 잊으신 건 없나요?',
              body:
                '장바구니에 담겨진 상품이 있어요.😢 지금 바로 보러 가실까요?',
            },
          },
        ],
        exitConditions: {
          event: [
            [
              {
                eventType: 'purchase',
              },
            ],
          ],
          segment: null,
        },
      },
    },
    {
      id: 'REPURCHASE',
      section: 'commerce',
      title: '재구매 유도하기',
      description:
        '구매 고객을 감동시킬 후속 알림으로 재구매를 유도하고 단골 고객으로 만드세요.',
      imageUrl:
        'https://imagedelivery.net/lfkkXChLw6uG9RcA2ff5fg/1d02bc00-457a-4d1e-07b3-5fbd2d499e00/flarelane',
      created: false,
      automation: {
        entryType: 'EVENT',
        entryConditions: {
          event: [
            [
              {
                eventType: 'purchase',
              },
            ],
          ],
          segment: null,
        },
        startActions: [
          {
            type: 'SEND_NOTIFICATION',
            waitMinutes: 10080,
            splitWeightPercent: null,
            nextActions: [],
            payload: {
              title: '🥰 감사한 마음을 담아 다른 것도 준비해봤어요',
              body:
                '지난 번 구매하신 상품과 어울릴만한 다른 상품들을 모아봤는데, 구경해보실래요?',
            },
          },
        ],
        exitConditions: {
          event: [
            [
              {
                eventType: 'purchase',
              },
            ],
          ],
          segment: null,
        },
      },
    },
    {
      id: 'WRITE_REVIEW',
      section: 'commerce',
      title: '리뷰 작성 유도하기',
      description:
        '리뷰는 새로운 구매를 부른다! 리뷰 혜택을 적극 소개하고 구매 후 리뷰 작성을 유도하세요.',
      imageUrl:
        'https://imagedelivery.net/lfkkXChLw6uG9RcA2ff5fg/00d6166e-0f77-44b4-b3c2-fad5e6eff900/flarelane',
      created: false,
      automation: {
        entryType: 'EVENT',
        entryConditions: {
          event: [
            [
              {
                eventType: 'purchase',
              },
            ],
          ],
          segment: null,
        },
        startActions: [
          {
            type: 'SEND_NOTIFICATION',
            waitMinutes: 10080,
            splitWeightPercent: null,
            nextActions: [],
            payload: {
              title: '🥹 고객님, 주문하신 상품은 마음에 드시나요?',
              body:
                '솔직담백 후기가 궁금해요! 📝 리뷰 작성하고 2000원 적립금 받아가세요!',
            },
          },
        ],
        exitConditions: {
          event: [
            [
              {
                eventType: 'write_review',
              },
            ],
          ],
          segment: null,
        },
      },
    },
  ],
  en: [
    {
      id: 'NEW_USER_ONBOARDING',
      section: 'base',
      title: 'Onboard New Customers',
      description:
        'Welcome new customers and help them get started with your product or service.',
      imageUrl:
        'https://imagedelivery.net/lfkkXChLw6uG9RcA2ff5fg/9d8772d5-9b36-4ac1-11c7-99c558b64b00/flarelane',
      created: false,
      automation: {
        entryType: 'SEGMENT',
        entryConditions: {
          event: null,
          segment: [
            [
              {
                type: 'CREATED_AT_WITHIN_HOURS',
                values: ['NOT_EQ', '1'],
              },
            ],
          ],
        },
        startActions: [
          {
            type: 'SEND_NOTIFICATION',
            waitMinutes: 0,
            splitWeightPercent: null,
            nextActions: [
              {
                type: 'SEND_NOTIFICATION',
                waitMinutes: 360,
                splitWeightPercent: null,
                nextActions: [
                  {
                    type: 'SEND_NOTIFICATION',
                    waitMinutes: 1440,
                    splitWeightPercent: null,
                    nextActions: [],
                    payload: {
                      title: 'Check this out! 📣',
                      body: 'FlareMall AI recommended products just for you 🤖',
                      imageUrl:
                        'https://imagedelivery.net/lfkkXChLw6uG9RcA2ff5fg/5fe138d5-4307-4334-36b3-4361ade42e00/flarelane',
                    },
                  },
                ],
                payload: {
                  title: 'Special news! 📣',
                  body: "🧾 Take a look at this week's discounted item",
                  imageUrl:
                    'https://imagedelivery.net/lfkkXChLw6uG9RcA2ff5fg/df11c268-776e-4578-7052-bf2413710800/flarelane',
                },
              },
            ],
            payload: {
              title: '🥳 Welcome to FlareMall',
              body:
                "Check out our beginner's guide to get started with our product today🎁",
              imageUrl:
                'https://imagedelivery.net/lfkkXChLw6uG9RcA2ff5fg/a149eb99-0cf7-42da-e1d9-4e95931af800/flarelane',
            },
          },
        ],
      },
    },
    {
      id: 'INACTIVE_USER_RETENTION',
      section: 'base',
      title: 'Encourage Revisits',
      description:
        'Encourage dormant users to revisit your website or app and engage with your business.',
      imageUrl:
        'https://imagedelivery.net/lfkkXChLw6uG9RcA2ff5fg/a1ed6bc0-a2dc-4d3e-fd1a-b38dc11d2700/flarelane',
      created: false,
      automation: {
        entryType: 'SEGMENT',
        entryConditions: {
          event: null,
          segment: [
            [
              {
                type: 'LAST_ACTIVE_AT_WITHIN_HOURS',
                values: ['NOT_EQ', '168'],
              },
            ],
          ],
        },
        startActions: [
          {
            type: 'SEND_NOTIFICATION',
            waitMinutes: 0,
            splitWeightPercent: null,
            nextActions: [],
            payload: {
              title: "😭 We've missed you!",
              body:
                "New products available now. Don't forget to use your exclusive discount code! 💪🏻",
            },
          },
        ],
      },
    },
    {
      id: 'NEW_USER_SIGNUP',
      section: 'base',
      title: 'Encourage Sign-ups',
      description:
        'Encourage website visitors or app users to sign up for your product or service.',
      imageUrl:
        'https://imagedelivery.net/lfkkXChLw6uG9RcA2ff5fg/2e4c3720-b078-4d61-3898-75594b249200/flarelane',
      created: false,
      automation: {
        entryType: 'SEGMENT',
        entryConditions: {
          event: null,
          segment: [
            [
              {
                type: 'USER_ID',
                values: ['NOT_EXIST', ''],
              },
              {
                type: 'CREATED_AT_WITHIN_HOURS',
                values: ['NOT_EQ', '24'],
              },
            ],
          ],
        },
        startActions: [
          {
            type: 'SEND_NOTIFICATION',
            waitMinutes: 0,
            splitWeightPercent: null,
            nextActions: [],
            payload: {
              title: '🛍️ Ready to elevate your experience with our product?',
              body:
                'Sign up now and receive a special welcome offer to get started 😎',
            },
          },
        ],
      },
    },
    {
      id: 'FIRST_PURCHASE',
      section: 'commerce',
      title: 'Encourage First Purchase',
      description:
        'Provide incentives to first-time customers to encourage them to complete their first purchase.',
      imageUrl:
        'https://imagedelivery.net/lfkkXChLw6uG9RcA2ff5fg/88ae7898-3529-41b1-cc01-dd5c7ae52900/flarelane',
      created: false,
      automation: {
        entryType: 'EVENT',
        entryConditions: {
          event: [
            [
              {
                eventType: '@first_session',
              },
            ],
          ],
          segment: null,
        },
        startActions: [
          {
            type: 'SEND_NOTIFICATION',
            waitMinutes: 180,
            splitWeightPercent: null,
            nextActions: [],
            payload: {
              title: '🎁 Promo code for first time purchase',
              body:
                '7 days only! Use this promo code for 10% off your first purchase ⏰',
            },
          },
        ],
        exitConditions: {
          event: [
            [
              {
                eventType: 'purchase',
              },
            ],
          ],
          segment: null,
        },
      },
    },
    {
      id: 'ABANDONED_CART',
      section: 'commerce',
      title: 'Cart Recovery',
      description:
        'Remind customers who have abandoned their shopping cart to complete the purchase and recover lost sales.',
      imageUrl:
        'https://imagedelivery.net/lfkkXChLw6uG9RcA2ff5fg/81d2305f-a1d8-4415-d5fc-fcf49911de00/flarelane',
      created: false,
      automation: {
        entryType: 'EVENT',
        entryConditions: {
          event: [
            [
              {
                eventType: 'add_to_cart',
              },
            ],
          ],
          segment: null,
        },
        startActions: [
          {
            type: 'SEND_NOTIFICATION',
            waitMinutes: 180,
            splitWeightPercent: null,
            nextActions: [],
            payload: {
              title: '🤔 Forget something?',
              body:
                'Complete your purchase now and enjoy free shipping on us 🛒',
            },
          },
        ],
        exitConditions: {
          event: [
            [
              {
                eventType: 'purchase',
              },
            ],
          ],
          segment: null,
        },
      },
    },
    {
      id: 'REPURCHASE',
      section: 'commerce',
      title: 'Encourage Repeat Purchase',
      description:
        'Encourage existing customers to make repeat purchases and build customer loyalty.',
      imageUrl:
        'https://imagedelivery.net/lfkkXChLw6uG9RcA2ff5fg/1d02bc00-457a-4d1e-07b3-5fbd2d499e00/flarelane',
      created: false,
      automation: {
        entryType: 'EVENT',
        entryConditions: {
          event: [
            [
              {
                eventType: 'purchase',
              },
            ],
          ],
          segment: null,
        },
        startActions: [
          {
            type: 'SEND_NOTIFICATION',
            waitMinutes: 10080,
            splitWeightPercent: null,
            nextActions: [],
            payload: {
              title: '🥰 Thanks for your recent purchase!',
              body:
                'Enjoy 15% off your next purchase as a thank you for being a loyal customer.',
            },
          },
        ],
        exitConditions: {
          event: [
            [
              {
                eventType: 'purchase',
              },
            ],
          ],
          segment: null,
        },
      },
    },
    {
      id: 'WRITE_REVIEW',
      section: 'commerce',
      title: 'Encourage To Write Reviews',
      description:
        'Request customers their feedback on your product or service and encourage them to write reviews.',
      imageUrl:
        'https://imagedelivery.net/lfkkXChLw6uG9RcA2ff5fg/00d6166e-0f77-44b4-b3c2-fad5e6eff900/flarelane',
      created: false,
      automation: {
        entryType: 'EVENT',
        entryConditions: {
          event: [
            [
              {
                eventType: 'purchase',
              },
            ],
          ],
          segment: null,
        },
        startActions: [
          {
            type: 'SEND_NOTIFICATION',
            waitMinutes: 10080,
            splitWeightPercent: null,
            nextActions: [],
            payload: {
              title: '🥹 How was your recent purchase?',
              body:
                'Let us know by leaving a review and receive 10% off your next purchase! 📝',
            },
          },
        ],
        exitConditions: {
          event: [
            [
              {
                eventType: 'write_review',
              },
            ],
          ],
          segment: null,
        },
      },
    },
  ],
} as any;

export const demoPebuiltAutomatedMessageData: {
  kr: AutomatedExample[];
  en: AutomatedExample[];
} = {
  kr: [
    {
      id: 'NEW_USER_ONBOARDING',
      section: 'base',
      imageUrl:
        'https://imagedelivery.net/lfkkXChLw6uG9RcA2ff5fg/9d8772d5-9b36-4ac1-11c7-99c558b64b00/flarelane',
      filters: [
        [
          {
            type: 'CREATED_AT_WITHIN_HOURS',
            values: ['NOT_EQ', '1'],
          },
        ],
      ],
      title: '신규 유저 대상 우리 서비스 소개하기',
      description:
        '신규 유저에게 구석구석 우리 서비스를 소개하거나 지금 진행 중인 프로모션을 알려주세요.',
      notification: {
        title: '🥳 반가워요. 플레어몰입니다.',
        body:
          '마침 여름 맞이 할인 이벤트🎁를 진행하고 있었는데 한번 확인해보실래요?',
      },
      created: false,
    },
    {
      id: 'INACTIVE_USER_RETENTION',
      section: 'base',
      imageUrl:
        'https://imagedelivery.net/lfkkXChLw6uG9RcA2ff5fg/a1ed6bc0-a2dc-4d3e-fd1a-b38dc11d2700/flarelane',
      filters: [
        [
          {
            type: 'LAST_ACTIVE_AT_WITHIN_HOURS',
            values: ['NOT_EQ', '168'],
          },
        ],
      ],
      title: '미접속 유저 대상 재방문율 높이기',
      description:
        '일정 기간 접속하지 않은 유저의 재방문을 유도하여 이탈률을 개선합니다.',
      notification: {
        title: '😭 안 들어오신지 꽤 되셨어요.',
        body: '신상품이 정말 많이 추가되었는데 보러오지 않으실래요? 🙏',
      },
      created: false,
    },
    {
      id: 'NEW_USER_SIGNUP',
      section: 'base',
      imageUrl:
        'https://imagedelivery.net/lfkkXChLw6uG9RcA2ff5fg/2e4c3720-b078-4d61-3898-75594b249200/flarelane',
      filters: [
        [
          {
            type: 'USER_ID',
            values: ['NOT_EXIST', ''],
          },
          {
            type: 'CREATED_AT_WITHIN_HOURS',
            values: ['NOT_EQ', '24'],
          },
        ],
      ],
      title: '회원가입율 높이기',
      description:
        '회원에게만 제공되는 쿠폰 혹은 전용 혜택을 통해 충성 고객으로 전환시키세요.',
      notification: {
        title: '🛍️ 바로 지금만! 가입 시 할인쿠폰 지급',
        body: '최소 금액 없이 즉시 사용할 수 있어요. (안 쓰면 손해 😎)',
      },
      created: false,
    },
    {
      id: 'FIRST_PURCHASE',
      section: 'commerce',
      imageUrl:
        'https://imagedelivery.net/lfkkXChLw6uG9RcA2ff5fg/88ae7898-3529-41b1-cc01-dd5c7ae52900/flarelane',
      filters: [
        [
          {
            type: 'CREATED_AT_WITHIN_HOURS',
            values: ['NOT_EQ', '3'],
          },
          {
            type: 'TAG',
            values: ['last_purchase_timestamp', 'NOT_EXIST', ''],
          },
        ],
      ],
      title: '첫 구매 유도하기',
      description:
        '구매율 높이기의 시작! 첫 구매 전용 혜택으로 신규 유저의 첫 구매를 적극 유도하세요.',
      notification: {
        title: '🎁 첫 구매 전용 쿠폰팩 도착',
        body:
          '오직 지금만 사용할 수 있는 할인 쿠폰이 있어요! 해당 쿠폰은 7일 이내 사용 가능하니 서두르세요.⏰',
      },
      created: false,
    },
    {
      id: 'ABANDONED_CART',
      section: 'commerce',
      imageUrl:
        'https://imagedelivery.net/lfkkXChLw6uG9RcA2ff5fg/81d2305f-a1d8-4415-d5fc-fcf49911de00/flarelane',
      filters: [
        [
          {
            type: 'TAG',
            values: ['cart_timestamp', 'AFTER_HOURS', '24'],
          },
        ],
      ],
      title: '장바구니 비우기',
      description:
        '장바구니에 담겨진 상품은 구매확률이 높습니다. 구매로 이어지도록 리마인드 알림을 보내세요.',
      notification: {
        title: '🛒 고객님! 뭔가 잊으신 건 없나요?',
        body: '장바구니에 담겨진 상품이 있어요.😢 지금 바로 보러 가실까요?',
      },
      created: false,
    },
    {
      id: 'REPURCHASE',
      section: 'commerce',
      imageUrl:
        'https://imagedelivery.net/lfkkXChLw6uG9RcA2ff5fg/1d02bc00-457a-4d1e-07b3-5fbd2d499e00/flarelane',
      filters: [
        [
          {
            type: 'TAG',
            values: ['last_purchase_timestamp', 'AFTER_HOURS', '168'],
          },
        ],
      ],
      title: '재구매 유도하기',
      description:
        '구매 고객을 감동시킬 후속 알림으로 재구매를 유도하고 단골 고객으로 만드세요.',
      notification: {
        title: '🥰 감사한 마음을 담아 다른 것도 준비해봤어요',
        body:
          '지난 번 구매하신 상품과 어울릴만한 다른 상품들을 모아봤는데, 구경해보실래요?',
      },
      created: false,
    },
    {
      id: 'WRITE_REVIEW',
      section: 'commerce',
      imageUrl:
        'https://imagedelivery.net/lfkkXChLw6uG9RcA2ff5fg/00d6166e-0f77-44b4-b3c2-fad5e6eff900/flarelane',
      filters: [
        [
          {
            type: 'TAG',
            values: ['review_timestamp', 'AFTER_HOURS', '168'],
          },
        ],
      ],
      title: '리뷰 작성 유도하기',
      description:
        '리뷰는 새로운 구매를 부른다! 리뷰 혜택을 적극 소개하고 구매 후 리뷰 작성을 유도하세요.',
      notification: {
        title: '🥹 고객님, 주문하신 상품은 마음에 드시나요?',
        body:
          '솔직담백 후기가 궁금해요! 📝 리뷰 작성하고 2000원 적립금 받아가세요!',
      },
      created: false,
    },
  ] as any,
  en: [
    {
      id: 'NEW_USER_ONBOARDING',
      section: 'base',
      imageUrl:
        'https://imagedelivery.net/lfkkXChLw6uG9RcA2ff5fg/9d8772d5-9b36-4ac1-11c7-99c558b64b00/flarelane',
      filters: [
        [
          {
            type: 'CREATED_AT_WITHIN_HOURS',
            values: ['NOT_EQ', '1'],
          },
        ],
      ],
      title: 'Onboard New Customers',
      description:
        'Welcome new customers and help them get started with your product or service',
      notification: {
        title: '🥳 Welcome to FlareMall',
        body:
          "Check out our beginner's guide to get started with our product today🎁",
      },
      created: false,
    },
    {
      id: 'INACTIVE_USER_RETENTION',
      section: 'base',
      imageUrl:
        'https://imagedelivery.net/lfkkXChLw6uG9RcA2ff5fg/a1ed6bc0-a2dc-4d3e-fd1a-b38dc11d2700/flarelane',
      filters: [
        [
          {
            type: 'LAST_ACTIVE_AT_WITHIN_HOURS',
            values: ['NOT_EQ', '168'],
          },
        ],
      ],
      title: 'Encourage Revisits',
      description:
        'Encourage dormant users to revisit your website or app and engage with your business',
      notification: {
        title: "😭 We've missed you!",
        body:
          "New products available now. Don't forget to use your exclusive discount code! 💪🏻",
      },
      created: false,
    },
    {
      id: 'NEW_USER_SIGNUP',
      section: 'base',
      imageUrl:
        'https://imagedelivery.net/lfkkXChLw6uG9RcA2ff5fg/2e4c3720-b078-4d61-3898-75594b249200/flarelane',
      filters: [
        [
          {
            type: 'USER_ID',
            values: ['NOT_EXIST', ''],
          },
          {
            type: 'CREATED_AT_WITHIN_HOURS',
            values: ['NOT_EQ', '24'],
          },
        ],
      ],
      title: 'Encourage Sign-ups',
      description:
        'Encourage website visitors or app users to sign up for your product or service',
      notification: {
        title: '🛍️ Ready to elevate your experience with our product?',
        body:
          'Sign up now and receive a special welcome offer to get started 😎',
      },
      created: false,
    },
    {
      id: 'FIRST_PURCHASE',
      section: 'commerce',
      imageUrl:
        'https://imagedelivery.net/lfkkXChLw6uG9RcA2ff5fg/88ae7898-3529-41b1-cc01-dd5c7ae52900/flarelane',
      filters: [
        [
          {
            type: 'CREATED_AT_WITHIN_HOURS',
            values: ['NOT_EQ', '3'],
          },
          {
            type: 'TAG',
            values: ['last_purchase_timestamp', 'NOT_EXIST', ''],
          },
        ],
      ],
      title: 'Encourage First Purchase',
      description:
        'Provide incentives to first-time customers to encourage them to complete their first purchase',
      notification: {
        title: '🎁 Promo code for first time purchase',
        body:
          '7 days only! Use this promo code for 10% off your first purchase ⏰',
      },
      created: false,
    },
    {
      id: 'ABANDONED_CART',
      section: 'commerce',
      imageUrl:
        'https://imagedelivery.net/lfkkXChLw6uG9RcA2ff5fg/81d2305f-a1d8-4415-d5fc-fcf49911de00/flarelane',
      filters: [
        [
          {
            type: 'TAG',
            values: ['cart_timestamp', 'AFTER_HOURS', '24'],
          },
        ],
      ],
      title: 'Cart Recovery',
      description:
        'Remind customers who have abandoned their shopping cart to complete the purchase and recover lost sales',
      notification: {
        title: '🤔 Forget something?',
        body: 'Complete your purchase now and enjoy free shipping on us 🛒',
      },
      created: false,
    },
    {
      id: 'REPURCHASE',
      section: 'commerce',
      imageUrl:
        'https://imagedelivery.net/lfkkXChLw6uG9RcA2ff5fg/1d02bc00-457a-4d1e-07b3-5fbd2d499e00/flarelane',
      filters: [
        [
          {
            type: 'TAG',
            values: ['last_purchase_timestamp', 'AFTER_HOURS', '168'],
          },
        ],
      ],
      title: 'Encourage Repeat Purchase',
      description:
        'Encourage existing customers to make repeat purchases and build customer loyalty',
      notification: {
        title: '🥰 Thanks for your recent purchase!',
        body:
          'Enjoy 15% off your next purchase as a thank you for being a loyal customer',
      },
      created: false,
    },
    {
      id: 'WRITE_REVIEW',
      section: 'commerce',
      imageUrl:
        'https://imagedelivery.net/lfkkXChLw6uG9RcA2ff5fg/00d6166e-0f77-44b4-b3c2-fad5e6eff900/flarelane',
      filters: [
        [
          {
            type: 'TAG',
            values: ['review_timestamp', 'AFTER_HOURS', '168'],
          },
        ],
      ],
      title: 'Encourage To Write Reviews',
      description:
        'Request customers their feedback on your product or service and encourage them to write reviews',
      notification: {
        title: '🥹 How was your recent purchase?',
        body:
          'Let us know by leaving a review and receive 10% off your next purchase! 📝',
      },
      created: false,
    },
  ] as any,
};

export const demoAutomationsData = {
  ko: [
    {
      id: '1bf625eb-f8d5-408a-9d42-da75d6026d43',
      createdAt: '2023-08-17T02:09:20.464Z',
      updatedAt: '2023-08-17T02:09:22.297Z',
      name: '리뷰 작성 유도하기',
      active: true,
      lastActivatedAt: null,
      entryType: 'EVENT',
      entryConditions: {
        event: [
          [
            {
              eventType: 'purchase',
            },
          ],
        ],
        segment: null,
      },
      exitConditions: {
        event: [
          [
            {
              eventType: 'write_review',
            },
          ],
        ],
        segment: null,
      },
      goalConditions: null,
      startActions: [
        {
          id: '1b02ee33-913f-4d85-9c51-e42e86a5a776',
          createdAt: '2023-08-17T02:09:22.297Z',
          updatedAt: '2023-08-17T02:09:22.297Z',
          type: 'SEND_NOTIFICATION',
          payload: {
            body:
              '솔직담백 후기가 궁금해요! 📝 리뷰 작성하고 2000원 적립금 받아가세요!',
            title: '🥹 고객님, 주문하신 상품은 마음에 드시나요?',
          },
          waitMinutes: 10080,
          splitWeightPercent: null,
          nextActions: [],
          waiting: 1520,
          sent: 10962,
          clicked: 2779,
        },
      ],
      settings: {
        dnd: {
          endHour: 8,
          startHour: 21,
          handlingType: 'DELAY',
        },
      },
      exampleId: 'WRITE_REVIEW',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      archivedAt: null,
      handlingOnPause: null,
      waiting: 1520,
      sent: 10962,
      clicked: 2779,
    },
    {
      id: '3b4b2b57-8c77-4d08-b943-69c64581eb2c',
      createdAt: '2023-08-17T02:09:08.862Z',
      updatedAt: '2023-08-17T02:09:11.058Z',
      name: '재구매 유도하기',
      active: true,
      lastActivatedAt: null,
      entryType: 'EVENT',
      entryConditions: {
        event: [
          [
            {
              eventType: 'purchase',
            },
          ],
        ],
        segment: null,
      },
      exitConditions: {
        event: [
          [
            {
              eventType: 'purchase',
            },
          ],
        ],
        segment: null,
      },
      goalConditions: null,
      startActions: [
        {
          id: 'e5f95b10-0d63-4dec-a629-b185db0e091d',
          createdAt: '2023-08-17T02:09:11.057Z',
          updatedAt: '2023-08-17T02:09:11.058Z',
          type: 'SEND_NOTIFICATION',
          payload: {
            body:
              '지난 번 구매하신 상품과 어울릴만한 다른 상품들을 모아봤는데, 구경해보실래요?',
            title: '🥰 감사한 마음을 담아 다른 것도 준비해봤어요',
          },
          waitMinutes: 10080,
          splitWeightPercent: null,
          nextActions: [],
          waiting: 8704,
          sent: 20859,
          clicked: 6090,
        },
      ],
      settings: {
        dnd: {
          endHour: 8,
          startHour: 21,
          handlingType: 'DELAY',
        },
      },
      exampleId: 'REPURCHASE',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      archivedAt: null,
      handlingOnPause: null,
      waiting: 8704,
      sent: 20859,
      clicked: 6090,
    },
    {
      id: '951f0921-1f57-4ff3-a43c-edd420fa35a5',
      createdAt: '2023-08-17T02:09:00.758Z',
      updatedAt: '2023-08-17T02:09:02.664Z',
      name: '장바구니 비우기',
      active: true,
      lastActivatedAt: null,
      entryType: 'EVENT',
      entryConditions: {
        event: [
          [
            {
              eventType: 'add_to_cart',
            },
          ],
        ],
        segment: null,
      },
      exitConditions: {
        event: [
          [
            {
              eventType: 'purchase',
            },
          ],
        ],
        segment: null,
      },
      goalConditions: null,
      startActions: [
        {
          id: '48fc40ab-2f8c-42c3-8789-0e67e5ed97ca',
          createdAt: '2023-08-17T02:09:02.663Z',
          updatedAt: '2023-08-17T02:09:02.664Z',
          type: 'SEND_NOTIFICATION',
          payload: {
            body: '장바구니에 담겨진 상품이 있어요.😢 지금 바로 보러 가실까요?',
            title: '🛒 고객님! 뭔가 잊으신 건 없나요?',
          },
          waitMinutes: 180,
          splitWeightPercent: null,
          nextActions: [],
          waiting: 404,
          sent: 12080,
          clicked: 6841,
        },
      ],
      settings: {
        dnd: {
          endHour: 8,
          startHour: 21,
          handlingType: 'DELAY',
        },
      },
      exampleId: 'ABANDONED_CART',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      archivedAt: null,
      handlingOnPause: null,
      waiting: 404,
      sent: 12080,
      clicked: 6841,
    },
    {
      id: 'acbf4b47-ad36-4f47-af2e-0336aa3dfd71',
      createdAt: '2023-08-17T02:08:50.378Z',
      updatedAt: '2023-08-17T02:08:52.362Z',
      name: '첫 구매 유도하기',
      active: true,
      lastActivatedAt: null,
      entryType: 'EVENT',
      entryConditions: {
        event: [
          [
            {
              eventType: '@first_session',
            },
          ],
        ],
        segment: null,
      },
      exitConditions: {
        event: [
          [
            {
              eventType: 'purchase',
            },
          ],
        ],
        segment: null,
      },
      goalConditions: null,
      startActions: [
        {
          id: 'eea4cbe2-7acb-419e-9cca-a2ec4894d900',
          createdAt: '2023-08-17T02:08:52.361Z',
          updatedAt: '2023-08-17T02:08:52.362Z',
          type: 'SEND_NOTIFICATION',
          payload: {
            body:
              '오직 지금만 사용할 수 있는 할인 쿠폰이 있어요! 해당 쿠폰은 7일 이내 사용 가능하니 서두르세요.⏰',
            title: '🎁 첫 구매 전용 쿠폰팩 도착',
          },
          waitMinutes: 180,
          splitWeightPercent: null,
          nextActions: [],
          waiting: 2104,
          sent: 14080,
          clicked: 9896,
        },
      ],
      settings: {
        dnd: {
          endHour: 8,
          startHour: 21,
          handlingType: 'DELAY',
        },
      },
      exampleId: 'FIRST_PURCHASE',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      archivedAt: null,
      handlingOnPause: null,
      waiting: 2104,
      sent: 14080,
      clicked: 9896,
    },
    {
      id: '74e27a8b-0f2d-4fa4-adc3-36c4351ff660',
      createdAt: '2023-08-17T02:08:42.178Z',
      updatedAt: '2023-08-17T02:08:44.540Z',
      name: '회원가입율 높이기',
      active: true,
      lastActivatedAt: null,
      entryType: 'SEGMENT',
      entryConditions: {
        event: null,
        segment: [
          [
            {
              type: 'USER_ID',
              values: ['NOT_EXIST', ''],
            },
            {
              type: 'CREATED_AT_WITHIN_HOURS',
              values: ['NOT_EQ', '24'],
            },
          ],
        ],
      },
      exitConditions: null,
      goalConditions: null,
      startActions: [
        {
          id: '6cfcfdbe-dca4-45c7-9358-ebd306b5078e',
          createdAt: '2023-08-17T02:08:44.539Z',
          updatedAt: '2023-08-17T02:08:44.540Z',
          type: 'SEND_NOTIFICATION',
          payload: {
            body: '최소 금액 없이 즉시 사용할 수 있어요. (안 쓰면 손해 😎)',
            title: '🛍️ 바로 지금만! 가입 시 할인쿠폰 지급',
          },
          waitMinutes: 0,
          splitWeightPercent: null,
          nextActions: [],
          waiting: 81,
          sent: 7080,
          clicked: 3410,
        },
      ],
      settings: {
        dnd: {
          endHour: 8,
          startHour: 21,
          handlingType: 'DELAY',
        },
      },
      exampleId: 'NEW_USER_SIGNUP',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      archivedAt: null,
      handlingOnPause: null,
      waiting: 81,
      sent: 7080,
      clicked: 3410,
    },
    {
      id: '8100813b-d293-47d7-9474-07142c321e40',
      createdAt: '2023-08-17T02:08:30.443Z',
      updatedAt: '2023-08-17T02:08:33.866Z',
      name: '미접속 유저 대상 재방문율 높이기',
      active: true,
      lastActivatedAt: null,
      entryType: 'SEGMENT',
      entryConditions: {
        event: null,
        segment: [
          [
            {
              type: 'LAST_ACTIVE_AT_WITHIN_HOURS',
              values: ['NOT_EQ', '168'],
            },
          ],
        ],
      },
      exitConditions: null,
      goalConditions: null,
      startActions: [
        {
          id: '7da41a84-8ef7-4441-b674-ccd7c62cb6bf',
          createdAt: '2023-08-17T02:08:33.866Z',
          updatedAt: '2023-08-17T02:08:33.866Z',
          type: 'SEND_NOTIFICATION',
          payload: {
            body: '신상품이 정말 많이 추가되었는데 보러오지 않으실래요? 🙏',
            title: '😭 안 들어오신지 꽤 되셨어요.',
          },
          waitMinutes: 0,
          splitWeightPercent: null,
          nextActions: [],
          waiting: 2196,
          sent: 39687,
          clicked: 18073,
        },
      ],
      settings: {
        dnd: {
          endHour: 8,
          startHour: 21,
          handlingType: 'DELAY',
        },
      },
      exampleId: 'INACTIVE_USER_RETENTION',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      archivedAt: null,
      handlingOnPause: null,
      waiting: 2196,
      sent: 39687,
      clicked: 18073,
    },
    {
      id: 'e27127a2-41cd-4269-93ba-9e28611facb1',
      createdAt: '2023-08-17T02:08:03.484Z',
      updatedAt: '2023-08-17T02:08:14.022Z',
      name: '신규 유저 대상 우리 서비스 소개하기',
      active: true,
      lastActivatedAt: null,
      entryType: 'SEGMENT',
      entryConditions: {
        event: null,
        segment: [
          [
            {
              type: 'CREATED_AT_WITHIN_HOURS',
              values: ['NOT_EQ', '1'],
            },
          ],
        ],
      },
      exitConditions: null,
      goalConditions: null,
      startActions: [
        {
          id: '667f06f7-3ac9-48de-a880-25687160751d',
          createdAt: '2023-08-17T02:08:14.021Z',
          updatedAt: '2023-08-17T02:08:14.022Z',
          type: 'SEND_NOTIFICATION',
          payload: {
            body:
              '마침 여름 맞이 할인 이벤트🎁를 진행하고 있었는데 한번 확인해보실래요?',
            title: '🥳 반가워요. 플레어몰입니다.',
            imageUrl:
              'https://imagedelivery.net/lfkkXChLw6uG9RcA2ff5fg/a149eb99-0cf7-42da-e1d9-4e95931af800/flarelane',
          },
          waitMinutes: 0,
          splitWeightPercent: null,
          nextActions: [
            {
              id: '2f0fe169-bbae-416e-9332-c84fed7a1a66',
              createdAt: '2023-08-17T02:08:14.021Z',
              updatedAt: '2023-08-17T02:08:14.022Z',
              type: 'SEND_NOTIFICATION',
              payload: {
                body: '🧾 이번 주 할인하는 인기 상품들을 확인해보세요.',
                title: '바쁜 고객님을 위한 맞춤 알림! 📣',
                imageUrl:
                  'https://imagedelivery.net/lfkkXChLw6uG9RcA2ff5fg/df11c268-776e-4578-7052-bf2413710800/flarelane',
              },
              waitMinutes: 360,
              splitWeightPercent: null,
              nextActions: [
                {
                  id: '195f6eec-2fc1-4db7-b5af-1cfa712d8bfa',
                  createdAt: '2023-08-17T02:08:14.021Z',
                  updatedAt: '2023-08-17T02:08:14.022Z',
                  type: 'SEND_NOTIFICATION',
                  payload: {
                    body: '플레어 AI🤖가 제공하는 초개인화 추천 상품입니다.',
                    title: '저희 이런 것도 있어요! 📣',
                    imageUrl:
                      'https://imagedelivery.net/lfkkXChLw6uG9RcA2ff5fg/5fe138d5-4307-4334-36b3-4361ade42e00/flarelane',
                  },
                  waitMinutes: 1440,
                  splitWeightPercent: null,
                  nextActions: [],
                  waiting: 532,
                  sent: 56013,
                  clicked: 25030,
                },
              ],
              waiting: 105,
              sent: 56013,
              clicked: 23593,
            },
          ],
          waiting: 23,
          sent: 56013,
          clicked: 21593,
        },
      ],
      settings: {
        dnd: {
          endHour: 8,
          startHour: 21,
          handlingType: 'DELAY',
        },
      },
      exampleId: 'NEW_USER_ONBOARDING',
      projectId: '990by8d93-2f7f-43an-bd78-c0323b194eb1',
      archivedAt: null,
      handlingOnPause: null,
      waiting: 660,
      sent: 168039,
      clicked: 70216,
    },
  ] as any,
  en: [
    {
      id: 'a8510f2e-1e3d-4a0e-bb84-378c7de20497',
      createdAt: '2023-08-17T02:15:27.966Z',
      updatedAt: '2023-08-17T02:15:29.934Z',
      name: 'Encourage To Write Reviews',
      active: true,
      lastActivatedAt: null,
      entryType: 'EVENT',
      entryConditions: {
        event: [
          [
            {
              eventType: 'purchase',
            },
          ],
        ],
        segment: null,
      },
      exitConditions: {
        event: [
          [
            {
              eventType: 'write_review',
            },
          ],
        ],
        segment: null,
      },
      goalConditions: null,
      startActions: [
        {
          id: '4e09a574-fbcb-4be5-acd5-c262cebc521b',
          createdAt: '2023-08-17T02:15:29.934Z',
          updatedAt: '2023-08-17T02:15:29.934Z',
          type: 'SEND_NOTIFICATION',
          payload: {
            body:
              'Let us know by leaving a review and receive 10% off your next purchase! 📝',
            title: '🥹 How was your recent purchase?',
          },
          waitMinutes: 10080,
          splitWeightPercent: null,
          nextActions: [],
          waiting: 1520,
          sent: 10962,
          clicked: 2779,
        },
      ],
      settings: {
        dnd: {
          endHour: 8,
          startHour: 21,
          handlingType: 'DELAY',
        },
      },
      exampleId: 'WRITE_REVIEW',
      projectId: 'bfaa75e5-2b3b-407e-bdfe-74eaa72f76a1',
      archivedAt: null,
      handlingOnPause: null,
      waiting: 1520,
      sent: 10962,
      clicked: 2779,
    },
    {
      id: 'e836dda8-e805-4d56-a0f6-029757148a87',
      createdAt: '2023-08-17T02:15:18.831Z',
      updatedAt: '2023-08-17T02:15:20.408Z',
      name: 'Encourage Repeat Purchase',
      active: true,
      lastActivatedAt: null,
      entryType: 'EVENT',
      entryConditions: {
        event: [
          [
            {
              eventType: 'purchase',
            },
          ],
        ],
        segment: null,
      },
      exitConditions: {
        event: [
          [
            {
              eventType: 'purchase',
            },
          ],
        ],
        segment: null,
      },
      goalConditions: null,
      startActions: [
        {
          id: '04fceb17-522d-4222-83ae-609a01583c21',
          createdAt: '2023-08-17T02:15:20.407Z',
          updatedAt: '2023-08-17T02:15:20.408Z',
          type: 'SEND_NOTIFICATION',
          payload: {
            body:
              'Enjoy 15% off your next purchase as a thank you for being a loyal customer.',
            title: '🥰 Thanks for your recent purchase!',
          },
          waitMinutes: 10080,
          splitWeightPercent: null,
          nextActions: [],
          waiting: 8704,
          sent: 20859,
          clicked: 6090,
        },
      ],
      settings: {
        dnd: {
          endHour: 8,
          startHour: 21,
          handlingType: 'DELAY',
        },
      },
      exampleId: 'REPURCHASE',
      projectId: 'bfaa75e5-2b3b-407e-bdfe-74eaa72f76a1',
      archivedAt: null,
      handlingOnPause: null,
      waiting: 8704,
      sent: 20859,
      clicked: 6090,
    },
    {
      id: '00387654-3a10-4589-9fe9-f47171b14f15',
      createdAt: '2023-08-17T02:15:10.381Z',
      updatedAt: '2023-08-17T02:15:12.275Z',
      name: 'Cart Recovery',
      active: true,
      lastActivatedAt: null,
      entryType: 'EVENT',
      entryConditions: {
        event: [
          [
            {
              eventType: 'add_to_cart',
            },
          ],
        ],
        segment: null,
      },
      exitConditions: {
        event: [
          [
            {
              eventType: 'purchase',
            },
          ],
        ],
        segment: null,
      },
      goalConditions: null,
      startActions: [
        {
          id: 'a2293a98-89a0-438f-99a0-9fd9eceb86ae',
          createdAt: '2023-08-17T02:15:12.275Z',
          updatedAt: '2023-08-17T02:15:12.275Z',
          type: 'SEND_NOTIFICATION',
          payload: {
            body: 'Complete your purchase now and enjoy free shipping on us 🛒',
            title: '🤔 Forget something?',
          },
          waitMinutes: 180,
          splitWeightPercent: null,
          nextActions: [],
          waiting: 404,
          sent: 12080,
          clicked: 6841,
        },
      ],
      settings: {
        dnd: {
          endHour: 8,
          startHour: 21,
          handlingType: 'DELAY',
        },
      },
      exampleId: 'ABANDONED_CART',
      projectId: 'bfaa75e5-2b3b-407e-bdfe-74eaa72f76a1',
      archivedAt: null,
      handlingOnPause: null,
      waiting: 404,
      sent: 12080,
      clicked: 6841,
    },
    {
      id: '0e16ae69-4db4-48c4-b186-ab3fe2ed6378',
      createdAt: '2023-08-17T02:15:02.719Z',
      updatedAt: '2023-08-17T02:15:04.760Z',
      name: 'Encourage First Purchase',
      active: true,
      lastActivatedAt: null,
      entryType: 'EVENT',
      entryConditions: {
        event: [
          [
            {
              eventType: '@first_session',
            },
          ],
        ],
        segment: null,
      },
      exitConditions: {
        event: [
          [
            {
              eventType: 'purchase',
            },
          ],
        ],
        segment: null,
      },
      goalConditions: null,
      startActions: [
        {
          id: 'd2dfaa91-c6d0-4c62-970a-6f4dd5d4ffd1',
          createdAt: '2023-08-17T02:15:04.760Z',
          updatedAt: '2023-08-17T02:15:04.760Z',
          type: 'SEND_NOTIFICATION',
          payload: {
            body:
              '7 days only! Use this promo code for 10% off your first purchase ⏰',
            title: '🎁 Promo code for first time purchase',
          },
          waitMinutes: 180,
          splitWeightPercent: null,
          nextActions: [],
          waiting: 2104,
          sent: 14080,
          clicked: 9896,
        },
      ],
      settings: {
        dnd: {
          endHour: 8,
          startHour: 21,
          handlingType: 'DELAY',
        },
      },
      exampleId: 'FIRST_PURCHASE',
      projectId: 'bfaa75e5-2b3b-407e-bdfe-74eaa72f76a1',
      archivedAt: null,
      handlingOnPause: null,
      waiting: 2104,
      sent: 14080,
      clicked: 9896,
    },
    {
      id: 'd198dd14-1611-4a9d-855b-742592c80283',
      createdAt: '2023-08-17T02:14:55.212Z',
      updatedAt: '2023-08-17T02:14:57.197Z',
      name: 'Encourage Sign-ups',
      active: true,
      lastActivatedAt: null,
      entryType: 'SEGMENT',
      entryConditions: {
        event: null,
        segment: [
          [
            {
              type: 'USER_ID',
              values: ['NOT_EXIST', ''],
            },
            {
              type: 'CREATED_AT_WITHIN_HOURS',
              values: ['NOT_EQ', '24'],
            },
          ],
        ],
      },
      exitConditions: null,
      goalConditions: null,
      startActions: [
        {
          id: '0bdf914a-ee75-46b5-9891-7b128be0f543',
          createdAt: '2023-08-17T02:14:57.197Z',
          updatedAt: '2023-08-17T02:14:57.197Z',
          type: 'SEND_NOTIFICATION',
          payload: {
            body:
              'Sign up now and receive a special welcome offer to get started 😎',
            title: '🛍️ Ready to elevate your experience with our product?',
          },
          waitMinutes: 0,
          splitWeightPercent: null,
          nextActions: [],
          waiting: 81,
          sent: 7080,
          clicked: 3410,
        },
      ],
      settings: {
        dnd: {
          endHour: 8,
          startHour: 21,
          handlingType: 'DELAY',
        },
      },
      exampleId: 'NEW_USER_SIGNUP',
      projectId: 'bfaa75e5-2b3b-407e-bdfe-74eaa72f76a1',
      archivedAt: null,
      handlingOnPause: null,
      waiting: 81,
      sent: 7080,
      clicked: 3410,
    },
    {
      id: '74d11dd5-13bd-441f-95b8-16d66bc6ac3e',
      createdAt: '2023-08-17T02:14:38.901Z',
      updatedAt: '2023-08-17T02:14:41.169Z',
      name: 'Encourage Revisits',
      active: true,
      lastActivatedAt: null,
      entryType: 'SEGMENT',
      entryConditions: {
        event: null,
        segment: [
          [
            {
              type: 'LAST_ACTIVE_AT_WITHIN_HOURS',
              values: ['NOT_EQ', '168'],
            },
          ],
        ],
      },
      exitConditions: null,
      goalConditions: null,
      startActions: [
        {
          id: 'ec965f6d-2f15-4c39-a019-7a9ef6f64880',
          createdAt: '2023-08-17T02:14:41.169Z',
          updatedAt: '2023-08-17T02:14:41.169Z',
          type: 'SEND_NOTIFICATION',
          payload: {
            body:
              "New products available now. Don't forget to use your exclusive discount code! 💪🏻",
            title: "😭 We've missed you!",
          },
          waitMinutes: 0,
          splitWeightPercent: null,
          nextActions: [],
          waiting: 2196,
          sent: 39687,
          clicked: 18073,
        },
      ],
      settings: {
        dnd: {
          endHour: 8,
          startHour: 21,
          handlingType: 'DELAY',
        },
      },
      exampleId: 'INACTIVE_USER_RETENTION',
      projectId: 'bfaa75e5-2b3b-407e-bdfe-74eaa72f76a1',
      archivedAt: null,
      handlingOnPause: null,
      waiting: 2196,
      sent: 39687,
      clicked: 18073,
    },
    {
      id: 'a59815e8-a1ae-4e98-bfd3-3aedbdc3a9e3',
      createdAt: '2023-08-17T02:14:33.600Z',
      updatedAt: '2023-08-17T02:14:33.602Z',
      name: 'Onboard New Customers',
      active: true,
      lastActivatedAt: null,
      entryType: 'SEGMENT',
      entryConditions: {
        event: null,
        segment: [
          [
            {
              type: 'CREATED_AT_WITHIN_HOURS',
              values: ['NOT_EQ', '1'],
            },
          ],
        ],
      },
      exitConditions: null,
      goalConditions: null,
      startActions: [
        {
          id: 'd41a38ab-96a1-42b3-b899-d0a946faa010',
          createdAt: '2023-08-17T02:14:33.600Z',
          updatedAt: '2023-08-17T02:14:33.602Z',
          type: 'SEND_NOTIFICATION',
          payload: {
            body:
              "Check out our beginner's guide to get started with our product today🎁",
            title: '🥳 Welcome to FlareMall',
            imageUrl:
              'https://imagedelivery.net/lfkkXChLw6uG9RcA2ff5fg/a149eb99-0cf7-42da-e1d9-4e95931af800/flarelane',
          },
          waitMinutes: 0,
          splitWeightPercent: null,
          nextActions: [
            {
              id: '72ca7e0b-8d00-49eb-9404-d34e489f7112',
              createdAt: '2023-08-17T02:14:33.600Z',
              updatedAt: '2023-08-17T02:14:33.602Z',
              type: 'SEND_NOTIFICATION',
              payload: {
                body: "🧾 Take a look at this week's discounted item",
                title: 'Special news! 📣',
                imageUrl:
                  'https://imagedelivery.net/lfkkXChLw6uG9RcA2ff5fg/df11c268-776e-4578-7052-bf2413710800/flarelane',
              },
              waitMinutes: 360,
              splitWeightPercent: null,
              nextActions: [
                {
                  id: '4f137b9a-1327-4e8c-9e05-0372575a28fa',
                  createdAt: '2023-08-17T02:14:33.600Z',
                  updatedAt: '2023-08-17T02:14:33.602Z',
                  type: 'SEND_NOTIFICATION',
                  payload: {
                    body: 'FlareMall AI recommended products just for you 🤖',
                    title: 'Check this out! 📣',
                    imageUrl:
                      'https://imagedelivery.net/lfkkXChLw6uG9RcA2ff5fg/5fe138d5-4307-4334-36b3-4361ade42e00/flarelane',
                  },
                  waitMinutes: 1440,
                  splitWeightPercent: null,
                  nextActions: [],
                  waiting: 23,
                  sent: 56013,
                  clicked: 25030,
                },
              ],
              waiting: 105,
              sent: 56013,
              clicked: 23593,
            },
          ],
          waiting: 532,
          sent: 56013,
          clicked: 21593,
        },
      ],
      settings: {
        dnd: {
          endHour: 8,
          startHour: 21,
          handlingType: 'DELAY',
        },
      },
      exampleId: 'NEW_USER_ONBOARDING',
      projectId: 'bfaa75e5-2b3b-407e-bdfe-74eaa72f76a1',
      archivedAt: null,
      handlingOnPause: null,
      waiting: 660,
      sent: 168039,
      clicked: 70216,
    },
  ] as any,
};
