import styled from '@emotion/styled';

const AlertLoadIcon = ({ className }: { className?: string }): JSX.Element => {
  return (
    <Svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="25" cy="25" r="24" className="border-bg" />
      <circle cx="25" cy="25" r="24" className="border" />
      <path
        d="M22.83 34.8505C22.83 34.2749 23.0586 33.723 23.4655 33.316C23.8725 32.9091 24.4244 32.6805 25 32.6805C25.5755 32.6805 26.1274 32.9091 26.5344 33.316C26.9413 33.723 27.17 34.2749 27.17 34.8505C27.17 35.426 26.9413 35.9779 26.5344 36.3849C26.1274 36.7918 25.5755 37.0205 25 37.0205C24.4244 37.0205 23.8725 36.7918 23.4655 36.3849C23.0586 35.9779 22.83 35.426 22.83 34.8505ZM26.9 15.0805C26.9 16.3505 26.52 19.6205 26.07 24.4505L25.62 29.2405H24.38L23.93 24.4505C23.48 19.6205 23.11 16.3505 23.11 15.0805C23.11 13.6005 23.83 12.9805 25 12.9805C26.17 12.9805 26.9 13.6005 26.9 15.0805Z"
        fill="#1D4289"
      />
    </Svg>
  );
};

const Svg = styled.svg`
  .border {
    fill: none;
    stroke-width: 2;
    /* stroke-dasharray: 158; */
    /* stroke-dashoffset: 158; */
    /* animation: progress 5s linear forwards; */
    stroke: ${({ theme: { colors } }) => colors.blue0};

    transform: rotate(-90deg) translateX(-50px);
  }

  .border-bg {
    fill: none;
    stroke: ${({ theme: { colors } }) => colors.gray5};
    stroke-width: 2;
  }

  @keyframes progress {
    to {
      stroke-dashoffset: 0;
    }
  }
`;

export default AlertLoadIcon;
