import { SET_USER, SET_USER_TOKEN } from '@store/user/actions';
import { createReducer } from 'typesafe-actions';
import { HYDRATE } from 'next-redux-wrapper';
import type { UserAction, UserTokenAction } from '@store/user/actions';
import type { User } from '@dataTypes/session';

type UserTokenState = null | string | undefined;
type UserState = Record<string, never> | User;

export const user = createReducer<UserState, UserAction>(
  {},
  {
    [HYDRATE]: (state, action) => {
      const userFromServerSide = action.payload.user;
      return userFromServerSide && userFromServerSide.sub !== state.sub
        ? userFromServerSide
        : state;
    },
    [SET_USER]: (state, action) => action.payload,
  }
);
export const userToken = createReducer<UserTokenState, UserTokenAction>(null, {
  [HYDRATE]: (state, action) => {
    const tokenFromServerSide = action.payload.userToken;
    return tokenFromServerSide && tokenFromServerSide !== state
      ? action.payload.userToken
      : state;
  },
  [SET_USER_TOKEN]: (state, action) => action.payload,
});
