import { createReducer } from 'typesafe-actions';
import type { SetWebPushConfigs, WebPushActions } from './actions';
import { SET_WEB_PUSH_CONFIGS } from './actions';

interface WebPushState {
  configs: SetWebPushConfigs;
}

export const webPush = createReducer<WebPushState, WebPushActions>(
  {
    configs: {
      data: null,
      isLoading: false,
      isActivate: false,
    },
  },
  {
    [SET_WEB_PUSH_CONFIGS]: (state, action) => {
      if (typeof action.payload === 'function') {
        return { ...state, configs: action.payload(state.configs) };
      }

      return { ...state, configs: action.payload };
    },
  }
);
