import { cookieConfigs } from '@configs/cookieConfigs';

export const getCookie = (name: string) => {
  name += '=';
  const arr = decodeURIComponent(document.cookie).split(';');
  const al = arr.length;
  for (let i = 0; i < al; i++) {
    let c = arr[i];
    while (c.charAt(0) == ' ') c = c.substring(1);
    if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
  }
  return '';
};

export const setLangCookie = (value) => {
  document.cookie = `${cookieConfigs.key}=${value};domain=${cookieConfigs.domain};path=/`;
};
