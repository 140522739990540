import BeatLoader from '@components/atoms/Loader/BeatLoader';
import styled from '@emotion/styled';
import type { RootState } from '@store/rootReducer';
import { useTranslation } from 'next-i18next';
import { memo } from 'react';
import { useSelector } from 'react-redux';

const UnauthorizedModal = (): JSX.Element => {
  const globalLoading = useSelector((state: RootState) => state.globalLoading);
  const { t } = useTranslation('common');

  return (
    <>
      {globalLoading.isLoading && (
        <Wrap>
          <BeatLoader />
          <Text
            dangerouslySetInnerHTML={{ __html: t(globalLoading.message || '') }}
          />
        </Wrap>
      )}
    </>
  );
};

const Wrap = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: ${({ theme: { colors } }) => colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Text = styled.div`
  margin-top: 8px;
  color: ${({ theme: { colors } }) => colors.gray1};
  ${({ theme: { typography } }) => typography.paragraph1};
  text-align: center;
`;

export default memo(UnauthorizedModal);
