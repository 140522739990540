const InfoIcon = (): JSX.Element => {
  return (
    <div>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.5 8C15.5 12.1421 12.1421 15.5 8 15.5C3.85786 15.5 0.5 12.1421 0.5 8C0.5 3.85786 3.85786 0.5 8 0.5C12.1421 0.5 15.5 3.85786 15.5 8Z"
          stroke="#14A8C9"
        />
        <path
          d="M9 9.92H7V12H9V9.92ZM7 8.64H9L9.5 4H6.5L7 8.64Z"
          fill="#14A8C9"
        />
      </svg>
    </div>
  );
};

export default InfoIcon;
