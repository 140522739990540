import Router from 'next/router';
import NProgress from 'nprogress';

const progressStart = () => {
  NProgress.start();
};

const progressDone = (url: string) => {
  NProgress.done();
};

export const setPageLoadingBar = (): void => {
  Router.events.on('routeChangeStart', progressStart);
  Router.events.on('routeChangeComplete', progressDone);
  Router.events.on('routeChangeError', progressDone);
};

export const removePageLoadingBar = (): void => {
  Router.events.off('routeChangeStart', progressStart);
  Router.events.off('routeChangeComplete', progressDone);
  Router.events.off('routeChangeError', progressDone);
};
