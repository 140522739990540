import { useRouter } from 'next/router';
import { useMemo } from 'react';

export const useDemoData = (data: { en: any; ko: any }) => {
  const router = useRouter();
  const _data = useMemo(() => {
    return data[router.locale || 'en'];
  }, [data, router]);
  return _data;
};
