import type { Conifgs } from '@dataTypes/configs';
import type { GlobalLoading } from '@dataTypes/GlobalLoading';
import { HYDRATE } from 'next-redux-wrapper';
import type { ActionType } from 'typesafe-actions';
import { createAction } from 'typesafe-actions';

export const SET_SIDEBAR_STATE = 'SET_SLIDEBAR_STATE';
export const SET_SIDEBAR_CLOSE = 'SET_SIDEBAR_CLOSE';
export const setSidebarState = createAction(SET_SIDEBAR_STATE)();
export const setSidebarClose = createAction(SET_SIDEBAR_CLOSE)();
const siderbarStateActions = { setSidebarState, setSidebarClose };
export type SidebarStateAction = ActionType<typeof siderbarStateActions>;

export const SET_UNAUTHORIZED_MODAL_STATE = 'SET_401_MODAL_STATE';
export const setUnauthorizedModalState = createAction(
  SET_UNAUTHORIZED_MODAL_STATE
)<boolean>();
export type UnauthorizedModalStateAction = ActionType<
  typeof setUnauthorizedModalState
>;

export const SET_IS_GLOBAL_LOADING = 'SET_IS_GLOBAL_LOADING';
export const hydrate = createAction(HYDRATE)<any>();
export const setIsGlobalLoading = createAction(SET_IS_GLOBAL_LOADING)<
  GlobalLoading | ((currentGlobalLoading: GlobalLoading) => GlobalLoading)
>();
const setIsGlobalLoadingAction = { setIsGlobalLoading, hydrate };
export type SetIsGlobalLoading = ActionType<typeof setIsGlobalLoadingAction>;

export const SET_CONFIGS = 'SET_CONFIGS';
export const setConfigs = createAction(SET_CONFIGS)<Conifgs>();
export type ConfigStateAction = ActionType<typeof setConfigs>;

export const SET_GLOBAL_REF = 'SET_GLOBAL_REF';
export const setGlobalRef = createAction(SET_GLOBAL_REF)<any>();
export type SetGlobalRefAction = ActionType<typeof setGlobalRef>;
