import type { NextPrevPaginationState } from '@dataTypes/pagination';
import { createReducer } from 'typesafe-actions';
import type { PaginationStateActions } from './actions';
import { SET_SENT_HISTORY_PAGINATION_STATE } from './actions';

interface PaginationState {
  sentHistory: NextPrevPaginationState;
}

export const pagination = createReducer<
  PaginationState,
  PaginationStateActions
>(
  {
    sentHistory: {
      page: 0,
      lastKeys: [],
    },
  },
  {
    [SET_SENT_HISTORY_PAGINATION_STATE]: (state, action) => {
      if (typeof action.payload === 'function') {
        return { ...state, ...action.payload(state.sentHistory) };
      }

      return { ...state, sentHistory: action.payload };
    },
  }
);
