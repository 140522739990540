import { demoWebPushConfigs } from '@configs/demoDatas';
import type { RootState } from '@store/rootReducer';
import { setWebPushConfigs } from '@store/webPush/actions';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDemoData } from './useDemoData';

export const useWebPushConfig = () => {
  const data = useSelector((state: RootState) => state.webPush.configs);
  const dispatch = useDispatch();
  const demoWebPushConfigsData = useDemoData(demoWebPushConfigs);

  const loadWebPushConfig = useCallback(async () => {
    try {
      dispatch(
        setWebPushConfigs({
          data: demoWebPushConfigsData,
          isActivate: true,
          isLoading: false,
        })
      );
    } catch {
      // 에러 시 로딩 안끝나게
    }
  }, [demoWebPushConfigsData]);

  useEffect(() => {
    loadWebPushConfig();
  }, [demoWebPushConfigsData]);

  return { data, loadWebPushConfig };
};
