import styled from '@emotion/styled';

export interface XIconProps {
  color?: string;
  border?: boolean;
  isSmall?: boolean;
  isBig?: boolean;
}

const XIcon = styled.div<XIconProps>`
  display: inline-block;
  position: relative;
  width: ${({ isBig, isSmall }) =>
    isBig ? '23px' : isSmall ? '16px' : '11px'};
  height: ${({ isBig, isSmall }) =>
    isBig ? '23px' : isSmall ? '16px' : '11px'};
  border-radius: 50%;
  border: 1px solid ${({ color, border }) => (border ? color : 'transparent')};
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    width: ${({ isBig, isSmall }) =>
      isBig ? '23px' : isSmall ? '8px' : '11px'};
    height: ${({ isBig, isSmall }) =>
      isBig ? '3px' : isSmall ? '2px' : '1px'};
    transform: rotate(-45deg);
    background-color: ${({ color }) => color};
    content: '';
    position: absolute;
  }

  &::after {
    width: ${({ isBig, isSmall }) =>
      isBig ? '23px' : isSmall ? '8px' : '11px'};
    height: ${({ isBig, isSmall }) =>
      isBig ? '3px' : isSmall ? '2px' : '1px'};
    transform: rotate(45deg);
    background-color: ${({ color }) => color};
    content: '';
    position: absolute;
  }
`;

export default XIcon;
