import { createAction } from 'typesafe-actions';
import { HYDRATE } from 'next-redux-wrapper';
import type { ActionType } from 'typesafe-actions';
import type { User } from '@dataTypes/session';

export const SET_USER = 'SET_USER';
export const SET_USER_TOKEN = 'SET_USER_TOKEN';

export const hydrate = createAction(HYDRATE)<any>();
export const setUser = createAction(SET_USER)<User>();
export const setUserToken = createAction(SET_USER_TOKEN)<string | undefined>();

const actions = { setUser, hydrate };
const userTokenActions = { setUserToken, hydrate };

export type UserAction = ActionType<typeof actions>;
export type UserTokenAction = ActionType<typeof userTokenActions>;
