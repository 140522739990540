import XIcon from '@components/atoms/Icons/XIcon/XIcon';
import styled from '@emotion/styled';
import type { CSSProperties, Dispatch, ReactNode, SetStateAction } from 'react';
import { useCallback } from 'react';

export interface ModalProps {
  setModalOpen?: Dispatch<SetStateAction<boolean>>;
  children?: ReactNode;
  mobileFullScreen?: boolean;
  padding?: string;
  noBackground?: boolean;
  style?: CSSProperties;
}

const Modal = ({
  setModalOpen,
  children,
  mobileFullScreen,
  noBackground,
  style,
}: ModalProps): JSX.Element => {
  const closeModal = useCallback((e) => {
    e.stopPropagation();
    setModalOpen && setModalOpen(false);
  }, []);

  return (
    <>
      <Background onClick={closeModal} mobileFullScreen={mobileFullScreen} />
      <Wrap
        mobileFullScreen={mobileFullScreen}
        noBackground={noBackground}
        style={style}
      >
        {children}
        {mobileFullScreen && (
          <ExitButtonWrap onClick={closeModal}>
            <XIcon color="#c4c4c4" isBig />
          </ExitButtonWrap>
        )}
      </Wrap>
    </>
  );
};

interface BackgroundProps {
  mobileFullScreen?: boolean;
}
const Background = styled.div<BackgroundProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 4, 22, 0.15);
  animation: ${({ theme: { animation } }) => animation.place} 0.1s;
  z-index: 99898;

  @media ${({ theme: { mediaQuery } }) => mediaQuery.mobile} {
    display: ${({ mobileFullScreen }) => (mobileFullScreen ? 'none' : '')};
  }
`;

interface WrapProps {
  mobileFullScreen?: boolean;
  noBackground?: boolean;
}
const Wrap = styled.div<WrapProps>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${({ theme: { colors }, noBackground }) =>
    noBackground ? 'transparent' : colors.white};
  border-radius: 10px;
  padding: 40px;
  animation: ${({ theme: { animation } }) => animation.place} 0.1s;
  z-index: 99899;
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.1);
  max-height: calc(100% - 32px);
  max-width: calc(100% - 32px);
  overflow: auto;

  @media ${({ theme: { mediaQuery } }) => mediaQuery.mobile} {
    width: ${({ mobileFullScreen }) =>
      mobileFullScreen ? '100%' : 'calc(100% - 32px)'};
    height: ${({ mobileFullScreen }) => (mobileFullScreen ? '100%' : '')};
    top: ${({ mobileFullScreen }) => (mobileFullScreen ? 0 : '50%')};
    left: ${({ mobileFullScreen }) => (mobileFullScreen ? 0 : '50%')};
    transform: ${({ mobileFullScreen }) =>
      mobileFullScreen ? 'translate(0, 0)' : 'translate(-50%, -50%)'};
    border-radius: ${({ mobileFullScreen }) => (mobileFullScreen ? 0 : '10px')};
    padding: ${({ mobileFullScreen }) => (mobileFullScreen ? 0 : '32px')};
    max-height: ${({ mobileFullScreen }) => (mobileFullScreen ? '100%' : '')};
    max-width: unset;
    overflow: auto;
  }
`;

const ExitButtonWrap = styled.div`
  display: none;
  position: absolute;
  top: 19px;
  right: 19px;

  @media ${({ theme: { mediaQuery } }) => mediaQuery.mobile} {
    display: block;
  }
`;

export default Modal;
