import { ProjectPlan } from '@dataTypes/project';
import { setGlobalRef } from '@store/configs/actions';
import type { RootState } from '@store/rootReducer';
import { useDispatch, useSelector } from 'react-redux';

export type GlobalRefCurrent = {
  isLoadingWebPushConfig: boolean;
  cachedPlan: Record<string, ProjectPlan>;
};

export type GlobalRef = {
  current: GlobalRefCurrent;
};

// _app의 useEffect에서 초기화 해줘야함
// call reset

export const useGlobalRef = () => {
  const globalRef = useSelector((state: RootState) => state.globalRef);
  const dispatch = useDispatch();

  const reset = (ref: any, initialValue: GlobalRefCurrent) => {
    ref.current = initialValue;
    dispatch(setGlobalRef(ref));
  };

  return { globalRef: globalRef as GlobalRef, reset };
};
