import type { NextPrevPaginationState } from '@dataTypes/pagination';
import type { ActionType } from 'typesafe-actions';
import { createAction } from 'typesafe-actions';

export const SET_SENT_HISTORY_PAGINATION_STATE =
  'SET_SENT_HISTORY_PAGINATION_STATE';
export const setSentHistoryPaginationState = createAction(
  SET_SENT_HISTORY_PAGINATION_STATE
)<
  | NextPrevPaginationState
  | ((prev: NextPrevPaginationState) => NextPrevPaginationState)
>();

const paginationStateActions = {
  setSentHistoryPaginationState,
};

export type PaginationStateActions = ActionType<typeof paginationStateActions>;
