import CheckIcon from '@components/atoms/Icons/CheckIcon/CheckIcon';
import InfoIcon from '@components/atoms/Icons/InfoIcon/InfoIcon';
import XIcon from '@components/atoms/Icons/XIcon/XIcon';
import styled from '@emotion/styled';
import type { CSSProperties } from 'react';

type optionType = 'info' | 'success' | 'error';

export interface AlertProps {
  message: string;
  options: Record<string, unknown>;
  style: CSSProperties;
  close: () => void;
}

const Alert = ({ message, options, style, close }: AlertProps): JSX.Element => {
  return (
    <Wrap style={style} onClick={close} type={options.type as optionType}>
      <pre>{message}</pre>
      {(() => {
        switch (options.type) {
          case 'info':
            return (
              <AlerIconWrap>
                <InfoIcon />
              </AlerIconWrap>
            );
          case 'success':
            return (
              <AlertCheckWrap>
                <AlertCheck />
              </AlertCheckWrap>
            );
          case 'error':
            return (
              <AlerIconWrap>
                <XIcon border color="#ff7060" isSmall />
              </AlerIconWrap>
            );
        }
      })()}
    </Wrap>
  );
};

const Wrap = styled.div<{ type: optionType }>`
  display: flex;
  align-items: center;
  ${({ theme: { typography } }) => typography.paragraph2}
  font-weight: 700;
  background-color: ${({ theme: { colors }, type }) => {
    switch (type) {
      case 'info':
        return '#cdf3f8';
      case 'success':
        return '#dcf8e9';
      case 'error':
        return '#fde5e3';
      default:
        return colors.white;
    }
  }};
  color: ${({ theme: { colors }, type }) => {
    switch (type) {
      case 'info':
        return '#14a8c9';
      case 'success':
        return '#0db059';
      case 'error':
        return '#ff7060';
      default:
        return colors.gray0;
    }
  }};
  border: 1px solid
    ${({ theme: { colors }, type }) => {
      switch (type) {
        case 'info':
          return '#cff1f9';
        case 'success':
          return '#cdf1de';
        case 'error':
          return '#ffdad6';
        default:
          return colors.gray0;
      }
    }};
  padding: 7px 12px;
  border-radius: 2px;
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.05);
  user-select: none;
  cursor: pointer;
`;

const AlertCheckWrap = styled.div`
  width: 16px;
  height: 16px;
  position: relative;
  margin-left: 19px;
  border-radius: 50%;
  border: 1px solid #0db059;
`;

const AlertCheck = styled(CheckIcon)`
  width: 7px;
  height: 5px;
  border-color: #0db059;
  transform: translate(-50%, 0) rotate(135deg);
  position: absolute;
  top: 4px;
  left: 50%;
`;

const AlerIconWrap = styled.div`
  height: 16px;
  margin-left: 19px;
`;

export default Alert;
