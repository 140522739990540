import { memo } from 'react';
import styled from '@emotion/styled';

const FullWideButton = styled.button`
  width: 100%;
  height: 46px;
  ${({ theme: { typography } }) => typography.paragraph1}
  color: ${({ theme: { colors } }) => colors.white};
  background-color: ${({ theme: { colors } }) => colors.blue0};
  border-radius: 2px;
  transition: background-color 0.1s ease-in-out;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: ${({ theme: { colors } }) => colors.darkBlue};
  }
`;

export default memo(FullWideButton);
