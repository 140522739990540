import styled from '@emotion/styled';

interface CheckIconProps {
  isBig?: boolean;
  disable?: boolean;
}
const CheckIcon = styled.div<CheckIconProps>`
  width: ${({ isBig }) => (isBig ? '11px' : '8px')};
  height: ${({ isBig }) => (isBig ? '7px' : '6px')};
  border-width: 2px 2px 0 0;
  border-color: ${({ theme: { colors }, disable }) =>
    disable ? colors.gray4 : colors.blue1};
  border-style: solid;
  transform: rotate(135deg);
`;

export default CheckIcon;
