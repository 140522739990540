import { css, keyframes } from '@emotion/core';

export const deviceSizes = {
  smallMobile: 321,
  mobile: 428,
  smallHeight: 720,
};

const theme = {
  colors: {
    darkBlue: '#011E41',
    deepBlue: '#14284f',
    blue0: '#1d4289',
    blue1: '#527cbd',
    blue2: '#5f9dfd',
    blue2Hover: '#4f84d6',
    blue3: 'rgba(82, 124, 189, 0.15)',
    blue4: 'rgba(82, 124, 189, 0.1)',
    blue3Hover: 'rgba(82, 124, 189, 0.25)',
    gray0: '#000416',
    gray1: '#343745',
    gray2: '#7a7d8c',
    gray3: '#c0c0cc',
    gray4: '#e0e0e8',
    gray5: '#f5f5f7',
    gray6: '#fbfbfd',
    white: '#ffffff',
    red: '#d86c77',
    yellow: '#e59545',
    green: '#43a8a3',
    purple: '#5858bc',
  },
  typography: {
    heading1: css`
      font-weight: 700;
      font-size: 60px;
      line-height: 78px;
      letter-spacing: -0.5px;
      text-transform: none;
      letter-spacing: normal;
    `,
    heading2: css`
      font-weight: 700;
      font-size: 40px;
      line-height: 52px;
      text-transform: none;
      letter-spacing: normal;
    `,
    heading3: css`
      font-size: 30px;
      line-height: 40px;
      text-transform: none;
      letter-spacing: normal;
    `,
    heading4: css`
      font-size: 20px;
      line-height: 26px;
      text-transform: none;
      letter-spacing: normal;
    `,
    paragraph: css`
      font-size: 16px;
      line-height: 22px;
      font-weight: 400;
      text-transform: none;
      letter-spacing: normal;
    `,
    paragraph1: css`
      font-size: 14px;
      line-height: 22px;
      font-weight: 400;
      text-transform: none;
      letter-spacing: normal;
    `,
    paragraph2: css`
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      text-transform: none;
      letter-spacing: normal;
    `,
    paragraph3: css`
      font-size: 10px;
      line-height: 18px;
      font-weight: 400;
      text-transform: none;
      letter-spacing: normal;
    `,
    mobileParagraph1: css`
      font-size: 18px;
      line-height: 22px;
      text-transform: none;
      letter-spacing: normal;
    `,
    mobileParagraph2: css`
      font-size: 16px;
      line-height: 22px;
      text-transform: none;
      letter-spacing: normal;
    `,
    caption: css`
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 1.25px;
      text-transform: uppercase;
    `,
    captionSmall: css`
      font-weight: 700;
      font-size: 8px;
      line-height: 16px;
      letter-spacing: 1.25px;
      text-transform: uppercase;
    `,
  },
  animation: {
    place: keyframes`
      0% {
        opacity: 0;
      }
  `,
  },
  mediaQuery: {
    smallMobile: `screen and (max-width: ${deviceSizes.smallMobile}px)`,
    mobile: `screen and (max-width: ${deviceSizes.mobile}px)`,
    smallHeight: `screen and (max-height: ${deviceSizes.smallHeight}px)`,
  },
};

export type Theme = typeof theme;

export const SentHistoryColorTheme = {
  sent: '#43a8a3',
  converted: '#55c7c1',
  remaining: '#feda82',
  failed: '#c0c0c0',
  uninstalled: '#ff9999',
  none: '#ececec',
};

export const journeyBadgeColors = {
  gray: '#F5F5F7',
  green: '#55BDB8',
  orange: '#EEA358',
  darkGray: '#7a7d8c',
};

export default theme;
