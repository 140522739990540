import styled from '@emotion/styled';

const BeatLoader = (): JSX.Element => {
  return (
    <Wrap>
      <LogoContainer>
        <svg
          width="19"
          height="29"
          viewBox="0 0 19 29"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M18.9475 0H0V18.9475L18.9475 0Z" fill="#1A428A" />
          <path d="M0 0V18.9475L9.47662 9.47662L0 0Z" fill="#011E41" />
          <path
            d="M18.9475 9.47656H0V28.4241L18.9475 9.47656Z"
            fill="#527DBE"
          />
          <path
            d="M0 9.47656V28.4241L9.47662 18.9475L0 9.47656Z"
            fill="#345D9D"
          />
        </svg>
      </LogoContainer>
    </Wrap>
  );
};

const Wrap = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin: 3em;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  animation: 1s infinite ease-in-out;

  &:before,
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    animation: 1s infinite ease-in-out;
  }

  &:before {
    border: 1px solid ${({ theme: { colors } }) => colors.gray4};
    top: -1px;
    left: -1px;
    opacity: 0;
    animation-name: oline;
  }

  @keyframes oline {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.3);
      opacity: 0;
    }
    100% {
      transform: scale(1.3);
      opacity: 0;
    }
  }

  &:after {
    background-color: ${({ theme: { colors } }) => colors.gray5};
    animation-name: inner;
  }

  @keyframes inner {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.7);
    }
    100% {
      transform: scale(1);
    }
  }
`;

const LogoContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;

  svg {
    margin: 4px 0 0 4px;
    animation: 1s inner2 infinite ease-in-out;
  }

  @keyframes inner2 {
    0% {
      transform: scale(2);
    }
    50% {
      transform: scale(1.8);
    }
    100% {
      transform: scale(2);
    }
  }
`;

export default BeatLoader;
