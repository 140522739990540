import FullWideButton from '@components/atoms/Buttons/FullWideButton';
import AlertLoadIcon from '@components/atoms/Icons/AlertIcon/AlertLoadIcon';
import Modal from '@components/atoms/Modal/Modal';
import styled from '@emotion/styled';
import type { RootState } from '@store/rootReducer';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { memo } from 'react';
import { useSelector } from 'react-redux';

const UnauthorizedModal = (): JSX.Element => {
  const isUnauthorizedModalOpen = useSelector(
    (state: RootState) => state.isUnauthorizedModalOpen
  );
  const { t } = useTranslation('common');

  return (
    <>
      {isUnauthorizedModalOpen && (
        <Modal>
          <Container>
            <IconWrap>
              <AlertLoadIcon />
            </IconWrap>
            <Text
              dangerouslySetInnerHTML={{ __html: t('modal.loginExpire') }}
            />
            <FullWideButton
              onClick={() => (window.location.href = '/api/auth/logout')}
            >
              {t('button.confirm')}
            </FullWideButton>
          </Container>
        </Modal>
      )}
    </>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const IconWrap = styled.div`
  margin-bottom: 17px;
`;

const Text = styled.div`
  width: 360px;
  word-break: keep-all;
  text-align: center;
  ${({ theme: { typography } }) => typography.paragraph1};
  margin-bottom: 48px;
`;

export default memo(UnauthorizedModal);
