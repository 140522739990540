import type { Project } from '@dataTypes/project';
import type { CurrentProjectState } from '@store/projects/reducers';
import type { ActionType } from 'typesafe-actions';
import { createAction } from 'typesafe-actions';

export const SET_PROJECTS = 'SET_PROJECTS';
export const SET_PROJECTS_LOADING = 'SET_PROJECTS_LOADING';
export const SET_CURRENT_PROJECT = 'SET_CURRENT_PROJECT';

export const setProjects = createAction(SET_PROJECTS)<Project[]>();
export const setProjectsLoading = createAction(SET_PROJECTS_LOADING)<boolean>();
export const setCurrentProject = createAction(SET_CURRENT_PROJECT)<
  Project | ((currentProject: CurrentProjectState) => CurrentProjectState)
>();

const projectsActions = { setProjects };
const currentProjectAction = { setCurrentProject };

export type ProjectsAction = ActionType<typeof projectsActions>;
export type ProjectsLoadingAction = ActionType<typeof setProjectsLoading>;
export type CurrentProjectAction = ActionType<typeof currentProjectAction>;
